import { ICellRendererParams } from 'ag-grid-community'
import React, { useEffect, useRef, useState } from 'react'
import { FONT_FAMILY, FontSize } from '../../../../../styles/commonStyles'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Tooltip from '@mui/material/Tooltip'
import { FloatingEditableIcon } from '../../../commons/AgGrid/components/cell/common/text'
import { inputErrorColor } from '../../../commons/AgGrid/lib/validator'

interface Props extends ICellRendererParams {}

export const TreeCellRenderer = (props: Props) => {
  const { value, data, node } = props
  const ref = useRef<HTMLDivElement | null>(null)
  const [tooltip, setTooltip] = useState('')
  const [openTooltip, setOpenTooltip] = useState(false)

  useEffect(() => {
    const offsetWidth = ref.current?.offsetWidth
    const ctx = document.createElement('canvas').getContext('2d')
    if (value && offsetWidth && ctx) {
      ctx.font = `${FontSize.MEDIUM} "${FONT_FAMILY}"`
      const textWidth = ctx.measureText(value)?.width
      if (offsetWidth - textWidth < 0) {
        setTooltip(value)
      }
    }
  }, [value])

  return (
    <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
      <Tooltip
        title={tooltip}
        placement={'bottom-start'}
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: `${FontSize.MEDIUM}px`,
              marginTop: '5px !important',
            },
          },
        }}
        open={openTooltip}
      >
        <div
          id={`tree-column-${props['rowIndex']}`}
          className="sevend-ag-cell-project-plan-tree"
          ref={ref}
          onClick={e => setOpenTooltip(!openTooltip)}
          onMouseLeave={e => setOpenTooltip(false)}
        >
          <div
            className="sevend-ag-cell-project-plan-tree__text-field"
            style={{
              backgroundColor: value ? 'none' : inputErrorColor,
            }}
          >
            {value}
          </div>
          <FloatingEditableIcon {...props} />
        </div>
      </Tooltip>
    </ClickAwayListener>
  )
}
