import { Column, GridApi } from 'ag-grid-community'
import { CheckBoxCellEditor } from './CheckBoxCellEditor'
import { CustomEnumCellEditor } from './CustomEnumCellEditor'
import { DateCellEditor } from './DateCellEditor'
import { DateTimeCellEditor } from './DateTimeCellEditor'
import { EntitySearchCellEditor } from './EntitySearchCellEditor'
import { MultiAutocompleteCellEditor } from './MultiAutocompleteCellEditor'
import { NumberCellEditor } from './NumberCellEditor'
import { ColorChartCellEditor } from './ColorChartCellEditor'
import { MyWbsItemsCustomEnumCellEditor } from './MyWbsItemsCustomEnumCellEditor'
import { TagCellEditor } from './TagCellEditor'
import { SlackChannelCellEditor } from './SlackChannelCellEditor'
import { SelectCellEditor } from './SelectCellEditor'

export {
  CheckBoxCellEditor,
  CustomEnumCellEditor,
  EntitySearchCellEditor,
  DateCellEditor,
  DateTimeCellEditor,
  MultiAutocompleteCellEditor,
  NumberCellEditor,
  ColorChartCellEditor,
  MyWbsItemsCustomEnumCellEditor,
  TagCellEditor,
  SlackChannelCellEditor,
  SelectCellEditor,
}

export const moveToNextRow = (
  api: GridApi,
  rowIndex: number,
  column: Column
) => {
  api.clearRangeSelection()
  api.clearFocusedCell()
  api.setFocusedCell(rowIndex + 1, column)
}
