import _ from 'lodash'
import { useCallback, useState } from 'react'
import { NumberFilterOperator } from '../NumberFilter/index'

export const useNumberFilter = (): {
  operator: NumberFilterOperator
  setOperator: (_: NumberFilterOperator) => void
  value: number | undefined
  setValue: (_?: number) => void
  isActive: () => boolean
  model: () => { operator: NumberFilterOperator; value: number } | undefined
  reset: () => void
  getModelAsString: () => string
} => {
  const [operator, setOperator] = useState<NumberFilterOperator>(
    NumberFilterOperator.EQUALS
  )
  const [value, setValue] = useState<number>()

  const reset = useCallback(() => {
    setOperator(NumberFilterOperator.EQUALS)
    setValue(undefined)
  }, [])

  const isActive = useCallback(
    () =>
      [NumberFilterOperator.BLANK, NumberFilterOperator.NOT_BLANK].includes(
        operator
      ) || value !== undefined,
    [operator, value]
  )

  const model = useCallback(() => {
    if (isActive()) {
      return { operator, value: value ?? 0 }
    }
    return undefined
  }, [operator, value])

  const getModelAsString = useCallback(() => {
    if (!isActive()) return ''
    if (
      [NumberFilterOperator.BLANK, NumberFilterOperator.NOT_BLANK].includes(
        operator
      )
    ) {
      return `(${operator.charAt(0)}${_.lowerCase(operator.slice(1))})`
    }
    return `${
      operator === NumberFilterOperator.EQUALS
        ? '='
        : operator === NumberFilterOperator.NOT_EQUALS
        ? '≠'
        : operator === NumberFilterOperator.LESS_THAN
        ? '<'
        : operator === NumberFilterOperator.LESS_THAN_OR_EQUALS
        ? '≤'
        : operator === NumberFilterOperator.GREATER_THAN
        ? '>'
        : operator === NumberFilterOperator.GREATER_THAN_OR_EQUALS
        ? '≥'
        : ''
    } ${value}`
  }, [operator, value])

  return {
    operator,
    setOperator,
    value,
    setValue,
    isActive,
    model,
    reset,
    getModelAsString,
  }
}
