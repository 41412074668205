import { intl } from '../i18n'
import { addActionLog } from '../utils/monitoring'

const ACTION_LOGNAME_FETCH_ERROR: string = 'getCacheControlHeaders failed'
const MAX_FETCH_RETRY: number = 3

type Headers = {
  lastModified?: string
  eTag?: string
}
class CacheExpirationHandler {
  ignore = false
  headers: Headers = {}

  sleep = () => new Promise(resolve => setTimeout(resolve, 500))

  getCacheControlHeaders = async () => {
    let retry: number = 0
    while (retry <= MAX_FETCH_RETRY) {
      try {
        retry++
        const response = await fetch(window.location.origin, {
          method: 'HEAD',
          headers: {
            'Cache-Control': 'no-cache',
            pragma: 'no-cache',
            expires: '0',
          },
        })
        const lastModified = response.headers.get('Last-Modified') || undefined
        const eTag = response.headers.get('ETag') || undefined
        if (!response.ok || (!lastModified && !eTag)) {
          await this.sleep()
          continue
        }
        return {
          lastModified,
          eTag,
        }
      } catch (e) {
        await this.sleep()
        continue
      }
    }
    return {
      lastModified: undefined,
      eTag: undefined,
    }
  }

  private execute = async (event: Event) => {
    if (this.ignore) {
      return
    }
    const newHeaders = await this.getCacheControlHeaders()

    if (!newHeaders.lastModified && !newHeaders.eTag) {
      const name = ACTION_LOGNAME_FETCH_ERROR
      addActionLog(name, {
        name,
        actionType: 'other',
        prevETag: this.headers?.eTag ?? '',
        eTag: newHeaders?.eTag ?? '',
        prevLastModified: this.headers?.lastModified ?? '',
        lastModified: newHeaders?.lastModified ?? '',
      })
      return
    }

    if (
      (this.headers.lastModified &&
        this.headers.lastModified !== newHeaders.lastModified) ||
      (this.headers.eTag && this.headers.eTag !== newHeaders.eTag)
    ) {
      this.ignore = true
      event.preventDefault()
      event.stopPropagation()
      if (
        window.confirm(
          intl.formatMessage({ id: 'cacheExpiration.confirmMessage' })
        )
      ) {
        window.location.reload()
      }
    }
  }

  register = async () => {
    this.headers = await this.getCacheControlHeaders()
    window.addEventListener('focus', this.execute)
  }
}

export default new CacheExpirationHandler()
