import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import _ from 'lodash'
import { intl } from '../../../../../../i18n'
import { IDoesFilterPassParams, ColDef } from 'ag-grid-community'
import {
  BulkSheetFilter,
  FilterSelect,
  FilterInput,
  FilterFooter,
} from '../common'
import { useNumberFilter } from './useNumberFilter'

export type NumberFilter = {
  operator: NumberFilterOperator
  value: number
}

export enum NumberFilterOperator {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUALS = 'LESS_THAN_OR_EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUALS = 'GREATER_THAN_OR_EQUALS',
  BLANK = 'BLANK',
  NOT_BLANK = 'NOT_BLANK',
}

export const NumberFilterOperatorLabel = {
  [NumberFilterOperator.EQUALS]: intl.formatMessage({
    id: 'bulksheet.filter.numberFilter.operator.equals',
  }),
  [NumberFilterOperator.NOT_EQUALS]: intl.formatMessage({
    id: 'bulksheet.filter.numberFilter.operator.notEquals',
  }),
  [NumberFilterOperator.LESS_THAN]: intl.formatMessage({
    id: 'bulksheet.filter.numberFilter.operator.lessThan',
  }),
  [NumberFilterOperator.LESS_THAN_OR_EQUALS]: intl.formatMessage({
    id: 'bulksheet.filter.numberFilter.operator.lessThanOrEquals',
  }),
  [NumberFilterOperator.GREATER_THAN]: intl.formatMessage({
    id: 'bulksheet.filter.numberFilter.operator.greaterThan',
  }),
  [NumberFilterOperator.GREATER_THAN_OR_EQUALS]: intl.formatMessage({
    id: 'bulksheet.filter.numberFilter.operator.greaterThanOrEquals',
  }),
  [NumberFilterOperator.BLANK]: intl.formatMessage({
    id: 'bulksheet.filter.numberFilter.operator.blank',
  }),
  [NumberFilterOperator.NOT_BLANK]: intl.formatMessage({
    id: 'bulksheet.filter.numberFilter.operator.notBlank',
  }),
}

interface Props {
  filterChangedCallback: () => void
  valueGetter: (params) => any
  colDef: ColDef
}

export const ClientSideNumberFilter = forwardRef((props: Props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null)

  // State
  const filter = useNumberFilter()

  // Ag-grid custom filter
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params: IDoesFilterPassParams) {
        // Don't filter new rows
        if (params.data.isAdded) return true
        const numberValue = props.valueGetter(params)
        if (
          [NumberFilterOperator.BLANK, NumberFilterOperator.NOT_BLANK].includes(
            filter.operator
          )
        ) {
          switch (filter.operator) {
            case NumberFilterOperator.BLANK:
              return !numberValue
            case NumberFilterOperator.NOT_BLANK:
              return !!numberValue
          }
        }
        if (!filter.value) return true
        switch (filter.operator) {
          case NumberFilterOperator.EQUALS:
            return numberValue === filter.value
          case NumberFilterOperator.NOT_EQUALS:
            return numberValue !== filter.value
          case NumberFilterOperator.LESS_THAN:
            return numberValue < filter.value
          case NumberFilterOperator.LESS_THAN_OR_EQUALS:
            return numberValue <= filter.value
          case NumberFilterOperator.GREATER_THAN:
            return numberValue > filter.value
          case NumberFilterOperator.GREATER_THAN_OR_EQUALS:
            return numberValue >= filter.value
          case NumberFilterOperator.BLANK:
            return !numberValue
          case NumberFilterOperator.NOT_BLANK:
            return !!numberValue
          default:
            // should never happen
            console.warn('invalid filter type ' + filter.operator)
            return false
        }
      },

      isFilterActive() {
        return filter.isActive()
      },

      getModel() {
        return filter.model()
      },

      setModel(model) {
        if (!model) {
          filter.reset()
          return
        }
        if (model.filterType) {
          filter.setOperator(model.type?.toUpperCase())
          filter.setValue(model.filter)
        } else {
          filter.setOperator(model.operator)
          filter.setValue(model.value)
        }
      },

      afterGuiAttached() {
        inputRef.current && inputRef.current.focus()
      },

      getModelAsString() {
        return filter.getModelAsString()
      },
    }
  })

  useEffect(() => {
    props.filterChangedCallback()
  }, [filter.value, filter.operator])

  // Event
  const onOperatorChange = useCallback(v => filter.setOperator(v), [])
  const onNumberChange = useCallback(v => {
    const num = parseFloat(v)
    filter.setValue(Number.isFinite(num) ? num : undefined)
  }, [])
  const clearFilter = useCallback(() => filter.reset(), [])

  return (
    <BulkSheetFilter>
      <FilterSelect
        variant="outlined"
        value={filter.operator}
        onChange={e => onOperatorChange(e.target.value as NumberFilterOperator)}
        sx={{ width: '100%' }}
      >
        {Object.values(NumberFilterOperator).map(v => (
          <option key={`number-filter-option-${v}`} value={v}>
            {NumberFilterOperatorLabel[v]}
          </option>
        ))}
      </FilterSelect>
      <FilterInput
        type="number"
        inputRef={inputRef}
        value={filter.value ?? ''}
        onChange={e => {
          onNumberChange(e.target.value)
        }}
        placeholder={'Filter...'}
        sx={{ height: 22 }}
      />
      <FilterFooter loading={false} onClick={clearFilter} />
    </BulkSheetFilter>
  )
})
