import { ColumnType } from '../../containers/commons/AgGrid'
import { SequenceNoCellRenderer } from '../../containers/commons/AgGrid/components/cell/custom/sequenceNo/SequenceNoCellRenderer'
import { BulkSheetProperty } from './bulksheetProperty'

export const rowNumber: BulkSheetProperty = {
  field: 'rowNumber',
  type: [ColumnType.sequenceNo],
  resizable: false,
  width: 35,
  cellRenderer: SequenceNoCellRenderer,
  cellRendererParams: params => {
    return {
      value: params.node.rowIndex + 1,
    }
  },
}
