import { adaptPermanentUiStateBefore20220208 } from './../../compatible-adaptors/permanentUiStateCompatibleAdaptor'
import { generateUuid } from './../../../utils/uuids'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useUiStateStorageService } from '../../../infrastructure/adaptors/uiStateStorageServiceAdaptor'
import { UiStateScope, PermanentUiState, getKey } from '../../model/uiState'
import { generateCode } from '../../../utils/code'
import DateTimeVO from '../../../domain/value-object/DateTimeVO'
import { ApiRequestValue, ApiResponseValue } from '../../model/api'
import Auth from '../../../lib/commons/auth'

type PermanentUiStateStorageService = {
  stored: PermanentUiState<ApiResponseValue>[]
  save: (request: SavePermanentUiStateRequest<ApiRequestValue>) => Promise<void>
  delete: (uuid: string) => Promise<void>
  fetch: () => Promise<void>
  getByCode: (
    code: string
  ) => Promise<PermanentUiState<ApiResponseValue> | undefined>
}

type CreatePermanentUiStateRequest<T> = {
  name: string
  scope: UiStateScope
  value: T
}

type UpdatePermanentUiStateRequest<T> = {
  uuid: string
  name: string
  scope: UiStateScope
  value: T
}

export type SavePermanentUiStateRequest<T> =
  | CreatePermanentUiStateRequest<T>
  | UpdatePermanentUiStateRequest<T>

/**
 *
 * TODO: Replace Auth to some other suitable module.
 *
 * @param functionUuid
 * @param uiStateKey
 * @param projectUuid
 * @returns PermanentUiStateStorageService<T>
 */
export const usePermanentUiStateStorageService = (
  functionUuid: string,
  uiStateKey: string,
  projectUuid: string
): PermanentUiStateStorageService => {
  const uiStateStorageService = useUiStateStorageService()

  const [permanentUiStates, setPermanentUiStates] = useState<
    PermanentUiState<ApiResponseValue>[]
  >([])

  const user = Auth.getCurrentTenant()?.user!
  const fetchPermanentUiState = useCallback(async () => {
    const fetchedPermanentUiState = await Promise.all([
      uiStateStorageService.fetch(
        functionUuid,
        getKey(uiStateKey, 'CROSS_PROJECT', projectUuid),
        'CROSS_PROJECT'
      ),
      uiStateStorageService.fetch(
        functionUuid,
        getKey(uiStateKey, 'TENANT', projectUuid),
        'TENANT'
      ),
      uiStateStorageService.fetch(
        functionUuid,
        getKey(uiStateKey, 'USER', projectUuid),
        'USER'
      ),
    ]).then(result => {
      const [crossProjectUiState, tenantUiState, userUiState] = result
      return [
        ...(crossProjectUiState || []),
        ...(tenantUiState || []),
        ...(userUiState || []),
      ]
    })
    return fetchedPermanentUiState
  }, [])
  const fetched = useRef(false)
  const fetch = useCallback(async () => {
    const fetchedPermanentUiState = await fetchPermanentUiState()
    fetched.current = true
    const adapted = fetchedPermanentUiState.map(s =>
      adaptPermanentUiStateBefore20220208(s)
    )
    setPermanentUiStates(adapted)
  }, [])

  const create = useCallback(
    async (request: CreatePermanentUiStateRequest<ApiRequestValue>) => {
      const { name, scope, value } = request
      let oldPermanentUiStates = permanentUiStates
      if (!fetched.current) {
        oldPermanentUiStates = await fetchPermanentUiState()
      }
      const existingCodes = oldPermanentUiStates.map(s => s.code)
      const newUiState = {
        uuid: generateUuid(),
        code: generateCode(existingCodes),
        name,
        scope,
        value,
        updatedBy: user.name,
        updatedAt: new DateTimeVO().toString(),
      }
      const newPermanentUiStates = [...oldPermanentUiStates, newUiState]
      const newPermanentUiStatesWithTargetScope = newPermanentUiStates.filter(
        s => s.scope === scope
      )
      setPermanentUiStates(newPermanentUiStates)
      uiStateStorageService.save(
        functionUuid,
        getKey(uiStateKey, scope, projectUuid),
        scope,
        newPermanentUiStatesWithTargetScope
      )
    },
    [permanentUiStates, user]
  )

  const update = useCallback(
    async (request: UpdatePermanentUiStateRequest<ApiRequestValue>) => {
      const { uuid, name, scope, value } = request
      let oldPermanentUiStates = permanentUiStates
      if (!fetched.current) {
        oldPermanentUiStates = await fetchPermanentUiState()
      }
      const targetIndex = oldPermanentUiStates.findIndex(s => s.uuid === uuid)
      if (targetIndex < 0) {
        console.warn(`PermanentUiState with uuid=${uuid} does not exist.`)
        return
      }
      const target = oldPermanentUiStates[targetIndex]
      const previousScope = target.scope
      const updated = {
        uuid,
        code: target.code,
        name,
        scope,
        value,
        updatedBy: user.name,
        updatedAt: new DateTimeVO().toString(),
      }
      const newPermanentUiStates = oldPermanentUiStates.map((s, i) => {
        if (i === targetIndex) return updated
        return s
      })
      setPermanentUiStates(newPermanentUiStates)
      const newPermanentUiStatesWithTargetScope = newPermanentUiStates.filter(
        s => s.scope === scope
      )
      uiStateStorageService.save(
        functionUuid,
        getKey(uiStateKey, scope, projectUuid),
        scope,
        newPermanentUiStatesWithTargetScope
      )
      if (previousScope !== scope) {
        const newPermanentUiStatesWithPrevScope = newPermanentUiStates.filter(
          s => s.scope === previousScope
        )
        uiStateStorageService.save(
          functionUuid,
          getKey(uiStateKey, previousScope, projectUuid),
          previousScope,
          newPermanentUiStatesWithPrevScope
        )
      }
    },
    [permanentUiStates, user]
  )

  const save = useCallback(
    async (request: SavePermanentUiStateRequest<ApiRequestValue>) => {
      if ('uuid' in request && !!request.uuid) {
        update(request)
      } else {
        create(request)
      }
    },
    [create, update]
  )

  const remove = useCallback(
    async (uuid: string) => {
      let oldPermanentUiStates = permanentUiStates
      if (!fetched.current) {
        oldPermanentUiStates = await fetchPermanentUiState()
      }
      const targetIndex = oldPermanentUiStates.findIndex(s => s.uuid === uuid)
      if (targetIndex < 0) {
        console.warn(`PermanentUiState with uuid=${uuid} does not exist.`)
        return
      }
      const target = oldPermanentUiStates[targetIndex]
      const targetScope = target.scope
      const newPermanentUiStates = oldPermanentUiStates.filter(
        (_, i) => i !== targetIndex
      )
      setPermanentUiStates(newPermanentUiStates)
      const newPermanentUiStatesWithTargetScope = newPermanentUiStates.filter(
        s => s.scope === targetScope
      )
      uiStateStorageService.save(
        functionUuid,
        getKey(uiStateKey, targetScope, projectUuid),
        targetScope,
        newPermanentUiStatesWithTargetScope
      )
    },
    [permanentUiStates]
  )

  const getByCode = useCallback(async (code: string) => {
    const candidates = await Promise.all([
      uiStateStorageService.fetch(
        functionUuid,
        getKey(uiStateKey, 'CROSS_PROJECT', projectUuid),
        'CROSS_PROJECT'
      ),
      uiStateStorageService.fetch(
        functionUuid,
        getKey(uiStateKey, 'TENANT', projectUuid),
        'TENANT'
      ),
    ]).then(result => {
      const [crossProjectUiState, tenantUiState] = result
      return [
        ...(crossProjectUiState || []),
        ...(tenantUiState || []),
      ] as PermanentUiState<ApiResponseValue>[]
    })
    return candidates.find(s => s.code === code)
  }, [])

  return {
    stored: permanentUiStates,
    save,
    delete: remove,
    fetch,
    getByCode,
  }
}
