import React from 'react'
import { Color } from '../../../../../styles/commonStyles'
import { ToggleButtonGroup, ToggleButton, Typography } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'

interface Props {
  value: string | undefined
  options: { value: string; label: string }[]
  onChange: (value: string) => void
  disabled?: boolean
  height?: number
}

const ExclusiveToggleGroup: React.FC<Props> = (props: Props) => {
  const { value, options, onChange, disabled } = props
  const onChangeValue = (newValue: string | null) => {
    if (!newValue) {
      return
    }
    onChange(newValue)
  }
  const height = props.height || 24
  return (
    <ToggleButtonGroup
      value={value}
      onChange={(e, v) => onChangeValue(v)}
      exclusive={true}
      style={{
        border: `1px solid ${colorPalette.monotone[2]}`,
        width: 'fit-content',
        margin: '0 3px',
        background: colorPalette.monotone[0],
      }}
    >
      {options.map((option, i) => (
        <ToggleButton
          size="small"
          key={`toggle-${option.value}-${i}`}
          value={option.value}
          sx={{
            textTransform: 'none',
            height,
            borderRadius: '4px !important',
            margin: '2px',
            border: 'none !important',
            color:
              option.value === value
                ? `${colorPalette.monotone[0]} !important`
                : colorPalette.monotone[4],
            background:
              option.value === value && !disabled
                ? `${colorPalette.skyBlue[7]} !important`
                : `${colorPalette.monotone[0]} 0% 0% no-repeat padding-box !important`,
            width: '100%',
          }}
          disabled={disabled}
        >
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              color: disabled ? colorPalette.monotone[2] : '',
            }}
          >
            {option.label}
          </Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default ExclusiveToggleGroup
