import { ValueGetterParams } from 'ag-grid-community'
import { intl } from '../../../i18n'
import IconCellRenderer from '../../containers/commons/AgGrid/components/cell/common/iconCell'
import { ColumnType } from '../../containers/commons/AgGrid'
import { BulkSheetProperty } from './bulksheetProperty'

export const revision: BulkSheetProperty = {
  field: 'revision',
  headerName: intl.formatMessage({ id: 'revision' }),
  hide: true,
  width: 90,
}
export const createdBy: BulkSheetProperty = {
  field: 'createdBy',
  headerName: intl.formatMessage({ id: 'createdBy' }),
  hide: true,
  width: 110,
  valueGetter: (params: ValueGetterParams) => {
    return params.data.createdBy?.name
  },
  cellRenderer: IconCellRenderer,
  cellRendererParams: {
    labelField: 'createdBy.name',
    iconUrlField: 'createdBy.iconUrl',
  },
}
export const createdAt: BulkSheetProperty = {
  field: 'createdAt',
  headerName: intl.formatMessage({ id: 'createdAt' }),
  hide: true,
  width: 120,
  type: [ColumnType.dateTime],
}
export const updatedBy: BulkSheetProperty = {
  field: 'updatedBy',
  headerName: intl.formatMessage({ id: 'updatedBy' }),
  hide: true,
  width: 110,
  valueGetter: (params: ValueGetterParams) => {
    return params.data.updatedBy?.name
  },
  cellRenderer: IconCellRenderer,
  cellRendererParams: {
    labelField: 'updatedBy.name',
    iconUrlField: 'updatedBy.iconUrl',
  },
}
export const updatedAt: BulkSheetProperty = {
  field: 'updatedAt',
  headerName: intl.formatMessage({ id: 'updatedAt' }),
  hide: true,
  width: 120,
  type: [ColumnType.dateTime],
}
