import { IconButton } from '@mui/material'
import RoundChatIcon from '../../../components/icons/RoundChatIcon'
import store, { AllState } from '../../../../store'
import {
  ViewType,
  toggleWbsItemCommentSummaries,
  openWbsItemCommentSummaries,
} from '../../../../store/information'
import { Color, IconColor } from '../../../../styles/commonStyles'
import AnnotationMark from './AnnotationMark'
import { useSelector } from 'react-redux'

const WbsItemCommentUserSummaryIcon = () => {
  const wbsItemCommentSummaryOpened = useSelector<AllState>(
    state =>
      state.information.open &&
      state.information.type === ViewType.WBS_COMMENT_USER_SUMMARIES
  )
  const hasNew = useSelector<AllState, boolean>(
    state => state.wbsItemCommentUserSummary.hasNew
  )
  const { type } = useSelector((state: AllState) => state.information)
  return (
    <IconButton
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        if (type === ViewType.WBS_COMMENT_USER_SUMMARIES) {
          store.dispatch(toggleWbsItemCommentSummaries())
        } else {
          store.dispatch(openWbsItemCommentSummaries())
        }
      }}
    >
      <RoundChatIcon
        style={{
          height: '17px',
          verticalAlign: 'middle',
          margin: 0,
          cursor: 'pointer',
          color: wbsItemCommentSummaryOpened ? Color.MAIN : IconColor.GREY,
          fill: wbsItemCommentSummaryOpened ? Color.MAIN : 'none',
        }}
      />
      {hasNew && <AnnotationMark />}
    </IconButton>
  )
}

export default WbsItemCommentUserSummaryIcon
