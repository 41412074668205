import API, { APIResponse } from '../commons/api'
import { WbsItemRow } from './wbsItem'

export const TAG_DELIMITER = ','
// Space, Full width space, Comma, Full width comma
export const TAG_CHARACTERS_PROHIBITED_LIST = ' 　,，'

export interface TagForWbsItem {
  uuid: string
  name: string
  backgroundColor: string
}

/**
 *
 * @param params
 */
export const fetchTags = async (params: {
  projectUuid: string
}): Promise<APIResponse> => {
  return API.functional.request('GET', '/api/v1/projects/tags', params)
}

export const getTagFilterOptionUuids = async ({
  projectUuid,
  rootProjectPlanUuid,
}: {
  projectUuid: string
  rootProjectPlanUuid?: string
}): Promise<APIResponse> => {
  return API.functional.request('GET', '/api/v1/projects/tags/filter_options', {
    projectUuid,
    rootProjectPlanUuid,
  })
}

export interface TagsInput {
  uuid: string
  wbsItemUuid: string | undefined
  tagUuids: string[]
}

export const getTextColor = (backgroundColor?: string) => {
  const brightness = backgroundColor
    ? parseInt(backgroundColor.substring(1, 3), 16) * 0.299 +
      parseInt(backgroundColor.substring(3, 5), 16) * 0.587 +
      parseInt(backgroundColor.substring(5, 7), 16) * 0.114
    : 256
  return brightness >= 180 ? '#000000' : '#ffffff'
}

export const isTagColumnEdited = (
  before?: TagForWbsItem[],
  after?: TagForWbsItem[]
) => {
  return (
    before
      ?.map(v => v.uuid)
      .sort()
      .join() !==
    after
      ?.map(v => v.uuid)
      .sort()
      .join()
  )
}

interface ICreateTagInputs {
  uuid: string
  wbsItem: WbsItemRow
}
export const createTagInputs = (
  added?: ICreateTagInputs[],
  edited?: {
    before: ICreateTagInputs
    after: ICreateTagInputs
  }[]
): TagsInput[] => {
  const addedTags =
    added
      ?.filter(v => !!v.wbsItem.tags && v.wbsItem.tags.length > 0)
      .map(
        v =>
          ({
            uuid: v.uuid,
            wbsItemUuid: v.wbsItem.uuid,
            tagUuids: v.wbsItem.tags!.map(w => w.uuid),
          } as TagsInput)
      ) ?? []
  const editedTags =
    edited
      ?.filter(v =>
        isTagColumnEdited(
          v.before.wbsItem.tags || [],
          v.after.wbsItem.tags || []
        )
      )
      .map(
        v =>
          ({
            uuid: v.after.uuid,
            wbsItemUuid: v.after.wbsItem.uuid,
            tagUuids: Array.isArray(v.after.wbsItem.tags)
              ? v.after.wbsItem.tags?.map(w => w.uuid)
              : [],
          } as TagsInput)
      ) ?? []
  return [...addedTags, ...editedTags]
}
