import { useCallback, useEffect, useState } from 'react'
import { ServerSideAutocompleteConditionItemLabel } from '../ServerSideAutocompleteConditionItemLabel'
import { SprintAutocompleteConditionProps } from '../../search-components/wbs-item-search-components/SprintAutocompleteCondition'
import sprint, { SprintStatus } from '../../../../../lib/functions/sprint'
import { intl } from '../../../../../i18n'
import { SelectOption } from '../../../../model/selectOption'
import { useProjectPrivateContext } from '../../../../context/projectContext'

type SprintAutocompleteConditionLabelItemProps = Omit<
  SprintAutocompleteConditionProps,
  'colSpan' | 'onEnter'
> & { search: () => void }

export const SprintAutocompleteConditionItemLabel = ({
  value,
  teamUuid,
  onChange,
  ...other
}: SprintAutocompleteConditionLabelItemProps) => {
  const searchOptions = useCallback(
    async (inputValue: string) => {
      if (!teamUuid) return []
      const response = await sprint.searchByText(teamUuid, inputValue)
      return (response || []).map(v => ({
        value: v.uuid,
        name: v.name,
      }))
    },
    [teamUuid]
  )
  const fetchOption = useCallback(
    async (value: string) => {
      if (!teamUuid) return undefined
      const response = await sprint.getSingleByUuid(value)
      if (!response) {
        onChange(undefined)
        return undefined
      }
      return {
        value: response.uuid,
        name: response.name,
      }
    },
    [teamUuid]
  )

  const [sprintOptions, setSprintOptions] = useState<SelectOption[]>([])
  const { projectUuid } = useProjectPrivateContext()
  const getSortIndex = (status: SprintStatus) => {
    const sortedStatusList = [
      SprintStatus.INPROGRESS,
      SprintStatus.STANDBY,
      SprintStatus.FINISHED,
      SprintStatus.CANCELED,
    ]
    return sortedStatusList.findIndex(v => v === status)
  }
  useEffect(() => {
    const getSprint = async () => {
      if (!teamUuid) return
      const sprints = await sprint.searchByText(teamUuid)

      setSprintOptions(
        sprints
          .sort((a, b) => getSortIndex(a.status) - getSortIndex(b.status))
          .map(v => ({ value: v.uuid, name: v.name, groupBy: v.status }))
      )
    }
    getSprint()
  }, [projectUuid, teamUuid])

  return (
    <ServerSideAutocompleteConditionItemLabel
      {...other}
      header={intl.formatMessage({
        id: 'wbsItemSearchFilterPanel.sprint',
      })}
      value={value}
      onChange={onChange}
      searchOptions={searchOptions}
      fetchOption={fetchOption}
      deps={[teamUuid]}
      options={sprintOptions}
    />
  )
}
