import { intl } from '../../../i18n'
import { UserBasic } from '../../../lib/functions/user'
import { WbsItemTypeVO } from '../../value-object/WbsItemTypeVO'

export enum WbsItemAdditionalPropertyType {
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  MULTI_LINE_TEXT = 'MULTI_LINE_TEXT',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  CHECKBOX = 'CHECKBOX',
  SELECT = 'SELECT',
  ENTITY_SEARCH = 'ENTITY_SEARCH',
}

export type WbsItemAdditionalPropertySelectOption = {
  uuid: string
  selectOption: string
}

export enum EntitySearchReferenceEntity {
  PROCESS = 'PROCESS',
  DELIVERABLE_LIST = 'DELIVERABLE_LIST',
  DELIVERABLE = 'DELIVERABLE',
  TASK = 'TASK',
  TEAM = 'TEAM',
  PROJECT_MEMBER = 'PROJECT_MEMBER',
}
export const getEntitySearchReferenceEntityLabel = (
  value: EntitySearchReferenceEntity
) => {
  switch (value) {
    case EntitySearchReferenceEntity.PROCESS:
      return intl.formatMessage({
        id: 'wbsItemAdditionalProperties.entitySearchReferenceEntity.process',
      })
    case EntitySearchReferenceEntity.DELIVERABLE_LIST:
      return intl.formatMessage({
        id: 'wbsItemAdditionalProperties.entitySearchReferenceEntity.deliverableList',
      })
    case EntitySearchReferenceEntity.DELIVERABLE:
      return intl.formatMessage({
        id: 'wbsItemAdditionalProperties.entitySearchReferenceEntity.deliverable',
      })
    case EntitySearchReferenceEntity.TASK:
      return intl.formatMessage({
        id: 'wbsItemAdditionalProperties.entitySearchReferenceEntity.task',
      })
    case EntitySearchReferenceEntity.TEAM:
      return intl.formatMessage({
        id: 'wbsItemAdditionalProperties.entitySearchReferenceEntity.team',
      })
    case EntitySearchReferenceEntity.PROJECT_MEMBER:
      return intl.formatMessage({
        id: 'wbsItemAdditionalProperties.entitySearchReferenceEntity.projectMember',
      })
    default:
      return ''
  }
}

export type WbsItemAdditionalPropertyEntity = {
  uuid: string
  projectUuid: string
  lockVersion: number
  revision: string
  propertyName: string
  propertyType: WbsItemAdditionalPropertyType
  required: boolean
  selectOptions: WbsItemAdditionalPropertySelectOption[] | undefined
  entitySearchReferenceEntity: EntitySearchReferenceEntity | undefined
  wbsItemTypes: WbsItemTypeVO[] | undefined
  createdAt: number
  createdBy: UserBasic | undefined
  updatedAt: number
  updatedBy: UserBasic | undefined
}
