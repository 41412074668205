import {
  CellClassParams,
  CellStyle,
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueParserParams,
  ValueSetterParams,
} from 'ag-grid-community'
import { ColumnType } from '../../../containers/commons/AgGrid'
import MultiAutocompleteCell from '../../../containers/commons/AgGrid/components/cell/common/multiAutocomplete'
import { multiAutocompleteCellFilterValueFormatter } from '../../../containers/commons/AgGrid/components/cell/common/multiAutocomplete/cellEditor'
import { EntitySearchValue } from '../../../containers/meta/repositories'
import IconCellRenderer from '../../../containers/commons/AgGrid/components/cell/common/iconCell'
import { WbsItemCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/wbsItemName/wbsItemCellRenderer'
import { SequenceNoCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/sequenceNo/SequenceNoCellRenderer'
import AttachmentCellFilter from '../../../containers/commons/AgGrid/components/cell/custom/attachment/attachmentCellFilter'
import { DetailCellFilter } from '../../../containers/commons/AgGrid/components/cell/custom/detail'
import MultiLineTextCell from '../../../containers/commons/AgGrid/components/cell/custom/multiLineText'
import ProjectMember from '../../../../lib/functions/projectMember'
import { getLabel, parse } from '../../../../lib/commons/i18nLabel'
import {
  CustomEnumCode,
  getByCodeAndGroupKeys,
} from '../../../../lib/functions/customEnumValue'
import {
  AttachmentCellRenderer,
  EntitySearchCellRenderer,
  ProjectPlanActionCellRenderer,
  ProjectPlanStatusCellRenderer,
  MyWbsItemsCustomEnumCellRenderer,
} from '../../../containers/BulkSheetView/components/cellRenderer'
import {
  ClientSideNumberFilter,
  ClientSideTextFilter,
  ClientSideSelectFilter,
  CustomEnumFilter,
} from '../../../containers/BulkSheetView/components/filter'
import {
  DateCellEditor,
  EntitySearchCellEditor,
  MultiAutocompleteCellEditor,
  NumberCellEditor,
  MyWbsItemsCustomEnumCellEditor,
} from '../../../containers/BulkSheetView/components/cellEditor'
import {
  getWbsItemStatusColorCode,
  getWbsItemTreeStatusColorCode,
} from '../../../../lib/functions/wbsItem'
import { WbsItemStatus } from '../../../../domain/entity/WbsItemEntity'
import { BackgroundColor } from '../../../../styles/commonStyles'
import DateVO from '../../../../vo/DateVO'
import { intl } from '../../../../i18n'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import objects from '../../../../utils/objects'
import BoolExpression from '../../../../utils/boolExpression'
import {
  CustomEnumCombinationDirection,
  CustomEnumValue,
  FunctionProperty,
} from '../../../../lib/commons/appFunction'
import { MyWbsItemRow, MyWbsItem } from '../myWbsItems'
import DateCell from '../../../containers/commons/AgGrid/components/cell/common/date'
import { toNumber } from '../../../../utils/number'
import { TagCellEditor } from '../../../containers/BulkSheetView/components/cellEditor/TagCellEditor'
import { TagCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer/TagCellRenderer'
import { FilterSortOrder, SprintDetail } from '../../../../lib/functions/sprint'
import { useLatestComment } from '../../../page-properties/bulksheet-properties/wbsItem'
import { useMemo } from 'react'

const statusCombinedValuePath = (combinedEnumCode: string) => {
  switch (combinedEnumCode) {
    case 'WBS_TYPE':
      return 'body.wbsItem.type'
    case 'TICKET_TYPE':
      return 'body.wbsItem.ticketType'
    case 'WBS_STATUS':
      return 'body.wbsItem.status'
    case 'WBS_SUBSTATUS':
      return 'body.wbsItem.substatus'
  }
  return undefined
}

const getSubStatusOptions = async (data: MyWbsItemRow) => {
  const groupKeys: string[] = []
  const wbsItem = data.body.wbsItem
  groupKeys.push(wbsItem.projectUuid)
  if (wbsItem.ticketType) {
    groupKeys.push(wbsItem.ticketType)
    groupKeys.push(wbsItem.ticketListUuid!)
  }

  const response = await getByCodeAndGroupKeys({
    customEnumCode: CustomEnumCode.WBS_SUBSTATUS,
    groupKeys: groupKeys,
  })
  return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
}

const customEnumValueSetter = (params: ValueSetterParams) => {
  const field = params.colDef.field || params.colDef.colId
  if (!field || params.oldValue === params.newValue) return false
  const { customEnumCode, combinedValuePath } = params.colDef.cellEditorParams
  const options: CustomEnumValue[] = params.context[customEnumCode]

  const customEnumValue = options.find(o => o.value === params.newValue)
  const bidirection = customEnumValue?.combinations?.find(
    v => v.direction === CustomEnumCombinationDirection.BIDIRECTION
  )
  objects.setValue(params.data, field, params.newValue)
  if (bidirection && combinedValuePath) {
    objects.setValue(
      params.data,
      combinedValuePath?.(bidirection.combinedEnumCode),
      bidirection.combinedValues?.[0]?.value
    )
  }
  params.data.edited = true
  if (!params.data.editedData) {
    params.data.editedData = { [field]: params.oldValue }
  } else if (!params.data.editedData.hasOwnProperty(field)) {
    params.data.editedData[field] = params.oldValue
  }
  store.dispatch(requireSave())
  return true
}

const today = DateVO.now()
const isStartDelayed = (wbsItem?: MyWbsItem): boolean => {
  if (!wbsItem?.status) return false
  const { scheduledDate, actualDate } = wbsItem
  const scheduledStart = scheduledDate?.startDate
    ? new DateVO(scheduledDate.startDate)
    : undefined
  const actualStart = actualDate?.startDate
    ? new DateVO(actualDate.startDate)
    : undefined
  return !!(
    scheduledStart &&
    wbsItem.status === WbsItemStatus.TODO &&
    ((!actualStart && today.isAfter(scheduledStart)) ||
      (actualStart && actualStart.isAfter(scheduledStart)))
  )
}

const isEndDelayed = (wbsItem?: MyWbsItem): boolean => {
  if (!wbsItem?.status) return false
  const { scheduledDate, actualDate } = wbsItem
  const scheduledEnd = scheduledDate?.endDate
    ? new DateVO(scheduledDate.endDate)
    : undefined
  const actualEnd = actualDate?.endDate
    ? new DateVO(actualDate.endDate)
    : undefined
  return !!(
    scheduledEnd &&
    ![WbsItemStatus.DONE, WbsItemStatus.DISCARD].includes(
      wbsItem.status as WbsItemStatus
    ) &&
    ((!actualEnd && today.isAfter(scheduledEnd)) ||
      (actualEnd && actualEnd.isAfter(scheduledEnd)))
  )
}

const useDefaultColumns = () => {
  const commentDef = {
    externalId: 'myWbsItems.commentSummary.latestComment',
    ...useLatestComment('body.commentSummary.latestComment'),
    width: 90,
  }
  const columns = useMemo(
    () => [
      {
        externalId: 'uuid',
        field: 'uuid',
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        externalId: 'rowNumber',
        field: 'rowNumber',
        type: [ColumnType.sequenceNo],
        pinned: true,
        resizable: false,
        cellRenderer: SequenceNoCellRenderer,
        cellRendererParams: params => {
          return {
            value: params.node.rowIndex + 1,
          }
        },
      },
      {
        externalId: 'myWbsItems.information',
        headerName: intl.formatMessage({ id: 'myWbsItems.information' }),
        children: [
          {
            externalId: 'myWbsItems.wbsItem.code',
            field: 'body.wbsItem.code',
            headerName: intl.formatMessage({ id: 'myWbsItems.code' }),
            hide: true,
            pinned: true,
            lockPosition: true,
            width: 90,
          },
          {
            externalId: 'myWbsItems.wbsItem.type',
            field: 'body.wbsItem.type',
            headerName: intl.formatMessage({
              id: 'myWbsItems.type',
            }),
            hide: true,
            pinned: true,
            lockPosition: true,
            width: 100,
            valueGetter: (params: ValueGetterParams) =>
              params.data?.body?.wbsItem.baseWbsItemType,
            valueFormatter: params =>
              params.data?.body?.wbsItem?.baseWbsItemType?.getNameWithSuffix(),
            filter: ClientSideSelectFilter,
            filterParams: {
              getValue: option => (option && option.name ? option.name : ''),
              getLabel: option => option.name,
            },
            comparator: (valueA, valueB) => {
              if (valueA.name === valueB.name) return 0
              return valueA.name > valueB.name ? 1 : -1
            },
          },
          {
            externalId: 'myWbsItems.wbsItem.status',
            field: 'body.wbsItem.status',
            headerName: intl.formatMessage({ id: 'myWbsItems.status' }),
            pinned: true,
            lockPosition: 'left',
            width: 90,
            editable: true,
            type: [ColumnType.customEnum],
            cellRenderer: ProjectPlanStatusCellRenderer,
            cellStyle: (params: CellClassParams<MyWbsItemRow>) => {
              const style = {}
              const wbsItem = params.data?.body?.wbsItem
              if (!wbsItem) return { ...style, backgroundColor: '#F7F7F7' }
              if (wbsItem.wbsItemType.isTask()) {
                return {
                  ...style,
                  backgroundColor: `${getWbsItemStatusColorCode(
                    wbsItem.status!
                  )}`,
                }
              }
              return {
                ...style,
                background: getWbsItemTreeStatusColorCode(wbsItem.status!),
              }
            },
            cellEditorParams: {
              customEnumCode: 'status',
            },
            filter: CustomEnumFilter,
            floatingFilter: true,
            filterParams: {
              customEnumCode: 'status',
              getValue: option => option.value,
              getLabel: option => getLabel(option.nameI18n),
            },
          },
          {
            externalId: 'myWbsItems.wbsItem.substatus',
            field: 'body.wbsItem.substatus',
            headerName: intl.formatMessage({ id: 'myWbsItems.substatus' }),
            hide: true,
            pinned: true,
            lockPosition: 'left',
            width: 90,
            editable: true,
            type: [ColumnType.customEnum],
            cellEditor: MyWbsItemsCustomEnumCellEditor,
            cellEditorParams: {
              customEnumCode: 'substatus',
              combinedValuePath: statusCombinedValuePath,
              getOptions: getSubStatusOptions,
            },
            valueSetter: customEnumValueSetter,
            cellRenderer: MyWbsItemsCustomEnumCellRenderer,
            cellRendererParams: {
              customEnumCode: 'substatus',
            },
            filter: CustomEnumFilter,
            floatingFilter: true,
            filterParams: {
              customEnumCode: 'substatus',
              getValue: option => option.value,
              getLabel: option => getLabel(option.nameI18n),
            },
          },
          {
            externalId: 'myWbsItems.action',
            field: 'action',
            headerName: 'Action',
            pinned: true,
            sortable: false,
            lockPosition: 'left',
            width: 80,
            cellRenderer: ProjectPlanActionCellRenderer,
            cellRendererParams: {
              getCommentSummary: data => data?.body?.commentSummary,
              getWbsItem: data => data?.body?.wbsItem,
              openComment: true,
            },
            valueGetter: (params: ValueGetterParams) =>
              params.data?.body?.commentSummary?.hasComment ||
              params.data?.body?.wbsItem?.uuid, // This valueGetter is to notify the action cell of the necessary that it needs to be refreshed.
            filter: DetailCellFilter,
            floatingFilter: true,
          },
          {
            externalId: 'myWbsItems.deliverableAttachmentSummary',
            field: 'body.deliverableAttachmentSummary',
            headerName: intl.formatMessage({ id: 'myWbsItems.attachments' }),
            pinned: true,
            sortable: false,
            lockPosition: 'left',
            width: 50,
            hide: true,
            cellRenderer: AttachmentCellRenderer,
            filter: AttachmentCellFilter,
            cellRendererParams: {
              getAttachmentSummary: (data: MyWbsItemRow) =>
                data?.body.deliverableAttachmentSummary,
            },
          },
          {
            externalId: 'myWbsItems.project',
            field: 'body.project',
            headerName: intl.formatMessage({ id: 'myWbsItems.project' }),
            hide: true,
            pinned: true,
            lockPosition: true,
            width: 90,
            type: [ColumnType.autocomplete],
            cellRenderer: IconCellRenderer,
            cellRendererParams: {
              labelField: 'body.project.displayName',
              iconUrlField: 'body.project.iconUrl',
            },
            filter: ClientSideSelectFilter,
            filterParams: {
              getValue: option => (option && option.uuid ? option.uuid : ''),
              getLabel: option => option.displayName,
            },
            comparator: (valueA, valueB) => {
              if (valueA.displayName === valueB.displayName) return 0
              return valueA.displayName > valueB.displayName ? 1 : -1
            },
          },
          {
            externalId: 'myWbsItems.path',
            field: 'body.path',
            headerName: intl.formatMessage({ id: 'myWbsItems.path' }),
            hide: true,
            pinned: true,
            lockPosition: true,
            width: 90,
          },
          {
            externalId: 'myWbsItems.parentWbsItem.displayName',
            field: 'body.parentWbsItem.displayName',
            headerName: intl.formatMessage({ id: 'myWbsItems.parentWbs' }),
            hide: true,
            pinned: true,
            lockPosition: true,
            width: 90,
          },
          {
            externalId: 'myWbsItems.wbsItem.displayName',
            field: 'body.wbsItem.displayName',
            headerName: intl.formatMessage({ id: 'myWbsItems.displayName' }),
            width: 400,
            pinned: true,
            editable: true,
            cellRenderer: WbsItemCellRenderer,
            cellRendererParams: {
              uiMeta: {
                requiredIf: BoolExpression.of(true),
              } as Partial<FunctionProperty>,
            },
          },
          {
            externalId: 'myWbsItems.wbsItem.priority',
            field: 'body.wbsItem.priority',
            headerName: intl.formatMessage({ id: 'myWbsItems.priority' }),
            width: 90,
            hide: true,
            editable: true,
            type: [ColumnType.myWbsItemCustomEnum],
            cellEditor: MyWbsItemsCustomEnumCellEditor,
            cellEditorParams: {
              customEnumCode: 'priority',
              getOptionKey: (data: MyWbsItemRow) =>
                data.body.wbsItem.projectUuid,
            },
            cellRenderer: MyWbsItemsCustomEnumCellRenderer,
            cellRendererParams: {
              customEnumCode: 'priority',
              getOptionKey: (data: MyWbsItemRow) =>
                data.body.wbsItem.projectUuid,
            },
            filter: CustomEnumFilter,
            floatingFilter: true,
            filterParams: {
              customEnumCode: 'priority',
              getValue: option => option.value,
              getLabel: option => getLabel(option.nameI18n),
            },
          },
          {
            externalId: 'myWbsItems.wbsItem.difficulty',
            field: 'body.wbsItem.difficulty',
            headerName: intl.formatMessage({ id: 'myWbsItems.difficulty' }),
            hide: true,
            width: 90,
            editable: true,
            type: [ColumnType.myWbsItemCustomEnum],
            cellEditor: MyWbsItemsCustomEnumCellEditor,
            cellEditorParams: {
              customEnumCode: 'difficulty',
              getOptionKey: (data: MyWbsItemRow) =>
                data.body.wbsItem.projectUuid,
            },
            cellRenderer: MyWbsItemsCustomEnumCellRenderer,
            cellRendererParams: {
              customEnumCode: 'difficulty',
              getOptionKey: (data: MyWbsItemRow) =>
                data.body.wbsItem.projectUuid,
            },
            filter: CustomEnumFilter,
            floatingFilter: true,
            filterParams: {
              customEnumCode: 'difficulty',
              getValue: option => option.value,
              getLabel: option => getLabel(option.nameI18n),
            },
          },
          {
            externalId: 'myWbsItems.tags',
            field: 'body.tags',
            headerName: intl.formatMessage({ id: 'myWbsItems.tags' }),
            width: 90,
            editable: true,
            type: [ColumnType.multiSelect],
            cellEditor: TagCellEditor,
            cellEditorParams: {
              projectUuidExtractor: data => data.body?.project?.uuid,
            },
            cellRenderer: TagCellRenderer,
            cellrendererParams: {
              uiMeta: {},
            },
            filter: ClientSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              valueFormatter: (params: ValueFormatterParams) => {
                return params.value
              },
              getValue: option => option?.uuid,
              getLabel: option => option?.name,
            },
          },
        ],
      },
      {
        externalId: 'myWbsItems.description',
        headerName: intl.formatMessage({
          id: 'myWbsItems.description.comment',
        }),
        children: [
          {
            externalId: 'myWbsItems.wbsItem.description',
            field: 'body.wbsItem.description',
            headerName: intl.formatMessage({ id: 'myWbsItems.description' }),
            hide: true,
            width: 90,
            editable: true,
            cellEditor: MultiLineTextCell.cellEditor,
            cellRenderer: MultiLineTextCell.cellRenderer,
            filter: ClientSideTextFilter,
            floatingFilter: true,
          },
          commentDef,
        ],
      },
      {
        externalId: 'myWbsItems.assignment',
        headerName: intl.formatMessage({ id: 'myWbsItems.assignment' }),
        children: [
          {
            externalId: 'myWbsItems.wbsItem.team',
            field: 'body.wbsItem.team',
            headerName: intl.formatMessage({ id: 'myWbsItems.team' }),
            width: 65,
            editable: true,
            pinned: true,
            type: [ColumnType.autocomplete],
            cellRenderer: EntitySearchCellRenderer,
            cellEditor: EntitySearchCellEditor,
            cellEditorParams: {
              entity: 'team',
              optionsFilter: (option: any, data: any): boolean =>
                option.projectUuid === data?.body?.wbsItem?.projectUuid,
            },
            suppressKeyboardEvent: params =>
              (!params.editing &&
                ['Delete', 'Backspace'].includes(params.event.key)) ||
              (params.editing && params.event.key === 'Enter'),
            filter: ClientSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              getValue: option => (option && option.uuid ? option.uuid : ''),
              getLabel: option => option.displayName,
            },
          },
          {
            externalId: 'myWbsItems.wbsItem.accountable',
            field: 'body.wbsItem.accountable',
            headerName: intl.formatMessage({ id: 'myWbsItems.accountable' }),
            width: 65,
            editable: true,
            pinned: true,
            type: [ColumnType.autocomplete],
            cellRenderer: EntitySearchCellRenderer,
            cellEditor: EntitySearchCellEditor,
            cellEditorParams: {
              entity: 'member',
              optionsFilter: (option: any, data: any): boolean =>
                option.projectUuid === data?.body?.wbsItem?.projectUuid,
            },
            suppressKeyboardEvent: params =>
              (!params.editing &&
                ['Delete', 'Backspace'].includes(params.event.key)) ||
              (params.editing && params.event.key === 'Enter'),
            filter: ClientSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              getValue: option => (option && option.uuid ? option.uuid : ''),
              getLabel: option => option.name,
            },
          },
          {
            externalId: 'myWbsItems.wbsItem.responsible',
            field: 'body.wbsItem.responsible',
            headerName: intl.formatMessage({ id: 'myWbsItems.responsible' }),
            width: 65,
            editable: true,
            pinned: true,
            type: [ColumnType.autocomplete],
            cellRenderer: EntitySearchCellRenderer,
            cellEditor: EntitySearchCellEditor,
            cellEditorParams: {
              entity: 'member',
              optionsFilter: (option: any, data: any): boolean =>
                option.projectUuid === data?.body?.wbsItem?.projectUuid,
            },
            suppressKeyboardEvent: params =>
              (!params.editing &&
                ['Delete', 'Backspace'].includes(params.event.key)) ||
              (params.editing && params.event.key === 'Enter'),
            filter: ClientSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              getValue: option => (option && option.uuid ? option.uuid : ''),
              getLabel: option => option.name,
            },
          },
          {
            externalId: 'myWbsItems.wbsItem.assignee',
            field: 'body.wbsItem.assignee',
            headerName: intl.formatMessage({ id: 'myWbsItems.assignee' }),
            width: 65,
            hide: true,
            editable: true,
            type: [ColumnType.autocomplete],
            cellRenderer: EntitySearchCellRenderer,
            cellEditor: EntitySearchCellEditor,
            cellEditorParams: {
              entity: 'member',
              optionsFilter: (option: any, data: any): boolean =>
                option.projectUuid === data?.body?.wbsItem?.projectUuid,
            },
            suppressKeyboardEvent: params =>
              (!params.editing &&
                ['Delete', 'Backspace'].includes(params.event.key)) ||
              (params.editing && params.event.key === 'Enter'),
            filter: ClientSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              getValue: option => (option && option.uuid ? option.uuid : ''),
              getLabel: option => option.name,
            },
          },
          {
            externalId: 'myWbsItems.wbsItem.watchers',
            field: 'body.wbsItem.watchers',
            headerName: intl.formatMessage({ id: 'myWbsItems.watcher' }),
            hide: true,
            width: 120,
            editable: true,
            cellEditor: MultiAutocompleteCellEditor,
            cellEditorParams: {
              search: async (text: string, projectUuid: string) =>
                ProjectMember.search(text, { projectUuid }),
              label: option => option.name,
            },
            cellRenderer: MultiAutocompleteCell.cellRenderer,
            cellRendererParams: {
              showIcon: true,
            },
            filter: ClientSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              valueFormatter: multiAutocompleteCellFilterValueFormatter,
              getValue: option => new EntitySearchValue(option).toString(),
              getLabel: option => new EntitySearchValue(option).toString(),
            },
          },
        ],
      },
      {
        externalId: 'myWbsItems.estimated',
        headerName: intl.formatMessage({ id: 'myWbsItems.estimated' }),
        children: [],
      },
      {
        externalId: 'myWbsItems.productivity',
        headerName: intl.formatMessage({ id: 'myWbsItems.productivity' }),
        children: [
          {
            externalId: 'myWbsItems.cumulation.actualHour',
            field: 'body.cumulation.actualHour',
            headerName: intl.formatMessage({
              id: 'myWbsItems.actualHour',
            }),
            width: 90,
            hide: true,
            valueGetter: (params: ValueGetterParams<MyWbsItemRow>) => {
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!w || !c) return
              return w.wbsItemType?.isTask() ? c.actualHour : c.sumActualHour
            },
            valueFormatter: (params: ValueFormatterParams) => {
              if (params.value === undefined) return ''
              return (
                Number(params.value) /
                (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
              ).toFixed(2)
            },
            cellStyle: (params: CellClassParams<MyWbsItemRow>) => {
              const numberStyle = { justifyContent: 'flex-end' }
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!w || !c) return numberStyle
              if (w.wbsItemType?.isTask() && 0 < c.actualHour) {
                return {
                  ...numberStyle,
                  cursor: 'pointer',
                  color: 'blue',
                }
              }
              return numberStyle
            },
            filter: ClientSideNumberFilter,
            floatingFilter: true,
          },
        ],
      },
      {
        externalId: 'myWbsItems.term',
        headerName: intl.formatMessage({ id: 'myWbsItems.term' }),
        children: [
          {
            externalId: 'myWbsItems.wbsItem.sprint',
            field: 'body.wbsItem.sprint',
            headerName: intl.formatMessage({ id: 'myWbsItems.sprint' }),
            hide: true,
            width: 90,
            editable: params => {
              const type = params.data?.body?.wbsItem?.wbsItemType
              return type?.isTask() || type?.isDeliverable()
            },
            type: [ColumnType.autocomplete],
            cellRenderer: EntitySearchCellRenderer,
            cellEditor: EntitySearchCellEditor,
            cellEditorParams: {
              entity: 'sprint',
              optionsFilter: (option: any, data: any): boolean =>
                option.projectUuid === data?.body?.wbsItem?.projectUuid &&
                option.teamUuid === data?.body?.wbsItem?.team?.uuid,
            },
            suppressKeyboardEvent: params =>
              (!params.editing &&
                ['Delete', 'Backspace'].includes(params.event.key)) ||
              (params.editing && params.event.key === 'Enter'),
            filter: ClientSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              optionKey: 'sprint',
              getValue: option => (option && option.uuid ? option.uuid : ''),
              getLabel: option =>
                `[${option.status}] (${option.teamName ?? ''}) ${option.name}`,
              sortValues: (_: any, options?: SprintDetail[]) => {
                if (!options) return options
                return options.sort((a, b) => {
                  if (a.status === b.status) {
                    return (a.teamName ?? '') > (b.teamName ?? '') ? 1 : -1
                  }
                  return (
                    FilterSortOrder.indexOf(a.status) -
                    FilterSortOrder.indexOf(b.status)
                  )
                })
              },
            },
            comparator: (valueA, valueB) => {
              if (valueA?.name === valueB?.name) return 0
              return (valueA?.name ?? '') > (valueB?.name ?? '') ? 1 : -1
            },
          },
          {
            externalId: 'myWbsItems.wbsItem.scheduledDate.startDate',
            field: 'body.wbsItem.scheduledDate.startDate',
            headerName: intl.formatMessage({
              id: 'myWbsItems.scheduledDate.start',
            }),
            width: 90,
            editable: true,
            pinned: true,
            cellEditor: DateCellEditor,
            valueSetter: (params: ValueSetterParams<MyWbsItemRow>) => {
              const field = params.colDef.field || params.colDef.colId
              if (
                !field ||
                (!params.oldValue && !params.newValue) ||
                params.oldValue === params.newValue
              ) {
                return false
              }
              const end = params.data.body?.wbsItem?.scheduledDate?.endDate
              if (params.newValue && end) {
                const startDate = new DateVO(params.newValue)
                const endDate = new DateVO(end)
                params.data.body.wbsItem.scheduledDate = {
                  startDate: startDate.serialize(),
                  endDate: startDate.isAfter(endDate)
                    ? startDate.serialize()
                    : endDate.serialize(),
                }
                if (startDate.isAfter(endDate)) {
                  if (!params.data.editedData) {
                    params.data.editedData = {}
                  }
                  params.data.editedData['body.wbsItem.scheduledDate.endDate'] =
                    endDate.serialize()
                }
              } else {
                objects.setValue(
                  params.data,
                  'body.wbsItem.scheduledDate.startDate',
                  params.newValue
                )
              }
              if (!params.data.editedData) {
                params.data.editedData = { [field]: params.oldValue }
              } else if (!params.data.editedData.hasOwnProperty(field)) {
                params.data.editedData[field] = params.oldValue
              }
              params.data.edited = true
              store.dispatch(requireSave())
              return true
            },
            type: [ColumnType.date],
            filter: DateCell.cellFilter,
            floatingFilter: true,
          },
          {
            externalId: 'myWbsItems.wbsItem.scheduledDate.endDate',
            field: 'body.wbsItem.scheduledDate.endDate',
            headerName: intl.formatMessage({
              id: 'myWbsItems.scheduledDate.end',
            }),
            width: 90,
            editable: true,
            pinned: true,
            cellEditor: DateCellEditor,
            valueSetter: (params: ValueSetterParams<MyWbsItemRow>) => {
              const field = params.colDef.field || params.colDef.colId
              if (
                !field ||
                (!params.oldValue && !params.newValue) ||
                params.oldValue === params.newValue
              ) {
                return false
              }
              const start = params.data.body?.wbsItem?.scheduledDate?.startDate
              if (params.newValue && start) {
                const startDate = new DateVO(start)
                const endDate = new DateVO(params.newValue)
                params.data.body.wbsItem.scheduledDate = {
                  startDate: startDate.isAfter(endDate)
                    ? endDate.serialize()
                    : startDate.serialize(),
                  endDate: endDate.serialize(),
                }
                if (startDate.isAfter(endDate)) {
                  if (!params.data.editedData) {
                    params.data.editedData = {}
                  }
                  params.data.editedData[
                    'body.wbsItem.scheduledDate.startDate'
                  ] = startDate.serialize()
                }
              } else {
                objects.setValue(
                  params.data,
                  'body.wbsItem.scheduledDate.endDate',
                  params.newValue
                )
              }
              if (!params.data.editedData) {
                params.data.editedData = { [field]: params.oldValue }
              } else if (!params.data.editedData.hasOwnProperty(field)) {
                params.data.editedData[field] = params.oldValue
              }
              params.data.edited = true
              store.dispatch(requireSave())
              return true
            },
            type: [ColumnType.date],
            filter: DateCell.cellFilter,
            floatingFilter: true,
          },
          {
            externalId: 'myWbsItems.wbsItem.actualDate.startDate',
            field: 'body.wbsItem.actualDate.startDate',
            headerName: intl.formatMessage({
              id: 'myWbsItems.actualDate.start',
            }),
            width: 90,
            editable: true,
            hide: true,
            cellEditor: DateCellEditor,
            type: [ColumnType.date],
            filter: DateCell.cellFilter,
            floatingFilter: true,
            valueSetter: (params: ValueSetterParams<MyWbsItemRow>) => {
              const field = params.colDef.field || params.colDef.colId
              if (
                !field ||
                (!params.oldValue && !params.newValue) ||
                params.oldValue === params.newValue
              ) {
                return false
              }
              const end = params.data.body?.wbsItem?.actualDate?.endDate
              if (params.newValue && end) {
                const startDate = new DateVO(params.newValue)
                const endDate = new DateVO(end)
                params.data.body.wbsItem.actualDate = {
                  startDate: startDate.serialize(),
                  endDate: startDate.isAfter(endDate)
                    ? startDate.serialize()
                    : endDate.serialize(),
                }
                if (startDate.isAfter(endDate)) {
                  if (!params.data.editedData) {
                    params.data.editedData = {}
                  }
                  params.data.editedData['body.wbsItem.actualDate.endDate'] =
                    endDate.serialize()
                }
              } else {
                objects.setValue(
                  params.data,
                  'body.wbsItem.actualDate.startDate',
                  params.newValue
                )
              }
              if (!params.data.editedData) {
                params.data.editedData = { [field]: params.oldValue }
              } else if (!params.data.editedData.hasOwnProperty(field)) {
                params.data.editedData[field] = params.oldValue
              }
              params.data.edited = true
              store.dispatch(requireSave())
              return true
            },
            valueGetter: (params: ValueGetterParams<MyWbsItemRow>) => {
              const wbsItem = params.data?.body?.wbsItem
              if (!wbsItem) return ''
              return wbsItem.actualDate?.startDate
            },
            cellRendererParams: {
              tooltip: (
                params: ICellRendererParams<MyWbsItemRow>
              ): string | undefined => {
                const wbsItem = params.data?.body?.wbsItem
                return wbsItem && isStartDelayed(wbsItem)
                  ? intl.formatMessage({ id: 'wbs.start.delayed' })
                  : undefined
              },
            },
            cellStyle: (params: CellClassParams<MyWbsItemRow>): CellStyle => {
              const style = { justifyContent: 'flex-end' }
              const wbsItem = params.data?.body?.wbsItem
              if (isStartDelayed(wbsItem)) {
                return { ...style, backgroundColor: BackgroundColor.ALERT }
              }
              return style
            },
          },
          {
            externalId: 'myWbsItems.wbsItem.actualDate.endDate',
            field: 'body.wbsItem.actualDate.endDate',
            headerName: intl.formatMessage({
              id: 'myWbsItems.actualDate.end',
            }),
            width: 90,
            editable: true,
            hide: true,
            cellEditor: DateCellEditor,
            type: [ColumnType.date],
            filter: DateCell.cellFilter,
            floatingFilter: true,
            valueSetter: (params: ValueSetterParams<MyWbsItemRow>) => {
              const field = params.colDef.field || params.colDef.colId
              if (
                !field ||
                (!params.oldValue && !params.newValue) ||
                params.oldValue === params.newValue
              ) {
                return false
              }
              const start = params.data.body?.wbsItem?.actualDate?.startDate
              if (params.newValue && start) {
                const startDate = new DateVO(start)
                const endDate = new DateVO(params.newValue)
                params.data.body.wbsItem.actualDate = {
                  startDate: startDate.isAfter(endDate)
                    ? endDate.serialize()
                    : startDate.serialize(),
                  endDate: endDate.serialize(),
                }
                if (startDate.isAfter(endDate)) {
                  if (!params.data.editedData) {
                    params.data.editedData = {}
                  }
                  params.data.editedData['body.wbsItem.actualDate.startDate'] =
                    startDate.serialize()
                }
              } else {
                objects.setValue(
                  params.data,
                  'body.wbsItem.actualDate.endDate',
                  params.newValue
                )
              }
              if (!params.data.editedData) {
                params.data.editedData = { [field]: params.oldValue }
              } else if (!params.data.editedData.hasOwnProperty(field)) {
                params.data.editedData[field] = params.oldValue
              }
              params.data.edited = true
              store.dispatch(requireSave())
              return true
            },
            valueGetter: (params: ValueGetterParams<MyWbsItemRow>) => {
              const wbsItem = params.data?.body?.wbsItem
              if (!wbsItem) return ''
              return wbsItem.actualDate?.endDate
            },
            cellRendererParams: {
              tooltip: (
                params: ICellRendererParams<MyWbsItemRow>
              ): string | undefined => {
                const wbsItem = params.data?.body?.wbsItem
                return wbsItem && isEndDelayed(wbsItem)
                  ? intl.formatMessage({ id: 'wbs.end.delayed' })
                  : undefined
              },
            },
            cellStyle: (params: CellClassParams<MyWbsItemRow>): CellStyle => {
              const style = { justifyContent: 'flex-end' }
              const wbsItem = params.data?.body?.wbsItem
              if (isEndDelayed(wbsItem)) {
                return { ...style, backgroundColor: BackgroundColor.ALERT }
              }
              return style
            },
          },
        ],
      },
      {
        externalId: 'myWbsItems.changeLog',
        headerName: intl.formatMessage({ id: 'changeLog' }),
        children: [
          {
            externalId: 'myWbsItems.wbsItem.revision',
            field: 'body.wbsItem.revision',
            headerName: intl.formatMessage({ id: 'revision' }),
            hide: true,
            width: 90,
          },
          {
            externalId: 'myWbsItems.wbsItem.createdBy',
            field: 'body.wbsItem.createdBy',
            headerName: intl.formatMessage({ id: 'createdBy' }),
            hide: true,
            width: 150,
            valueGetter: (params: ValueGetterParams) => {
              return params.data.body?.wbsItem?.createdBy?.name
            },
            cellRenderer: IconCellRenderer,
            cellRendererParams: {
              labelField: 'body.wbsItem.createdBy.name',
              iconUrlField: 'body.wbsItem.createdBy.iconUrl',
            },
            floatingFilter: true,
            filter: 'agSetColumnFilter',
          },
          {
            externalId: 'myWbsItems.wbsItem.createdAt',
            field: 'body.wbsItem.createdAt',
            headerName: intl.formatMessage({ id: 'createdAt' }),
            hide: true,
            width: 120,
            type: [ColumnType.dateTime],
            floatingFilter: true,
          },
          {
            externalId: 'myWbsItems.wbsItem.updatedBy',
            field: 'body.wbsItem.updatedBy',
            headerName: intl.formatMessage({ id: 'updatedBy' }),
            hide: true,
            width: 150,
            valueGetter: (params: ValueGetterParams) => {
              return params.data.body?.wbsItem?.updatedBy?.name
            },
            cellRenderer: IconCellRenderer,
            cellRendererParams: {
              labelField: 'body.wbsItem.updatedBy.name',
              iconUrlField: 'body.wbsItem.updatedBy.iconUrl',
            },
            floatingFilter: true,
            filter: 'agSetColumnFilter',
          },
          {
            externalId: 'myWbsItems.wbsItem.updatedAt',
            field: 'body.wbsItem.updatedAt',
            headerName: intl.formatMessage({ id: 'updatedAt' }),
            hide: true,
            width: 120,
            type: [ColumnType.dateTime],
            floatingFilter: true,
          },
        ],
      },
    ],
    []
  )
  return columns
}

export const taskEstimatedColumnDef = {
  externalId: 'myWbsItems.wbsItem.estimatedWorkload.task',
  colId: 'body.wbsItem.estimatedHour.task',
  field: 'body.wbsItem.estimatedHour',
  headerName: intl.formatMessage({
    id: 'myWbsItems.estimated.task',
  }),
  width: 80,
  hide: true,
  editable: params => params.data.body?.wbsItem?.wbsItemType?.isTask(),
  cellEditor: NumberCellEditor,
  cellClass: 'ag-numeric-cell',
  cellStyle: { justifyContent: 'flex-end' },
  valueParser: (params: ValueParserParams) => toNumber(params.newValue),
  valueSetter: (params: ValueSetterParams<MyWbsItemRow>) => {
    if (params.oldValue === params.newValue || !params.data.body?.wbsItem) {
      return false
    }
    const value = toNumber(params.newValue)
    params.data.body.wbsItem.estimatedHour = value
      ? value * (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
      : 0
    params.data.edited = true
    const field = params.colDef.field ?? params.colDef.colId ?? ''
    if (!params.data.editedData) {
      params.data.editedData = { [field]: params.oldValue }
    } else if (!params.data.editedData.hasOwnProperty(field)) {
      params.data.editedData[field] = params.oldValue
    }
    store.dispatch(requireSave())
    return true
  },
  valueGetter: (params: ValueGetterParams) => {
    const w = params.data?.body?.wbsItem
    const c = params.data?.body?.cumulation
    if (!w) return undefined
    const hour = w.estimatedHour ?? 0
    const rate = params.context.workloadUnitState?.hoursPerSelectedUnit || 1
    if (w.wbsItemType.isTask()) return hour / rate
    if (!c) return undefined
    return (c.sumTaskEstimatedHour - c.sumTaskEstimatedHourDiscard) / rate
  },
  valueFormatter: params =>
    Number.isFinite(params.value) ? parseFloat(params.value).toFixed(2) : '',
  filter: ClientSideNumberFilter,
  floatingFilter: true,
} as ColDef

export const deliverableEstimatedColumnDef = {
  externalId: 'myWbsItems.wbsItem.estimatedWorkload.deliverable',
  colId: 'body.wbsItem.estimatedHour.deliverable',
  field: 'body.wbsItem.estimatedHour',
  headerName: intl.formatMessage({
    id: 'myWbsItems.estimated.deliverable',
  }),
  width: 80,
  hide: true,
  editable: params => params.data.body?.wbsItem?.wbsItemType?.isDeliverable(),
  cellEditor: NumberCellEditor,
  cellClass: 'ag-numeric-cell',
  cellStyle: { justifyContent: 'flex-end' },
  valueParser: (params: ValueParserParams) => toNumber(params.newValue),
  valueSetter: (params: ValueSetterParams<MyWbsItemRow>) => {
    if (params.oldValue === params.newValue || !params.data.body?.wbsItem) {
      return false
    }
    const value = toNumber(params.newValue)
    params.data.body.wbsItem.estimatedHour = value
      ? value * (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
      : 0
    params.data.edited = true
    const field = params.colDef.field ?? params.colDef.colId ?? ''
    if (!params.data.editedData) {
      params.data.editedData = { [field]: params.oldValue }
    } else if (!params.data.editedData.hasOwnProperty(field)) {
      params.data.editedData[field] = params.oldValue
    }
    store.dispatch(requireSave())
    return true
  },
  valueGetter: (params: ValueGetterParams) => {
    const w = params.data?.body?.wbsItem
    const c = params.data?.body?.cumulation
    if (!w || w.wbsItemType?.isTask()) return undefined
    const hour = w.estimatedHour ?? 0
    const rate = params.context.workloadUnitState?.hoursPerSelectedUnit || 1
    if (
      w.wbsItemType.isWorkgroup() ||
      w.wbsItemType.isProcess() ||
      w.wbsItemType.isDeliverableList()
    ) {
      if (!c) return undefined
      return (
        (c.sumDeliverableEstimatedHour - c.sumDeliverableEstimatedHourDiscard) /
        rate
      )
    }
    return hour / rate
  },
  valueFormatter: params =>
    Number.isFinite(params.value) ? parseFloat(params.value).toFixed(2) : '',
  filter: ClientSideNumberFilter,
  floatingFilter: true,
} as ColDef

export default useDefaultColumns
