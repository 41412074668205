import { ColGroupDef, GridOptions } from 'ag-grid-community'
import useDefaultColumns, {
  taskEstimatedColumnDef,
  deliverableEstimatedColumnDef,
} from './defaultColumns'
import defaultGridOptions from './defaultGridOptions'
import { useMemo } from 'react'

const useWatcherTabColumnDef = () => {
  const defaultColDef = useDefaultColumns()
  const columnDefs = useMemo(() => {
    const estimatedGroup = defaultColDef.find(
      colDef => colDef.externalId === 'myWbsItems.estimated'
    ) as ColGroupDef | undefined
    if (estimatedGroup && estimatedGroup.children) {
      estimatedGroup.children.push(taskEstimatedColumnDef)
      estimatedGroup.children.push(deliverableEstimatedColumnDef)
    }
    return defaultColDef
  }, [defaultColDef])
  return columnDefs
}

export const useMyWbsItemWatcherTabGridOptions = (): GridOptions => {
  const columnDefs = useWatcherTabColumnDef()
  const gridOptions = useMemo(
    () => ({
      ...defaultGridOptions(),
      columnDefs,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return gridOptions
}
