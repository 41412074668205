import { ICellRendererParams } from 'ag-grid-community'
import { RootDiv } from '../../../commons/AgGrid/components/cell/CellRendererRoot'
import { WbsItemTypeVO } from '../../../../../domain/value-object/WbsItemTypeVO'
import { ICON_SIZE } from '../../../BulkSheet/const'

export const TicketTypeCellRenderer = (props: ICellRendererParams) => {
  const type: WbsItemTypeVO = props.value
  if (!props.colDef || !type || !type.isTicket()) return <></>
  return (
    <RootDiv>
      {type.iconUrl && (
        <img
          src={type.iconUrl}
          style={{
            height: `${ICON_SIZE}px`,
            width: `${ICON_SIZE}px`,
            margin: '5px',
            borderRadius: '50%',
          }}
        />
      )}
      {type.name}
    </RootDiv>
  )
}
