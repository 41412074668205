import {
  WbsItemEntity,
  WbsItemStatus,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { generateUuid } from '../../../../utils/uuids'

export {}

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
type UnionUndefinedToNull<T> = T extends undefined ? null : T
type UndefinedToNull<T extends Object> = {
  [Prop in keyof T]-?: UnionUndefinedToNull<T[Prop]>
}

export type WbsItemFormModel = PartialBy<
  Omit<WbsItemEntity, 'projectUuid'>,
  | 'displayName'
  | 'code'
  | 'status'
  | 'createdBy'
  | 'createdAt'
  | 'updatedBy'
  | 'updatedAt'
  | 'revision'
>
export type WbsItemEditableField = keyof Omit<
  WbsItemFormModel,
  | 'uuid'
  | 'type'
  | 'code'
  | 'actualHour'
  | 'createdBy'
  | 'createdAt'
  | 'updatedBy'
  | 'updatedAt'
  | 'revision'
>

export const createInitialValue = (type: WbsItemType): WbsItemFormModel => {
  const uuid = generateUuid()
  return {
    uuid,
    type,
    status: WbsItemStatus.TODO,
  }
}

export type UpdateFormModel = <
  K extends keyof WbsItemFormModel,
  V extends WbsItemFormModel[K]
>(
  path: K,
  value: V
) => void
export type UpdateFormModelValue<K extends keyof WbsItemFormModel> = (
  value: WbsItemFormModel[K]
) => void
