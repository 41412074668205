import { useCallback, useEffect, useMemo, useState } from 'react'
import { FocusableSearchItemLabelProps, SearchItemLabel, usePopup } from '.'
import { AutocompleteConditionProps } from '../search-components/AutocompleteCondition'
import { List, ListItem, Popover, Typography } from '@mui/material'
import { colorPalette } from '../../../style/colorPallete'
import { Button } from '../../buttons'
import { SelectOption } from '../../../model/selectOption'

type AutocompleteConditionItemLabelProps =
  FocusableSearchItemLabelProps<AutocompleteConditionProps> & {
    fetchSelectedOption: (value: string) => Promise<SelectOption | undefined>
    fetchOptions: () => Promise<SelectOption[]>
  }

export const AutocompleteConditionItemLabel = ({
  header,
  value,
  onChange,
  search,
  fetchSelectedOption,
  fetchOptions,
}: AutocompleteConditionItemLabelProps) => {
  const { ref, open, openPopup, closePopup } = usePopup()
  const [options, setOptions] = useState<SelectOption[]>([])
  const [selectedOption, setSelectedOption] = useState<
    SelectOption | undefined
  >(undefined)

  useEffect(() => {
    if (!value) {
      setSelectedOption(undefined)
      return
    }
    fetchSelectedOption(value).then(option => {
      setSelectedOption(option)
    })
  }, [value, fetchSelectedOption, setSelectedOption])

  const valueName = useMemo(() => {
    return selectedOption?.name
  }, [selectedOption])

  const onClickLabel = useCallback(() => {
    fetchOptions().then(fetchedOptions => setOptions(fetchedOptions))
    openPopup()
  }, [openPopup, fetchOptions])

  const onClear = useCallback(() => {
    onChange(undefined)
  }, [onChange])

  const onSearch = useCallback(() => {
    closePopup()
    search()
  }, [closePopup, search])

  const onChangeValue = useCallback(
    (v: any) => {
      if (value !== v.value) {
        onChange(v.value)
      }
    },
    [onChange, value]
  )

  if (!selectedOption) return <></>
  return (
    <>
      <SearchItemLabel
        ref={ref}
        active={open}
        onClick={onClickLabel}
        onClear={onClear}
      >
        {header}: {valueName}
      </SearchItemLabel>
      <Popover
        open={open}
        anchorEl={ref.current || null}
        onClose={onSearch}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List>
          {options.map(option => (
            <ListItem key={option.value} sx={{ margin: '3px 0', padding: 0 }}>
              <Button
                colorPattern="monotone"
                sx={{
                  border: 'none',
                  background: colorPalette.monotone[0],
                  width: '100%',
                  textAlign: 'start',
                  justifyContent: 'start',
                  padding: '0 3px',
                  margin: '0 5px',
                  '&:hover': { background: colorPalette.monotone[1] },
                }}
                onClick={() => onChangeValue(option)}
              >
                <Typography
                  sx={{
                    color: colorPalette.monotone[4],
                    fontWeight: 500,
                    fontSize: '14px',
                    padding: '5px 0 !important',
                    margin: '10px',
                  }}
                >
                  {option.name}
                </Typography>
              </Button>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  )
}
