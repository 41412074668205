import { useCallback, useState } from 'react'
import _ from 'lodash'
import {
  BudgetResultType,
  ProfitLossItemAmountInput,
  ProfitLossItemAmountRowBody,
  ProfitLossItemInput,
  ProfitLossItemRow,
  ProfitLossItemRowBody,
  ProfitLossItemSkeleton,
  ProfitLossItemsUpdateParams,
  fetchProfitLossItems,
  updateItems,
} from '../profitLossItems'
import { getPrevSiblings } from '../../../containers/BulkSheetView/lib/tree'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import DateVO from '../../../../vo/DateVO'
import { generateUuid } from '../../../../utils/uuids'

export const useProfitLossItemData = () => {
  const [data, setDataInternal] = useState<ProfitLossItemRow[]>([])
  const [rootUuid, setRootUuid] = useState<string | undefined>(undefined)
  const [lockVersion, setLockVersion] = useState<number>(0)
  const [summaryRows, setSummaryRows] = useState<ProfitLossItemRow[]>([])

  const makeRootRowAmounts = useCallback((item: ProfitLossItemSkeleton) => {
    const budgets = item.budgetAmounts ?? []
    const results = item.resultAmounts ?? []
    const budgetaries = item.budgetaryAmounts ?? []
    const resList: ProfitLossItemAmountRowBody[] = []
    const itemMap = new Map()
    results.forEach(v => {
      itemMap.set(v.month, v)
    })
    budgets.forEach(v => {
      if (!itemMap.has(v.month)) {
        itemMap.set(v.month, v)
      }
    })
    budgetaries.forEach(v => {
      if (!itemMap.has(v.month)) {
        itemMap.set(v.month, v)
      }
    })
    itemMap.forEach(value => {
      resList.push(new ProfitLossItemAmountRowBody(value))
    })
    return resList
  }, [])

  const makeRegisterAmounts = useCallback(
    (uuid: string) => {
      const rows = data.filter(
        v => v.treeValue.length === 2 && v.treeValue.includes(uuid)
      )
      const result: ProfitLossItemAmountInput[] =
        rows
          .map(v => {
            return v.body.amounts.map(body => {
              const date = new DateVO(body.yearMonth)
              return {
                budgetResultType: v.body.type,
                year: date.getYear(),
                month: date.getMonth(),
                amount: body.amount,
              } as ProfitLossItemAmountInput
            })
          })
          .flat() ?? []
      return result
    },
    [data]
  )

  const fetchRecords = useCallback(
    async (
      projectUuid: string,
      startDate: string,
      endDate: string,
      accountCategory: string
    ) => {
      const response = await fetchProfitLossItems({
        projectUuid,
        startDate,
        endDate,
        accountCategory,
      })
      const sources: ProfitLossItemSkeleton = response.json
      const rows: ProfitLossItemRow[] = []
      sources.children?.forEach(v => {
        // ROOT ROW
        const root: ProfitLossItemRow = {
          uuid: v.uuid,
          body: new ProfitLossItemRowBody({
            profitLossItemName: v.profitLossItemName,
            remarks: v.remarks,
            financialState: v.financialState,
            generalLedger: v.generalLedger,
            subsidiary: v.subsidiary,
          } as unknown as ProfitLossItemSkeleton),
          treeValue: [v.uuid],
        }
        root.body.amounts = makeRootRowAmounts(v)
        rows.push(root)
        // APPLICATION_BUDGET ROW
        const appBudgetUuid = generateUuid()
        const appBudgetRow: ProfitLossItemRow = {
          uuid: appBudgetUuid,
          body: new ProfitLossItemRowBody(
            {
              profitLossItemName: v.profitLossItemName,
              financialState: v.financialState,
              generalLedger: v.generalLedger,
              subsidiary: v.subsidiary,
            } as unknown as ProfitLossItemSkeleton,
            BudgetResultType.BudgetaryAmount
          ),
          treeValue: [v.uuid, appBudgetUuid],
        }
        appBudgetRow.body.amounts = v.budgetaryAmounts?.map(v => {
          return new ProfitLossItemAmountRowBody(v)
        })
        rows.push(appBudgetRow)
        // BUDGET ROW
        const budgetUuid = generateUuid()
        const budgetRow: ProfitLossItemRow = {
          uuid: budgetUuid,
          body: new ProfitLossItemRowBody(
            {
              profitLossItemName: v.profitLossItemName,
              financialState: v.financialState,
              generalLedger: v.generalLedger,
              subsidiary: v.subsidiary,
            } as unknown as ProfitLossItemSkeleton,
            BudgetResultType.Budget
          ),
          treeValue: [v.uuid, budgetUuid],
        }
        budgetRow.body.amounts = v.budgetAmounts?.map(v => {
          return new ProfitLossItemAmountRowBody(v)
        })
        rows.push(budgetRow)
        // RESULT ROW
        const resultUuid = generateUuid()
        const resultRow: ProfitLossItemRow = {
          uuid: resultUuid,
          body: new ProfitLossItemRowBody(
            {
              profitLossItemName: v.profitLossItemName,
              financialState: v.financialState,
              generalLedger: v.generalLedger,
              subsidiary: v.subsidiary,
            } as unknown as ProfitLossItemSkeleton,
            BudgetResultType.Result
          ),
          treeValue: [v.uuid, resultUuid],
        }
        resultRow.body.amounts = v.resultAmounts?.map(v => {
          return new ProfitLossItemAmountRowBody(v)
        })
        rows.push(resultRow)
      })
      setRootUuid(sources.uuid)
      setLockVersion(sources.lockVersion)
      setDataInternal(rows)
      const sumRows: ProfitLossItemRow[] = [
        {
          uuid: '',
          body: new ProfitLossItemRowBody(undefined, undefined),
          isTotal: true,
          treeValue: [],
        },
        {
          uuid: '',
          body: new ProfitLossItemRowBody(
            undefined,
            BudgetResultType.BudgetaryAmount
          ),
          isTotal: true,
          treeValue: [],
        },
        {
          uuid: '',
          body: new ProfitLossItemRowBody(undefined, BudgetResultType.Budget),
          isTotal: true,
          treeValue: [],
        },
        {
          uuid: '',
          body: new ProfitLossItemRowBody(undefined, BudgetResultType.Result),
          isTotal: true,
          treeValue: [],
        },
      ]
      setSummaryRows(sumRows)
    },
    [makeRootRowAmounts]
  )

  const save = useCallback(
    async (
      projectUuid: string,
      startDate: string,
      endDate: string,
      accountCategory: string
    ) => {
      const filteredData = data.filter(v => !!v.body.name)
      const edited = filteredData
        .filter(v => !v.body.type)
        ?.map(v => {
          const siblings = getPrevSiblings(filteredData, v)
          return {
            uuid: v.uuid,
            prevSiblingUuid: siblings[siblings.length - 1]?.uuid,
            financialStatementUuid: v.body.financialState?.uuid,
            generalLedgerUuid: v.body.generalLedger?.uuid,
            subsidiaryUuid: v.body.subsidiary?.uuid,
            profitLossItemName: v.body.name,
            remarks: v.body.remarks,
            profitLossItemAmounts: makeRegisterAmounts(v.uuid),
          } as ProfitLossItemInput
        })
      const request: ProfitLossItemsUpdateParams = {
        uuid: rootUuid,
        lockVersion,
        projectUuid,
        startDate,
        endDate,
        accountCategory,
        profitLossItems: edited,
      }
      const response = await updateItems(request)
      return response
    },
    [data, lockVersion, makeRegisterAmounts, rootUuid]
  )

  const setData = useCallback((data: ProfitLossItemRow[]) => {
    setDataInternal(data)
    store.dispatch(requireSave())
  }, [])

  return {
    data,
    setData,
    rootUuid,
    fetchRecords,
    save,
    summaryRows,
  }
}
