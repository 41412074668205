import { useCallback, useMemo } from 'react'
import { SearchItemLabel, SearchItemLabelPropsBase, usePopup } from '.'
import { CheckboxSelectConditionProps } from '../search-components/CheckboxSelectCondition'
import { Box, Popover, Typography } from '@mui/material'
import { Checkbox } from '../../inputs/Checkbox'
import { colorPalette } from '../../../style/colorPallete'

type CheckboxSelectConditionItemLabelProps =
  SearchItemLabelPropsBase<CheckboxSelectConditionProps>

export const CheckboxSelectConditionItemLabel = ({
  header,
  value,
  onChange,
  options,
  search,
}: CheckboxSelectConditionItemLabelProps) => {
  const { ref, open, openPopup, closePopup } = usePopup()

  const selectedOptions = useMemo(() => {
    return options.filter(op => (value || []).includes(op.value))
  }, [value, options])

  const valueNames = useMemo(() => {
    return selectedOptions.map(op => op.name)
  }, [selectedOptions])

  const onClear = useCallback(() => {
    onChange(undefined)
  }, [])

  const onClose = useCallback(() => {
    search()
    closePopup()
  }, [search, closePopup])

  const onChangeValue = useCallback(
    (v: any) => {
      const newValue = [...(value || [])]
      if (newValue.includes(v.target.defaultValue)) {
        newValue.splice((value || []).indexOf(v.target.defaultValue), 1)
      } else {
        newValue.push(v.target.defaultValue)
      }
      onChange(newValue)
    },
    [value]
  )

  if (selectedOptions.length === 0) return <></>
  return (
    <>
      <SearchItemLabel
        ref={ref}
        active={open}
        onClick={openPopup}
        onClear={onClear}
      >
        {header}: {valueNames.join(',')}
      </SearchItemLabel>
      <Popover
        open={open}
        anchorEl={ref.current || null}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ padding: '8px' }}>
          {options.map(option => (
            <Box key={option.value}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Checkbox
                  sx={{ marginRight: '8px' }}
                  value={option.value}
                  checked={(value || []).some(v => v === option.value)}
                  onChange={onChangeValue}
                />
                <img src={option.iconUrl} />
                <Typography
                  sx={{
                    margin: 'auto 10px',
                    color: colorPalette.monotone[4],
                  }}
                >
                  {option.name}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  )
}
