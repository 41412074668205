import React, { useCallback, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { Portal } from 'react-overlays'
import {
  Box,
  FormControlLabel,
  IconButton,
  InputBase,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material'
import RelativeDateInput from './RelativeDateInput'
import CalendarIcon from '../../../icons/CalendarIcon'
import {
  DateVO,
  dateVoService,
} from '../../../../../domain/value-object/DateVO'
import HelpIcon from '../../../icons/HelpIcon'
import { colorPalette } from '../../../../style/colorPallete'
import { intl } from '../../../../../i18n'
import { SwitchButton } from '../../../../containers/BulkSheetView/components/header/SwitchButton'

const RootDiv = styled('div')({
  width: '100%',
  '@global': {
    '.react-datepicker-popper': {
      zIndex: 10000,
    },
  },
})

interface DateInputProps {
  className?: string
  placeholder?: string
  value: DateVO | undefined
  onChange: (value: DateVO | undefined) => void
  isClearable?: boolean
  disabled?: boolean
  enterHandler?: () => void
  isWbsSearchCondition?: boolean
  onChangeBlank?: (value: boolean) => void
  checkedBlank?: boolean
  onChangedSwitchButton?: () => void
  defaultEditing?: boolean
}

const DateInput: React.FC<DateInputProps> = (props: DateInputProps) => {
  const {
    className,
    placeholder,
    value,
    onChange,
    disabled,
    isClearable,
    enterHandler,
    isWbsSearchCondition,
    onChangeBlank,
    checkedBlank,
    onChangedSwitchButton,
    defaultEditing,
  } = props
  const rootRef = React.useRef(null)
  const datePickerRef = React.useRef<any>(null)
  const [editing, setEditing] = React.useState(!!defaultEditing)

  const openCalendar = useCallback(() => {
    setEditing(true)
  }, [])

  const closeCalendar = useCallback(() => {
    setEditing(false)
  }, [])
  const onChangeRelativeDate = useCallback(
    relativeDate => {
      onChange(relativeDate ? dateVoService.construct(relativeDate) : undefined)
      closeCalendar()
    },
    [value, editing]
  )
  const onChangeAbsoluteDate = useCallback(
    absoluteDate => {
      onChange(absoluteDate ? dateVoService.construct(absoluteDate) : undefined)
      closeCalendar()
    },
    [value, editing]
  )

  useEffect(() => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(editing)
    }
  }, [editing])

  const placeholderText = placeholder || 'YYYY/MM/DD'
  return (
    <RootDiv className={className} ref={rootRef}>
      {editing ? (
        <DatePicker
          ref={datePickerRef}
          autoFocus={true}
          selected={
            (isWbsSearchCondition && checkedBlank && undefined) ||
            (props.value && dateVoService.toDate(props.value))
          }
          dateFormat={'yyyy/MM/dd'}
          onChange={onChangeAbsoluteDate}
          placeholderText={placeholderText}
          customInput={
            <InputBase endAdornment={<CalendarIcon onClick={openCalendar} />} />
          }
          popperPlacement="bottom-start"
          isClearable={isClearable}
          disabled={disabled}
          onChangeRaw={event => {
            const text = event.target.value
            const relativeDate = dateVoService.constructRelativeDate(text)
            if (relativeDate) {
              onChange(relativeDate)
              closeCalendar()
            }
          }}
          popperContainer={props => {
            const { children } = props
            return <Portal container={null}>{children}</Portal>
          }}
          calendarContainer={props => {
            const { className, children } = props
            return (
              <div className={className + ' ag-custom-component-popup'}>
                {!isWbsSearchCondition && (
                  <RelativeDateInput
                    value={value && dateVoService.castToRelativeDate(value)}
                    onChange={onChangeRelativeDate}
                  />
                )}
                <div style={{ position: 'relative' }}>{children}</div>
                {isWbsSearchCondition && (
                  <ChangedSwitchButton
                    onChangeBlank={onChangeBlank}
                    checkedBlank={checkedBlank}
                    onChangedSwitchButton={onChangedSwitchButton}
                  />
                )}
              </div>
            )
          }}
          onKeyPress={event => {
            if (enterHandler && event.key === 'Enter') {
              enterHandler()
            }
          }}
          fixedHeight={true}
          minDate={
            isWbsSearchCondition &&
            checkedBlank &&
            new Date().setFullYear(new Date().getFullYear() + 2)
          }
          maxDate={
            isWbsSearchCondition &&
            checkedBlank &&
            new Date().setFullYear(new Date().getFullYear() - 2)
          }
        />
      ) : (
        <InputBase
          placeholder={placeholderText}
          onFocus={openCalendar}
          value={value ? dateVoService.format(value, 'YYYY/MM/DD') : ''}
          disabled={disabled}
          endAdornment={<CalendarIcon onClick={openCalendar} />}
        />
      )}
    </RootDiv>
  )
}

type ChangedSwitchButtonProps = {
  onChangeBlank?: (value: boolean) => void
  checkedBlank?: boolean
  onChangedSwitchButton?: () => void
}

const ChangedSwitchButton = ({
  onChangeBlank,
  checkedBlank,
  onChangedSwitchButton,
}: ChangedSwitchButtonProps) => {
  return (
    <div style={{ position: 'initial' }}>
      <Box
        sx={{
          flexDirection: 'row',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 20px 10px 20px',
        }}
      >
        <BootstrapTooltip
          title={intl.formatMessage({
            id: 'wbs.search.date.input.blank.tooltip',
          })}
          placement="top"
          sx={{ zIndex: '3000 !important' }}
        >
          <IconButton>
            <HelpIcon />
          </IconButton>
        </BootstrapTooltip>
        <Typography sx={{ color: colorPalette.monotone[5] }}>
          {intl.formatMessage({ id: 'wbs.search.date.input.blank' })}
        </Typography>
        <FormControlLabel
          sx={{ marginRight: 0 }}
          control={<SwitchButton checked={checkedBlank} />}
          label={undefined}
          checked={checkedBlank}
          onChange={(_, checked) => {
            onChangeBlank && onChangeBlank(checked)
            checked && onChangedSwitchButton && onChangedSwitchButton()
          }}
        />
      </Box>
    </div>
  )
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow={true} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: colorPalette.monotone[1],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colorPalette.monotone[1],
    color: colorPalette.monotone[4],
    fontSize: '14px',
    boxShadow: '0px 2px 6px 0px #7B8CAA66',
  },
}))

export default DateInput
