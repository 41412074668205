export const parseFullNumberToHalf = (str: string) => {
  str = str.replace(',', '') // FIXME Consider locale
  str = str.replace('。', '.')
  str = str.replace('．', '.')
  return str.replace(/[０-９]/g, s => {
    return String.fromCharCode(s.charCodeAt(0) - 65248)
  })
}

export const parseToHalfNumberOrEmptyString = (value?: string): string => {
  const parsed = parseFullNumberToHalf(value ?? '')
  if (
    value === undefined ||
    !isHalfOrFullWidthNumber(value) ||
    parsed.startsWith('.')
  ) {
    return ''
  }
  return parsed
}

export const formatNumberWithSign = (value: number, digits: number) => {
  const sign = value > 0 ? '+' : value === 0 ? '±' : ''
  return `${sign}${Number(value).toFixed(digits)}`
}

export const roundNumber = (value: number, decimalPoint: number = 0) => {
  if (decimalPoint === 0) {
    return Math.round(value)
  }
  // Adjust floating number error E.g. Convert 123.449999998 into 123.45
  const base = 10 ** decimalPoint
  return Math.round((value + (value > 0 ? 0.000001 : -0.000001)) * base) / base
}

export const isHalfOrFullWidthNumber = (value: any) => {
  const regex = /^[0-9０-９.．。]*$/
  return value === '' || regex.test(value)
}

export const isInteger = (value: string) => {
  const regex = /^[0-9０-９]*$/
  return !value || regex.test(value)
}

export const toInteger = (v: string | number | undefined) => {
  const value = toNumber(v)
  if (!value) return value
  return Number(value.toFixed(0))
}

export const toNumber = (v: string | number | undefined) => {
  if (v === undefined) return undefined
  if (v === 0) return 0
  let value = v
  if (typeof value === 'string') {
    value = parseFullNumberToHalf(value)
  }
  const num = Number(value)
  return Number.isNaN(num) ? undefined : num
}
