import React, { PropsWithChildren } from 'react'
import SingleSheet, {
  SingleSheetKey,
  SingleSheetOptions,
  SingleSheetRepository,
} from '../containers/SingleSheet'
import BulkSheet, { BulkSheetOptions } from '../containers/BulkSheet'
import BusinessClients from './BusinessClients'
import User from './User'
import Users from './Users'
import Project from './Project'
import Projects from './Projects'
import Tenant from './Tenant'
import IdProviders from './IdProviders'
import Teams from './Teams'
import Divisions from './Divisions'
import TaskActualWork from './TaskActualWork'
import WbsItemSearch from './WbsItemSearch'
import SprintReport from './SprintReport'
import WorkReport from './WorkReport'
import ProjectApi from '../../lib/functions/project'
import TenantApi from '../../lib/functions/tenant'
import UserApi from '../../lib/functions/user'
import Sprint from './Sprint'
import Positions from './Positions'
import Permissions from './Permissions'
import ProjectMember from './ProjectMember/index'
import ProjectPlan from './ProjectPlan'
import ProjectPlanLazyLoad from './ProjectPlanLazyLoad'
import OrganizationWorkingDayCalendar from './OrganizationWorkingDayCalendar'
import { Cockpit } from '../../lib/commons/appFunction'
import WbsItemApi, { WbsItemBasic } from '../../lib/functions/wbsItem'
import ResourcePlan from './ResourcePlan'
import Process from './WbsItem/Process'
import Deliverable from './WbsItem/Deliverable'
import Task from './WbsItem/Task'
import ScheduledOperationTime from './ScheduledOperationTime'
import RefinementList from './RefinementList'
import IssueList from './IssueList'
import ChangeRequestList from './ChangeRequestList'
import MeetingList from './MeetingList'
import TicketLists from './TicketLists'
import PropertyMaintenance from './PropertyMaintenance'
import ProjectInformationReference from './ProjectInformationReference'
import EntityExtensionOptions from './EntityExtension'
import EntityExtensionApi from '../../lib/functions/entityExtension'
import Report, { ReportOptions } from '../containers/ReportContainer'
import ProgressReportChart from './ProgressReport/Chart'
import ProgressChart from './ProgressChart'
import Tickets from './Tickets'
import Ticket from './Ticket'
import { TicketApi } from '../../lib/functions/ticket'
import MyTasks from './MyTasks'
import DeliverableList from './WbsItem/DeliverableList'
import ChartContainer, { ChartOptions } from '../containers/ChartContainer'
import ProgressReportTable from './ProgressReport/Table'
import ResourceplanNew from './ResourcePlanNew'
import { WbsItemType } from '../../domain/entity/WbsItemEntity'
import TicketList from './TicketList'
import WbsItem from './WbsItem'
import { ProjectOverview } from './ProjectOverview'
import { ProjectPlanNew as ProjectPlanNew } from './ProjectPlanNew'
import ResourcePlanCrossProjects from './ResourcePlanCrossProjects'
import { Box, Fade } from '@mui/material'
import ActualWorkingHours from './ActualWorkingHours'
import {
  TenantExternalServices,
  ProjectExternalServices,
} from './ExternalServices'
import ActualWorkingHoursConfirm from './ActualWorkingHoursConfirm'
import { ProjectReport } from './ProjectReport'
import { EnterpriseProjectReport } from './EnterpriseProjectReport'
import LedgerAccounts from './LedgerAccounts'
import ProfitLossItems from './ProfitLossItems'
import ProfitLossSummary from './ProfitLossSummary'
import MyWbsItems from './MyWbsItems'
import { Tag } from './Tag'
import UnitPricePerPosition from './UnitPricePerPosition'
import ProfitLossMembers from './ProfitLossMembers'
import { styled } from '@mui/system'
import { Risks } from './Risks'
import { Risk } from './Risk'
import { RiskApi } from '../../lib/functions/risk'
import UserPositions from './UserPositions'
import UnitPricePerUsers from './UnitPricePerUsers'
import UnitPricePerProjectPartners from './UnitPricePerProjectPartners'
import { colorPalette } from '../style/colorPallete'
import ProfitLossMembersNew from './ProfitLossMembersNew'
import { BurndownChart } from './BurndownChart'
import { ProjectNotificationSettings } from './ProjectNotificationSettings'
import Kanban from './Kanban'
import { DevelopmentEventWbsItemMapping } from './DevelopmentEventWbsItemStatusMapping'
import { ChatChannelMessageTicketCreationMapping } from './ChatChannelMessageTicketCreationMapping'
import TicketsNew from './TicketsNew'
import { isProduction } from '../../utils/urls'
import { Refinement } from './Refinement'
import { RefinementApi } from '../../lib/functions/refinement'
import { WbsItemV2 } from './WbsItemV2'
import { WbsItemAdditionalProperties } from './WbsAdditionalProperties'

export enum APPLICATION_FUNCTION_EXTERNAL_ID {
  TENANT_EDIT = 'tenant.edit',
  ID_PROVIDERS = 'idProvider.edit',
  EXTERNAL_SERVICES = 'externalServices.edit',
  DIVISION_EDIT = 'division.edit',
  POSITION_EDIT = 'position.edit',
  PERMISSION_EDIT = 'permission.edit',
  USER_REGISTRATION = 'user.registration',
  USERS_SEARCH = 'users.search',
  BUSINESSCLIENTS_EDIT = 'businessClients.edit',
  PROJECT_REGISTRATION = 'project.registration',
  PROJECTS_SEARCH = 'projects.search',
  PROJECT_INFORMATION_REFERENCE = 'projectInformation.reference',
  PROJECT_TEAM_EDIT = 'project.team.edit',
  PROJECT_REPORT = 'project.report',
  PROJECT_COCKPIT = 'enterpriseProjectReport.report',
  PROJECT_OVERVIEW = 'project.overview.edit',
  PROJECTPLAN_EDIT = 'projectPlan.edit',
  PROJECTPLAN_NEW_EDIT = 'projectPlan.new.edit',
  PROJECTPLAN_LAZYLOAD_EDIT = 'projectPlan.lazyLoad.edit',
  MYTASKS_EDIT = 'myTasks.edit',
  PROJECT_SPRINT_EDIT = 'project.sprint.edit',
  PROJECT_MEMBER_EDIT = 'project.member.edit',
  RESOURCE_PLAN_EDIT = 'project.resourcePlan.edit',
  RESOURCE_PLAN_NEW_EDIT = 'project.resourcePlanNew.edit',
  SCHEDULED_OPERATION_TIME = 'project.scheduledOperationTime.edit',
  TASKACTUALWORK_EDIT = 'taskActualWork.edit',
  PROJECT_PROGRESS_REPORT_TABLE = 'project.progressReport.table.reference',
  PROJECT_PROGRESS_REPORT_CHART = 'project.progressReport.chart',
  PROJECT_PROGRESS_CHART = 'project.progressChart',
  PROJECT_SPRINTREPORT = 'project.sprintReport.reference',
  PROJECT_WORKREPORT = 'project.workReport.reference',
  BURNDOWN_CHART = 'project.report.burndownChart',
  ORGANIZATION_WORKING_DAY_CALENDAR_EDIT = 'organizationWorkingDayCalendar.edit',
  WBS_ITEM_V2 = 'wbsItem.v2',
  WBS_ITEM = 'wbsItem',
  PROCESS = 'process',
  DELIVERABLE_LIST = 'deliverableList',
  DELIVERABLE = 'deliverable',
  TASK = 'task',
  TICKET = 'ticket',
  RISK = 'risk',
  WBS_ITEM_SEARCH = 'wbsItem.search.edit',
  PROJECT_TAG_EDIT = 'project.tag.edit',
  TICKET_LISTS = 'ticketLists.edit',
  TICKETS = 'ticket.list.edit',
  TICKETS_NEW = 'ticket.list.new.edit',
  REFINEMENT_LIST = 'refinement_new.list.edit',
  ISSUE_LIST = 'issue.list.edit',
  RISK_LIST = 'risk.list.edit',
  RISKS = 'risks.edit',
  CHANGE_REQUEST_LIST = 'changeRequest.list.edit',
  MEETING_LIST = 'meeting.list.edit',
  PROPERTY_MAINTENANCE = 'propertyMaintenance.edit',
  ENTITY_EXTENSION_EDIT = 'entityExtension.edit',
  RESOURCE_PLAN_CROSS_PROJECTS_EDIT = 'resourcePlanCrossProjects.edit',
  ACTUAL_WORKING_HOURS_EDIT = 'actualWorkingHours.edit',
  ACTUAL_WORKING_HOURS_CONFIRM = 'actualWorkingHours.confirm',
  LEDGER_ACCOUNTS = 'ledgerAccounts.edit',
  PROFIT_LOSS_ITEMS_EDIT = 'profitLossItems.edit',
  PROFIT_LOSS_MEMBERS_EDIT = 'profitLossMembers.edit',
  PROFIT_LOSS_MEMBERS_NEW_EDIT = 'profitLossMembers.new.edit',
  PROFIT_LOSS_SUMMARY = 'profitLossSummary.report',
  MY_WBS_ITEMS = 'myWbsItems.edit',
  UNIT_PRICE_PER_POSITION = 'unitPricePerPosition.edit',
  USER_POSITIONS = 'userPositions.edit',
  UNIT_PRICE_PER_USERS = 'unitPricePerUsers.edit',
  UNIT_PRICE_PER_PROJECT_PARTNERS = 'unitPricePerProjectPertners.edit',
  PROJECT_NOTIFICATION_SETTINGS = 'project.notificationSettings.edit',
  KANBAN_STATUS = 'project.kanban.status.edit',
  PROJECT_DEVELOPMENT_EVENT_WBS_ITEM_STATUS_MAPPINGS = 'project.developmentEventWbsItemStatusMapping.edit',
  PROJECT_EXTERNAL_SERVICES = 'project.externalServices.edit',
  PROJECT_CHAT_CHANNEL_MESSAGE_TICKET_CREATION_MAPPINGS = 'project.chatChannelMessageTicketCreationMapping.edit',
  REFINEMENT = 'refinement',
  WBS_ITEM_ADDITIONAL_PROPERTIES = 'project.wbsItemAdditionalProperties.edit',
}

export type PageProps = {
  key: string
  externalId: string
  uuid: string
} & ComponentProps

export interface ComponentProps {
  class: React.ComponentType<any>
  cockpit: Cockpit
  defaultPath: string
  optionalPaths?: string[]
  repository?: SingleSheetRepository
  options?:
    | BulkSheetOptions<any, any, any, any>
    | SingleSheetOptions<any>
    | ReportOptions<any, any, any, any, any, any>
    | ChartOptions<any, any>
  breadcrumbs?: (key: SingleSheetKey) => any
  hideSideMenu?: boolean
  // Overwrite properties(id, options, and repository) to use different ui metas for the same URL
  useUiMetaOf?: (basic: any) => APPLICATION_FUNCTION_EXTERNAL_ID
  // Use alternative function uuid. Set when the same ui state (comment) is shared by multiple components.
  useFunctionUuidOf?: APPLICATION_FUNCTION_EXTERNAL_ID
}

export interface Index {
  [key: string]: ComponentProps
}

const functions: Index = {
  [APPLICATION_FUNCTION_EXTERNAL_ID.TENANT_EDIT]: {
    class: SingleSheet,
    options: new Tenant(),
    repository: TenantApi,
    cockpit: Cockpit.Global,
    defaultPath: '/tenantEdit',
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.ID_PROVIDERS]: {
    class: IdProviders,
    cockpit: Cockpit.Global,
    defaultPath: '/idProviders',
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.EXTERNAL_SERVICES]: {
    class: TenantExternalServices,
    cockpit: Cockpit.Global,
    defaultPath: '/externalServices',
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.DIVISION_EDIT]: {
    class: Divisions,
    cockpit: Cockpit.Global,
    defaultPath: '/divisions',
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.POSITION_EDIT]: {
    class: Positions,
    cockpit: Cockpit.Global,
    defaultPath: '/positions',
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PERMISSION_EDIT]: {
    class: Permissions,
    cockpit: Cockpit.Global,
    defaultPath: '/permissions',
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.BUSINESSCLIENTS_EDIT]: {
    class: BusinessClients,
    cockpit: Cockpit.Global,
    defaultPath: '/businessClients',
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.USER_REGISTRATION]: {
    class: SingleSheet,
    options: new User(),
    repository: UserApi,
    cockpit: Cockpit.Global,
    defaultPath: '/userEdit',
    optionalPaths: ['/userEdit/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.USERS_SEARCH]: {
    class: Users,
    cockpit: Cockpit.Global,
    defaultPath: '/users',
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_REGISTRATION]: {
    class: SingleSheet,
    options: new Project(),
    repository: ProjectApi,
    cockpit: Cockpit.Global,
    defaultPath: '/projectEdit',
    optionalPaths: ['/projectEdit/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECTS_SEARCH]: {
    class: Projects,
    cockpit: Cockpit.Global,
    defaultPath: '/projects',
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_INFORMATION_REFERENCE]: {
    class: SingleSheet,
    options: new ProjectInformationReference(),
    repository: ProjectApi,
    cockpit: Cockpit.Project,
    defaultPath: '/projectReference',
    optionalPaths: ['/projectReference/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROPERTY_MAINTENANCE]: {
    class: BulkSheet,
    options: new PropertyMaintenance(),
    cockpit: Cockpit.Project,
    defaultPath: '/propertyMaintenance',
    optionalPaths: ['/propertyMaintenance/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.ENTITY_EXTENSION_EDIT]: {
    class: SingleSheet,
    options: new EntityExtensionOptions(),
    repository: EntityExtensionApi,
    cockpit: Cockpit.Project,
    defaultPath: '/entityExtension',
    optionalPaths: ['/entityExtension/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_TEAM_EDIT]: {
    class: Teams,
    cockpit: Cockpit.Project,
    defaultPath: '/teams',
    optionalPaths: ['/teams/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_REPORT]: {
    class: ProjectReport,
    cockpit: Cockpit.Project,
    defaultPath: '/projectReport',
    optionalPaths: ['/projectReport/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_COCKPIT]: {
    class: EnterpriseProjectReport,
    cockpit: Cockpit.Project,
    defaultPath: '/enterpriseProjectReport',
    optionalPaths: ['/enterpriseProjectReport/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_OVERVIEW]: {
    class: ProjectOverview,
    cockpit: Cockpit.Project,
    defaultPath: '/projectOverview',
    optionalPaths: ['/projectOverview/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECTPLAN_EDIT]: {
    class: ProjectPlan,
    cockpit: Cockpit.Project,
    defaultPath: '/projectPlanOld',
    optionalPaths: ['/projectPlanOld/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECTPLAN_LAZYLOAD_EDIT]: {
    class: ProjectPlanLazyLoad,
    cockpit: Cockpit.Project,
    defaultPath: '/projectPlanLLOld',
    optionalPaths: ['/projectPlanLLOld/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECTPLAN_NEW_EDIT]: {
    class: ProjectPlanNew,
    cockpit: Cockpit.Project,
    defaultPath: '/projectPlan',
    optionalPaths: ['/projectPlan/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM_SEARCH]: {
    class: WbsItemSearch,
    cockpit: Cockpit.Project,
    defaultPath: '/wbsItemSearch',
    optionalPaths: ['/wbsItemSearch/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.MYTASKS_EDIT]: {
    class: MyTasks,
    cockpit: Cockpit.Global,
    defaultPath: '/myTasksOld',
    optionalPaths: ['/myTasksOld/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_SPRINT_EDIT]: {
    class: Sprint,
    cockpit: Cockpit.Project,
    defaultPath: '/sprints',
    optionalPaths: ['/sprints/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_MEMBER_EDIT]: {
    class: ProjectMember,
    cockpit: Cockpit.Project,
    defaultPath: '/members',
    optionalPaths: ['/members/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.RESOURCE_PLAN_EDIT]: {
    class: ResourcePlan,
    cockpit: Cockpit.Project,
    defaultPath: '/resourcePlan',
    optionalPaths: ['/resourcePlan/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.RESOURCE_PLAN_NEW_EDIT]: {
    class: ResourceplanNew,
    cockpit: Cockpit.Project,
    defaultPath: '/resourcePlanNew',
    optionalPaths: ['/resourcePlanNew/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.SCHEDULED_OPERATION_TIME]: {
    class: ScheduledOperationTime,
    cockpit: Cockpit.Project,
    defaultPath: '/workSchedule',
    optionalPaths: ['/workSchedule/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM_V2]: {
    class: WbsItemV2,
    cockpit: Cockpit.Project,
    defaultPath: '/wbsItemV2',
    optionalPaths: ['/wbsItemV2/:code'],
    hideSideMenu: true,
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM]: {
    class: SingleSheet,
    repository: WbsItemApi,
    cockpit: Cockpit.Project,
    defaultPath: '/wbsItem',
    options: new WbsItem(),
    optionalPaths: ['/wbsItem/:code'],
    hideSideMenu: true,
    useUiMetaOf: (basic: WbsItemBasic) => {
      if (basic.type === WbsItemType.PROCESS) {
        return APPLICATION_FUNCTION_EXTERNAL_ID.PROCESS
      } else if (basic.type === WbsItemType.DELIVERABLE_LIST) {
        return APPLICATION_FUNCTION_EXTERNAL_ID.DELIVERABLE_LIST
      } else if (basic.type === WbsItemType.DELIVERABLE) {
        return APPLICATION_FUNCTION_EXTERNAL_ID.DELIVERABLE
      } else {
        if (basic.ticketType) {
          return APPLICATION_FUNCTION_EXTERNAL_ID.TICKET
        } else {
          return APPLICATION_FUNCTION_EXTERNAL_ID.TASK
        }
      }
    },
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.TASK]: {
    class: SingleSheet,
    repository: WbsItemApi,
    cockpit: Cockpit.Project,
    defaultPath: '/task',
    options: new Task(),
    useFunctionUuidOf: APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM,
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.DELIVERABLE]: {
    class: SingleSheet,
    repository: WbsItemApi,
    cockpit: Cockpit.Project,
    defaultPath: '/deliverable',
    options: new Deliverable(),
    useFunctionUuidOf: APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM,
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.DELIVERABLE_LIST]: {
    class: SingleSheet,
    repository: WbsItemApi,
    cockpit: Cockpit.Project,
    defaultPath: '/deliverableList',
    options: new DeliverableList(),
    useFunctionUuidOf: APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM,
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROCESS]: {
    class: SingleSheet,
    repository: WbsItemApi,
    cockpit: Cockpit.Project,
    defaultPath: '/process',
    options: new Process(),
    useFunctionUuidOf: APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM,
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.TASKACTUALWORK_EDIT]: {
    class: TaskActualWork,
    cockpit: Cockpit.Project,
    defaultPath: '/actualWork',
    optionalPaths: ['/actualWork/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_PROGRESS_REPORT_TABLE]: {
    class: ProgressReportTable,
    cockpit: Cockpit.Project,
    defaultPath: '/progressReport',
    optionalPaths: ['/progressReport/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_PROGRESS_REPORT_CHART]: {
    class: ChartContainer,
    options: new ProgressReportChart(),
    cockpit: Cockpit.Project,
    defaultPath: '/progressReportChart',
    optionalPaths: ['/progressReportChart/:code'],
    hideSideMenu: true,
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_PROGRESS_CHART]: {
    class: Report,
    options: new ProgressChart(),
    cockpit: Cockpit.Project,
    defaultPath: '/progressChart',
    optionalPaths: ['/progressChart/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_SPRINTREPORT]: {
    class: BulkSheet,
    options: new SprintReport(),
    cockpit: Cockpit.Project,
    defaultPath: '/sprintReport',
    optionalPaths: ['/sprintReport/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_WORKREPORT]: {
    class: WorkReport,
    cockpit: Cockpit.Project,
    defaultPath: '/workReport',
    optionalPaths: ['/workReport/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.BURNDOWN_CHART]: {
    class: BurndownChart,
    cockpit: Cockpit.Project,
    defaultPath: '/burndownChart',
    optionalPaths: ['/burndownChart/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.ORGANIZATION_WORKING_DAY_CALENDAR_EDIT]: {
    class: OrganizationWorkingDayCalendar,
    cockpit: Cockpit.Global,
    defaultPath: '/workingCalender',
  },
  /* Temporary comment out. ref: https://flagxs.sevend.net/wbsItem/LBNQFNEE */
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_TAG_EDIT]: {
    class: Tag,
    cockpit: Cockpit.Project,
    defaultPath: '/tags',
    optionalPaths: ['/tags/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.TICKET]: {
    class: SingleSheet,
    options: new Ticket(),
    repository: new TicketApi(),
    cockpit: Cockpit.Project,
    defaultPath: '/ticket',
    optionalPaths: ['/ticket/:code'],
    hideSideMenu: true,
    useFunctionUuidOf: APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM,
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.RISK]: {
    class: SingleSheet,
    options: new Risk(),
    repository: new RiskApi(),
    cockpit: Cockpit.Project,
    defaultPath: '/risk',
    optionalPaths: ['/risk/:code'],
    hideSideMenu: true,
    useFunctionUuidOf: APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM,
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.REFINEMENT]: {
    class: SingleSheet,
    options: new Refinement(),
    repository: new RefinementApi(),
    cockpit: Cockpit.Project,
    defaultPath: '/refinement',
    optionalPaths: ['/refinement/:code'],
    hideSideMenu: true,
    useFunctionUuidOf: APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM,
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.TICKETS]: (() => {
    return {
      class: Tickets,
      cockpit: Cockpit.Project,
      defaultPath: '/tickets',
      optionalPaths: ['/tickets/:code'],
    }
  })(),
  [APPLICATION_FUNCTION_EXTERNAL_ID.REFINEMENT_LIST]: isProduction
    ? {
        class: TicketList,
        options: new RefinementList(),
        cockpit: Cockpit.Project,
        defaultPath: '/refinements',
        optionalPaths: ['/refinements/:code'],
        useUiMetaOf: _ => APPLICATION_FUNCTION_EXTERNAL_ID.TICKETS,
      }
    : {
        class: TicketsNew,
        cockpit: Cockpit.Project,
        defaultPath: '/ticketsNew',
        optionalPaths: ['/ticketsNew/:code'],
        useUiMetaOf: _ => APPLICATION_FUNCTION_EXTERNAL_ID.TICKETS,
      },
  [APPLICATION_FUNCTION_EXTERNAL_ID.ISSUE_LIST]: isProduction
    ? {
        class: TicketList,
        options: new IssueList(),
        cockpit: Cockpit.Project,
        defaultPath: '/issues',
        optionalPaths: ['/issues/:code'],
        useUiMetaOf: _ => APPLICATION_FUNCTION_EXTERNAL_ID.TICKETS,
      }
    : {
        class: TicketsNew,
        cockpit: Cockpit.Project,
        defaultPath: '/ticketsNew',
        optionalPaths: ['/ticketsNew/:code'],
        useUiMetaOf: _ => APPLICATION_FUNCTION_EXTERNAL_ID.TICKETS,
      },
  [APPLICATION_FUNCTION_EXTERNAL_ID.RISK_LIST]: isProduction
    ? {
        class: Risks,
        cockpit: Cockpit.Project,
        defaultPath: '/risks',
        optionalPaths: ['/risks/:code'],
      }
    : {
        class: TicketsNew,
        cockpit: Cockpit.Project,
        defaultPath: '/ticketsNew',
        optionalPaths: ['/ticketsNew/:code'],
        useUiMetaOf: _ => APPLICATION_FUNCTION_EXTERNAL_ID.TICKETS,
      },
  [APPLICATION_FUNCTION_EXTERNAL_ID.CHANGE_REQUEST_LIST]: isProduction
    ? {
        class: TicketList,
        options: new ChangeRequestList(),
        cockpit: Cockpit.Project,
        defaultPath: '/changeRequests',
        optionalPaths: ['/changeRequests/:code'],
        useUiMetaOf: _ => APPLICATION_FUNCTION_EXTERNAL_ID.TICKETS,
      }
    : {
        class: TicketsNew,
        cockpit: Cockpit.Project,
        defaultPath: '/ticketsNew',
        optionalPaths: ['/ticketsNew/:code'],
        useUiMetaOf: _ => APPLICATION_FUNCTION_EXTERNAL_ID.TICKETS,
      },
  [APPLICATION_FUNCTION_EXTERNAL_ID.MEETING_LIST]: isProduction
    ? {
        class: TicketList,
        options: new MeetingList(),
        cockpit: Cockpit.Project,
        defaultPath: '/meetings',
        optionalPaths: ['/meetings/:code'],
        useUiMetaOf: _ => APPLICATION_FUNCTION_EXTERNAL_ID.TICKETS,
      }
    : {
        class: TicketsNew,
        cockpit: Cockpit.Project,
        defaultPath: '/ticketsNew',
        optionalPaths: ['/ticketsNew/:code'],
        useUiMetaOf: _ => APPLICATION_FUNCTION_EXTERNAL_ID.TICKETS,
      },
  [APPLICATION_FUNCTION_EXTERNAL_ID.TICKETS_NEW]: (() => {
    return {
      class: TicketsNew,
      cockpit: Cockpit.Project,
      defaultPath: '/ticketsNew',
      optionalPaths: ['/ticketsNew/:code'],
    }
  })(),
  [APPLICATION_FUNCTION_EXTERNAL_ID.TICKET_LISTS]: {
    class: TicketLists,
    cockpit: Cockpit.Project,
    defaultPath: '/ticketLists',
    optionalPaths: ['/ticketLists/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.RESOURCE_PLAN_CROSS_PROJECTS_EDIT]: {
    class: ResourcePlanCrossProjects,
    cockpit: Cockpit.Global,
    defaultPath: '/resourcePlanCP',
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.ACTUAL_WORKING_HOURS_EDIT]: {
    class: ActualWorkingHours,
    cockpit: Cockpit.Global,
    defaultPath: '/actualWorkingHours',
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.ACTUAL_WORKING_HOURS_CONFIRM]: {
    class: ActualWorkingHoursConfirm,
    cockpit: Cockpit.Project,
    defaultPath: '/actualWorkingHoursConfirm',
    optionalPaths: ['/actualWorkingHoursConfirm/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.LEDGER_ACCOUNTS]: {
    class: LedgerAccounts,
    cockpit: Cockpit.Global,
    defaultPath: '/ledgerAccounts',
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROFIT_LOSS_ITEMS_EDIT]: {
    class: ProfitLossItems,
    cockpit: Cockpit.Project,
    defaultPath: '/profitLossItems',
    optionalPaths: ['/profitLossItems/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROFIT_LOSS_MEMBERS_EDIT]: {
    class: ProfitLossMembers,
    cockpit: Cockpit.Project,
    defaultPath: '/profitLossMembers',
    optionalPaths: ['/profitLossMembers/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROFIT_LOSS_MEMBERS_NEW_EDIT]: {
    class: ProfitLossMembersNew,
    cockpit: Cockpit.Project,
    defaultPath: '/profitLossMembersNew',
    optionalPaths: ['/profitLossMembersNew/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROFIT_LOSS_SUMMARY]: {
    class: ProfitLossSummary,
    cockpit: Cockpit.Project,
    defaultPath: '/profitLossSummary',
    optionalPaths: ['/profitLossSummary/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.MY_WBS_ITEMS]: {
    class: MyWbsItems,
    cockpit: Cockpit.Global,
    defaultPath: '/myTasks',
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.UNIT_PRICE_PER_POSITION]: {
    class: UnitPricePerPosition,
    cockpit: Cockpit.Global,
    defaultPath: '/unitPrices/positions',
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.USER_POSITIONS]: {
    class: UserPositions,
    cockpit: Cockpit.Global,
    defaultPath: '/userPositions',
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.UNIT_PRICE_PER_USERS]: {
    class: UnitPricePerUsers,
    cockpit: Cockpit.Global,
    defaultPath: '/unitPrices/users',
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.UNIT_PRICE_PER_PROJECT_PARTNERS]: {
    class: UnitPricePerProjectPartners,
    cockpit: Cockpit.Project,
    defaultPath: '/unitPrices/partners',
    optionalPaths: ['/unitPrices/partners/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_NOTIFICATION_SETTINGS]: {
    class: ProjectNotificationSettings,
    cockpit: Cockpit.Project,
    defaultPath: '/project/notification',
    optionalPaths: ['/project/notification/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.KANBAN_STATUS]: {
    class: Kanban,
    cockpit: Cockpit.Project,
    defaultPath: '/project/kanban',
    optionalPaths: ['/project/kanban/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_DEVELOPMENT_EVENT_WBS_ITEM_STATUS_MAPPINGS]:
    {
      class: DevelopmentEventWbsItemMapping,
      cockpit: Cockpit.Project,
      defaultPath: '/project/developmentEventWbsItemStatusMappings',
      optionalPaths: ['/project/developmentEventWbsItemStatusMappings/:code'],
    },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_EXTERNAL_SERVICES]: {
    class: ProjectExternalServices,
    cockpit: Cockpit.Project,
    defaultPath: '/project/externalServices',
    optionalPaths: ['/project/externalServices/:code'],
  },
  [APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_CHAT_CHANNEL_MESSAGE_TICKET_CREATION_MAPPINGS]:
    {
      class: ChatChannelMessageTicketCreationMapping,
      cockpit: Cockpit.Project,
      defaultPath: '/project/chatChannelMessageTicketCreationMappings',
      optionalPaths: [
        '/project/chatChannelMessageTicketCreationMappings/:code',
      ],
    },
  [APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM_ADDITIONAL_PROPERTIES]: {
    class: WbsItemAdditionalProperties,
    cockpit: Cockpit.Project,
    defaultPath: '/project/wbsItemAdditionalProperties',
    optionalPaths: ['/project/wbsItemAdditionalProperties/:code'],
  },
}

export default functions

const functionMap = (() => {
  const defaultPathMap = Object.fromEntries(
    Object.entries(functions).map(([key, value]) => [
      value.defaultPath.toLowerCase(),
      { ...value, externalId: key },
    ])
  )
  const optionalPathMap = Object.fromEntries(
    Object.entries(functions).flatMap(([key, value]) =>
      value.optionalPaths
        ? value.optionalPaths.map(path => {
            const parts = path.toLowerCase().split('/')
            const pathWithoutParam = parts
              .filter(p => !p.startsWith(':'))
              .join('')
            return [pathWithoutParam, { ...value, externalId: key }]
          })
        : []
    )
  )
  return { ...defaultPathMap, ...optionalPathMap }
})()

export const getFunctionByPath = (path: string) => {
  let result = functionMap[path.toLowerCase()]
  if (result) {
    return result
  }
  // When the path is "/a/b/<uuid>/<uuid>", the function is for the path "/a/b".
  const parts = path.toLowerCase().split('/')
  for (let i = 0; i < parts.length; i++) {
    result = functionMap[parts.slice(0, -i - 1).join('/')]
    if (result) {
      return result
    }
  }
  return undefined
}

export const getPathByExternalId = (externalId: string) => {
  const appFunction = Object.values(functionMap).find(
    f => f.externalId === externalId
  )
  if (!appFunction) {
    return ''
  }
  return appFunction.defaultPath
}

const PageMain = styled('main')({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
})

export const PageArea = ({ children }: PropsWithChildren<{}>) => (
  <Fade in={true}>
    <Box
      sx={{
        width: '100%',
        height: 'calc(100% - 56px)',
        display: 'flex',
        flexDirection: 'column',
        background: colorPalette.monotone[1],
      }}
    >
      {children}
    </Box>
  </Fade>
)
