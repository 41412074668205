import {
  ColDef,
  EditableCallbackParams,
  ValueGetterParams,
  CellClassParams,
} from 'ag-grid-community'
import { WbsItemAdditionalPropertyType } from '../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { selectOptions } from '../../../../page-properties/bulksheet-properties/wbsItemAdditionalProperty'
import {
  isAdditionalPropertyRow,
  isGroupHeaderRow,
  AdditionalPropertyRow,
} from '../../rowModel/wbsItemAdditionalPropertyRow'
import { DISABLE_COLOR } from './style'

export const selectOptionsColumnDef: ColDef = {
  ...selectOptions,
  editable: (params: EditableCallbackParams) =>
    isAdditionalPropertyRow(params.node.data) &&
    params.node.data.propertyType === WbsItemAdditionalPropertyType.SELECT,
  valueGetter: (params: ValueGetterParams) => {
    if (!params.node || isGroupHeaderRow(params.node.data)) {
      return ''
    }
    const additionalPropertyRow = params.node.data as AdditionalPropertyRow
    return additionalPropertyRow.selectOptions
      ? additionalPropertyRow.selectOptions.map(v => v.selectOption).join(',')
      : ''
  },
  cellStyle: (params: CellClassParams) =>
    isGroupHeaderRow(params.node.data) ||
    params.node.data.propertyType !== WbsItemAdditionalPropertyType.SELECT
      ? {
          backgroundColor: DISABLE_COLOR,
        }
      : undefined,
}
