import { useCallback, useEffect, useState } from 'react'
import { ResizableArea } from '../../../../components/draggers/ResizableArea'
import {
  MainArea,
  PanelRoot,
  SideBarArea,
  SingleSheetRoot,
} from '../../../../containers/SingleSheetV2'
import {
  Tab,
  Tabs,
  TabMainArea,
  TabPanelArea,
} from '../../../../containers/SingleSheetV2/Tab'
import { TextCell } from '../../../../containers/SingleSheetV2/table/cells/TextCell'
import { PageArea } from '../../..'
import { WbsItemType } from '../../../../../domain/entity/WbsItemEntity'
import { TableRow } from '../../../../containers/SingleSheetV2/table/cells'
import { TableHeader } from '../../../../containers/SingleSheetV2/table/Header'
import { SelectCell } from '../../../../containers/SingleSheetV2/table/cells/SelectCell'
import { AutocompleteCell } from '../../../../containers/SingleSheetV2/table/cells/AutocompleteCell'
import { WbsItemProps } from '../..'
import { MultiAutocompleteCell } from '../../../../containers/SingleSheetV2/table/cells/MultiAutocompleteCell'
import { WorkloadCell } from '../../../../containers/SingleSheetV2/table/cells/WorkloadCell'
import { MultilineTextEditorTab } from '../../../../containers/SingleSheetV2/multiline-text'
import { ProjectPlanTab } from '../../../../containers/SingleSheetV2/project-plan'
import { AttachmentTab } from '../../../../containers/SingleSheetV2/attachment'
import { DiscussionTab } from '../../../../containers/SingleSheetV2/discussion'
import { WbsItemHeader } from '../header'
import { WbsItemSubHeader } from '../subheader'
import { DateTermCell } from '../../../../containers/SingleSheetV2/table/cells/DateTermCell'
import { DateTimeCell } from '../../../../containers/SingleSheetV2/table/cells/DateTimeCell'
import { DevelopmentTabPanel } from '../tab-panel/DevelopmentTabPanel'
import { ChangeLogTabPanel } from '../tab-panel/ChangeLogTabPanel'
import { WbsStatusCell } from '../../../../containers/SingleSheetV2/table/cells/WbsStatusCell'
import { useWbsItemSelectOptions } from '../../hooks/wbsItemSelectOptions'
import { useWbsItemSingleSheetData } from '../../hooks/wbsItemData'
import Loading from '../../../../components/process-state-notifications/Loading'
import { styled } from '@mui/material'
import { WbsTagsCell } from '../../../../containers/SingleSheetV2/table/cells/WbsTagsCell'
import { useAttachmentHandlers } from '../../hooks/attachments'
import { useWbsItemPropertySearch } from '../../hooks/wbsItemPropertySearch'
import { useWbsItemChangeLogParser } from '../../hooks/wbsItemChangeLogParser'
import { useWbsItemModelModifier } from '../../hooks/wbsItemModelModifier'

export const Deliverable = ({
  projectUuid,
  uuid,
  functionUuid,
}: WbsItemProps) => {
  const [leftTab, setLeftTab] = useState<number>(0)
  const onChangeLeftTab = useCallback(
    (_, newValue: number) => setLeftTab(newValue),
    []
  )
  const [rightTab, setRightTab] = useState<number>(0)
  const onChangeRightTab = useCallback(
    (_, newValue: number) => setRightTab(newValue),
    []
  )
  const { initialized, isLoading, formModel, onChange, reload, submit } =
    useWbsItemSingleSheetData(uuid)
  const {
    subStatuses,
    difficulties,
    priorities,
    getSubStatusByStatus,
    getStatusBySubStatus,
  } = useWbsItemSelectOptions(projectUuid, WbsItemType.DELIVERABLE)
  const { searchTeam, searchMember } = useWbsItemPropertySearch(
    projectUuid,
    formModel
  )

  const getChangeLogParser = useWbsItemChangeLogParser({
    subStatuses,
    difficulties,
    priorities,
  })

  const {
    onChangeDisplayName,
    onChangeStatus,
    onChangeSubStatus,
    onChangeTags,
    onChangeTeam,
    onChangeAccountable,
    onChangeResponsible,
    onChangeAssignee,
    onChangeWatchers,
    onChangeDifficulty,
    onChangePriority,
    onChangeSprint,
    onChangeScheduledDate,
    onChangeActualDate,
    onChangeDescription,
  } = useWbsItemModelModifier(onChange, {
    getStatusBySubStatus,
    getSubStatusByStatus,
  })

  const { addAttachments, deleteAttachments } = useAttachmentHandlers(uuid)

  return (
    <PageArea>
      <Loading isLoading={isLoading} />
      {initialized && (
        <>
          <WbsItemHeader
            title={formModel.displayName || ''}
            onChangeTitle={onChangeDisplayName}
          />
          <WbsItemSubHeader
            projectUuid={projectUuid}
            submit={submit}
            reload={reload}
            model={formModel}
            onChangeStatus={onChangeStatus}
            onChangeSubStatus={onChangeSubStatus}
            onChangePriority={onChangePriority}
            onChangeTeam={onChangeTeam}
            onChangeAccountable={onChangeAccountable}
            onChangeResponsible={onChangeResponsible}
            onChangeAssignee={onChangeAssignee}
            onChangeScheduledDate={onChangeScheduledDate}
            onChangeActualDate={onChangeActualDate}
            subStatuses={subStatuses}
            priorities={priorities}
          />
          <SingleSheetWrapper>
            <SingleSheetRoot>
              <MainArea width="50%">
                <TabPanelArea>
                  <Tabs value={leftTab} onChange={onChangeLeftTab}>
                    <Tab label="基本情報" />
                    <Tab label="説明" />
                    <Tab label="サブタスク" />
                    <Tab label="添付" />
                  </Tabs>
                  <TabMainArea>
                    <PanelRoot>
                      {leftTab === 0 && (
                        <>
                          <TableHeader title="基本情報" />
                          <TableRow>
                            <TextCell
                              label="コード"
                              cellWidth="half"
                              value={formModel.code}
                              required={true}
                              editable={false}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="名称"
                              cellWidth="full"
                              value={formModel.displayName}
                              onChange={onChangeDisplayName}
                              required={true}
                            />
                          </TableRow>
                          <TableRow>
                            <WbsStatusCell
                              label="ステータス"
                              cellWidth="half"
                              value={formModel.status}
                              required={true}
                            />
                          </TableRow>
                          <TableRow>
                            <SelectCell
                              label="サブステータス"
                              cellWidth="half"
                              value={formModel.substatus}
                              options={subStatuses}
                              onChange={onChangeSubStatus}
                            />
                          </TableRow>
                          <TableRow>
                            <WbsTagsCell
                              projectUuid={projectUuid}
                              label="タグ"
                              cellWidth="half"
                              value={formModel.tags || []}
                              onChange={onChangeTags}
                            />
                          </TableRow>
                          <TableHeader title="担当" />
                          <TableRow>
                            <AutocompleteCell
                              label="チーム"
                              cellWidth="half"
                              value={formModel.team}
                              onChange={onChangeTeam}
                              search={searchTeam}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="責任者"
                              cellWidth="half"
                              value={formModel.accountable}
                              onChange={onChangeAccountable}
                              search={searchMember}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="担当者"
                              cellWidth="half"
                              value={formModel.responsible}
                              onChange={onChangeResponsible}
                              search={searchMember}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="アサイン先"
                              cellWidth="half"
                              value={formModel.assignee}
                              onChange={onChangeAssignee}
                              search={searchMember}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <MultiAutocompleteCell
                              label="ウォッチャー"
                              cellWidth="half"
                              value={formModel.watchers || []}
                              onChange={onChangeWatchers}
                              search={searchMember}
                            />
                          </TableRow>
                          <TableHeader title="期間・見積もり" />
                          <TableRow>
                            <SelectCell
                              label="難易度"
                              cellWidth="half"
                              value={formModel.difficulty}
                              onChange={onChangeDifficulty}
                              options={difficulties}
                            />
                            <SelectCell
                              label="優先度"
                              cellWidth="half"
                              value={formModel.priority}
                              onChange={onChangePriority}
                              options={priorities}
                            />
                          </TableRow>
                          <TableRow>
                            <WorkloadCell
                              label="見積り工数"
                              cellWidth="half"
                              value={formModel.estimatedHour || null}
                            />
                          </TableRow>
                          <TableRow>
                            <DateTermCell
                              label="予定期間"
                              cellWidth="half"
                              value={formModel.scheduledDate}
                              onChange={onChangeScheduledDate}
                            />
                          </TableRow>
                          <TableRow>
                            <DateTermCell
                              label="実績期間"
                              cellWidth="half"
                              value={formModel.actualDate}
                              onChange={onChangeActualDate}
                            />
                          </TableRow>
                          <TableHeader title="更新履歴" />
                          <TableRow>
                            <AutocompleteCell
                              label="登録者"
                              cellWidth="half"
                              value={formModel.createdBy}
                              editable={false}
                              search={async () => []}
                              displayIcon={true}
                            />
                            <DateTimeCell
                              label="登録日時"
                              cellWidth="half"
                              value={formModel.createdAt}
                              editable={false}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="更新者"
                              cellWidth="half"
                              value={formModel.updatedBy}
                              editable={false}
                              search={async () => []}
                              displayIcon={true}
                            />
                            <DateTimeCell
                              label="更新日時"
                              cellWidth="half"
                              value={formModel.updatedAt}
                              editable={false}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="更新回数"
                              cellWidth="half"
                              value={formModel.revision}
                              editable={false}
                            />
                          </TableRow>
                        </>
                      )}
                      {leftTab === 1 && (
                        <MultilineTextEditorTab
                          uuid={uuid}
                          externalId="deliverable.description"
                          value={formModel.description}
                          onChange={onChangeDescription}
                        />
                      )}
                      {leftTab === 2 && <ProjectPlanTab uuid={uuid} />}
                      {leftTab === 3 && (
                        <AttachmentTab
                          attachments={formModel.deliverableAttachments}
                          acceptingFileTypes={[]}
                          onUpload={addAttachments}
                          onDelete={deleteAttachments}
                        />
                      )}
                    </PanelRoot>
                  </TabMainArea>
                </TabPanelArea>
              </MainArea>
              <SideBarArea>
                <TabPanelArea>
                  <Tabs value={rightTab} onChange={onChangeRightTab}>
                    <Tab label="ディスカッション" />
                    <Tab label="変更履歴" />
                    <Tab label="開発情報" />
                  </Tabs>
                  <TabMainArea>
                    <PanelRoot>
                      {rightTab === 0 && (
                        <DiscussionTab
                          functionUuid={functionUuid}
                          projectUuid={projectUuid}
                          uuid={uuid}
                        />
                      )}
                      {rightTab === 1 && (
                        <ChangeLogTabPanel
                          uuid={uuid}
                          getParser={getChangeLogParser}
                        />
                      )}
                      {rightTab === 2 && <DevelopmentTabPanel uuid={uuid} />}
                    </PanelRoot>
                  </TabMainArea>
                </TabPanelArea>
              </SideBarArea>
            </SingleSheetRoot>
          </SingleSheetWrapper>
        </>
      )}
    </PageArea>
  )
}

const SingleSheetWrapper = styled('div')({
  width: '100%',
  height: '100%',
  padding: '4px',
})
