import { useCallback, useState } from 'react'
import _ from 'lodash'
import {
  focusRow,
  getSelectedNode,
} from '../../../containers/BulkSheetView/lib/gridApi'
import {
  AdditionalPropertyRow,
  GroupHeaderRow,
  WbsItemAdditionalPropertyRow,
  createNewAdditionalPropertyRow,
  createNewGroupHeaderRow,
  entityToRow,
  isAdditionalPropertyRow,
  isGroupHeaderRow,
} from '../rowModel/wbsItemAdditionalPropertyRow'
import { removeRows } from '../../../containers/BulkSheetView/hooks/actions/crudTreeRows'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import { GridOptions } from 'ag-grid-community'
import { useWbsItemAdditionalPropertyRepository } from '../../../../services/adaptors/wbsItemAdditionalPropertyRepositoryAdaptor'

export const useWbsItemAdditionalPropertyRowData = ({
  projectUuid,
  allWbsItemTypes,
  gridOptions,
}: {
  projectUuid: string
  allWbsItemTypes: WbsItemTypeVO[]
  gridOptions: GridOptions<WbsItemAdditionalPropertyRow>
}): {
  data: WbsItemAdditionalPropertyRow[]
  fetchWbsItemAdditionalProperties: (projectUuid: string) => Promise<void>
  addGroupHeaderRow: (selectedRow: WbsItemAdditionalPropertyRow) => void
  addAdditionalPropertyRow: (selectedRow: WbsItemAdditionalPropertyRow) => void
  deleteRows: () => void
} => {
  const [data, setData] = useState<WbsItemAdditionalPropertyRow[]>([])
  const { getByProjectUuid: getWbsItemAdditionalProperties } =
    useWbsItemAdditionalPropertyRepository()

  const fetchWbsItemAdditionalProperties = useCallback(
    async (projectUuid: string) => {
      const wbsItemAdditionalPropertiesWithLayout =
        await getWbsItemAdditionalProperties(projectUuid)
      sortWbsItemTypesOfAdditionalProperties(
        wbsItemAdditionalPropertiesWithLayout,
        allWbsItemTypes
      )
      setData(entityToRow(projectUuid, wbsItemAdditionalPropertiesWithLayout))
    },
    [getWbsItemAdditionalProperties, allWbsItemTypes]
  )

  const addGroupHeaderRow = useCallback(
    (selectedRow: WbsItemAdditionalPropertyRow) => {
      if (!gridOptions.api) return
      const newRow = createNewGroupHeaderRow(projectUuid)
      const newData = [...data, newRow]
      setData(newData)
      focusRow(gridOptions.api, newRow.uuid)
    },
    [data, projectUuid]
  )
  const addAdditionalPropertyRow = useCallback(
    (selectedRow: WbsItemAdditionalPropertyRow) => {
      if (!gridOptions.api) return
      let newRow: WbsItemAdditionalPropertyRow
      if (isGroupHeaderRow(selectedRow)) {
        const groupHeaderRow = selectedRow as GroupHeaderRow
        newRow = createNewAdditionalPropertyRow(
          projectUuid,
          groupHeaderRow.uuid
        )
      } else if (isAdditionalPropertyRow(selectedRow)) {
        const additionalPropertyRow = selectedRow as AdditionalPropertyRow
        const groupHeaderUuid = additionalPropertyRow.treeValue[0]
        newRow = createNewAdditionalPropertyRow(projectUuid, groupHeaderUuid)
      } else {
        return
      }
      const newData = [...data, newRow]
      setData(newData)
      focusRow(gridOptions.api, newRow.uuid)
    },
    [data, projectUuid, gridOptions]
  )
  const deleteRows = useCallback(() => {
    const rows = getSelectedNode(gridOptions.api!).map(
      v => v.data
    ) as WbsItemAdditionalPropertyRow[]
    const target = _.uniqBy(rows, 'uuid')
    const newData = removeRows(data, target)
    if (gridOptions.context.errors) {
      target.forEach(v => gridOptions.context.errors.removeErrorsById(v.uuid))
    }
    setData(newData)
  }, [data, gridOptions.api, gridOptions.context.errors])

  return {
    data,
    fetchWbsItemAdditionalProperties,
    addGroupHeaderRow,
    addAdditionalPropertyRow,
    deleteRows,
  }
}

const sortWbsItemTypesOfAdditionalProperties = (
  entity: WbsItemAdditionalPropertyLayoutEntity,
  allWbsItemTypes: WbsItemTypeVO[]
) => {
  entity.groupHeaderLayouts.forEach(({ propertyLayouts }) => {
    propertyLayouts.forEach(({ wbsItemAdditionalProperty }) => {
      if (!wbsItemAdditionalProperty.wbsItemTypes) {
        return
      }
      wbsItemAdditionalProperty.wbsItemTypes =
        wbsItemAdditionalProperty.wbsItemTypes.sort((a, b) => {
          return (
            allWbsItemTypes.findIndex(o => o.uuid === a.uuid) -
            allWbsItemTypes.findIndex(o => o.uuid === b.uuid)
          )
        })
    })
  })
}
