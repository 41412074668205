import {
  ColDef,
  EditableCallbackParams,
  ValueGetterParams,
  CellClassParams,
} from 'ag-grid-community'
import {
  WbsItemAdditionalPropertyType,
  getEntitySearchReferenceEntityLabel,
} from '../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { entitySearchReferenceEntity } from '../../../../page-properties/bulksheet-properties/wbsItemAdditionalProperty'
import {
  isAdditionalPropertyRow,
  isGroupHeaderRow,
  AdditionalPropertyRow,
} from '../../rowModel/wbsItemAdditionalPropertyRow'
import { DISABLE_COLOR } from './style'

export const entitySearchReferenceEntityColumnDef: ColDef = {
  ...entitySearchReferenceEntity,
  editable: (params: EditableCallbackParams) =>
    isAdditionalPropertyRow(params.node.data) &&
    params.node.data.propertyType ===
      WbsItemAdditionalPropertyType.ENTITY_SEARCH,
  valueGetter: (params: ValueGetterParams) => {
    if (!params.node || isGroupHeaderRow(params.node.data)) {
      return ''
    }
    const additionalPropertyRow = params.node.data as AdditionalPropertyRow
    return additionalPropertyRow.entitySearchReferenceEntity
      ? getEntitySearchReferenceEntityLabel(
          additionalPropertyRow.entitySearchReferenceEntity
        )
      : ''
  },
  cellStyle: (params: CellClassParams) =>
    isGroupHeaderRow(params.node.data) ||
    params.node.data.propertyType !==
      WbsItemAdditionalPropertyType.ENTITY_SEARCH
      ? {
          backgroundColor: DISABLE_COLOR,
        }
      : undefined,
}
