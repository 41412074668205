import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getOpenWbsItemDetailSpec } from '../../../../../../../../lib/functions/wbsItem'
import { ICellRendererParams, RowNode } from 'ag-grid-community'
import { WbsItemIcon } from '../../../../../../../components/icons/WbsItemIcon'
import store from '../../../../../../../../store'
import { getPathByExternalId } from '../../../../../../../pages'
import { pushFunctionLayer } from '../../../../../../../../store/functionLayer'
import { ClickAwayListener, Tooltip } from '@mui/material'
import { FloatingEditableIcon } from '../../common/text'
import { inputErrorColor } from '../../../../lib/validator'
import {
  FONT_FAMILY,
  FontSize,
} from '../../../../../../../../styles/commonStyles'
import { setError } from '../../../../../../BulkSheetView/lib/validation'
import { FunctionProperty } from '../../../../../../../../lib/commons/appFunction'
import validator from '../../../../../../meta/validator'

interface Props extends ICellRendererParams {
  onCloseWbsItemDetail?: (wbsItem) => void
  beforeOpenWbsItemDetail?: (uuid: string) => Promise<boolean | undefined>
  uiMeta?: Partial<FunctionProperty>
}

export const ProjectPlanTreeCellRenderer = (props: Props) => {
  const {
    value,
    data,
    uiMeta,
    onCloseWbsItemDetail,
    beforeOpenWbsItemDetail,
    node,
  } = props
  const ref = useRef<HTMLDivElement | null>(null)
  const [tooltip, setTooltip] = useState('')
  const [openTooltip, setOpenTooltip] = useState(false)

  useEffect(() => {
    const err = uiMeta
      ? validator
          .validate(value, data, uiMeta as FunctionProperty, () => undefined)
          ?.getMessage()
      : undefined
    setError(data, err, props)
    setTooltip(err ?? '')
  }, [value])

  const openDetail = useCallback(
    async (node: RowNode, event: any) => {
      const d = node.data
      const w = d?.body?.wbsItem ?? d?.wbsItem
      if (!w) return
      if (d.added || d.edited) {
        const success = await beforeOpenWbsItemDetail?.(d.uuid)
        if (!success) return
      }
      const openDetailSpec = await getOpenWbsItemDetailSpec(true, w)
      const layer = openDetailSpec.layer
      openDetailSpec.onOpen && openDetailSpec.onOpen()
      if (
        !openDetailSpec.openInDialog ||
        (event && (event['ctrlKey'] || event['metaKey']))
      ) {
        window.open(`${getPathByExternalId(layer.externalId)}/${layer.code!}`)
      } else {
        store.dispatch(
          pushFunctionLayer({
            ...layer,
            onClose: async () => {
              onCloseWbsItemDetail?.(w)
            },
          })
        )
      }
    },
    [beforeOpenWbsItemDetail]
  )

  useEffect(() => {
    const offsetWidth = ref.current?.offsetWidth
    const ctx = document.createElement('canvas').getContext('2d')
    if (value && offsetWidth && ctx) {
      ctx.font = `${FontSize.MEDIUM} "${FONT_FAMILY}"`
      const textWidth = ctx.measureText(value)?.width
      if (offsetWidth - textWidth < 0) {
        setTooltip(value)
      }
    }
  }, [value])

  const wbsItem = data.body?.wbsItem ?? data.wbsItem
  const type = wbsItem?.wbsItemType
  if (!type) return <></>
  const description = wbsItem.description

  return (
    <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
      <Tooltip
        title={tooltip}
        placement={'bottom-start'}
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: `${FontSize.MEDIUM}px`,
              marginTop: '5px !important',
            },
          },
        }}
        open={openTooltip}
      >
        <div
          id={`wbs-tree-column-${props['rowIndex']}`}
          className="sevend-ag-cell-project-plan-tree"
          ref={ref}
          onClick={e => setOpenTooltip(!openTooltip)}
          onMouseLeave={e => setOpenTooltip(false)}
        >
          <div className="sevend-ag-cell-project-plan-tree__icon-field">
            <button
              className="sevend-ag-cell-project-plan-tree__icon-button"
              onClick={e => openDetail(node, e)}
            >
              <WbsItemIcon type={type} />
            </button>
            {description && <NotificationDot />}
          </div>
          <div
            className="sevend-ag-cell-project-plan-tree__text-field"
            style={{
              backgroundColor: value ? 'none' : inputErrorColor,
            }}
          >
            {value}
          </div>
          <FloatingEditableIcon {...props} />
        </div>
      </Tooltip>
    </ClickAwayListener>
  )
}

const NotificationDot = () => (
  <p className="sevend-ag-cell-project-plan-tree__annotation-has-data" />
)
