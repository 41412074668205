import { useCallback, useMemo } from 'react'
import { FocusableSearchItemLabelProps, SearchItemLabel, usePopup } from '.'
import { dateTermVoService } from '../../../../domain/value-object/DateTermVO'
import { intl } from '../../../../i18n'
import {
  BlankableDateTermCondition,
  BlankableDateTermConditionProps,
} from '../search-components/BlankableDateTermCondition'
import { Popover } from '@mui/material'

type BlankableDateTermConditionItemLabelProps =
  FocusableSearchItemLabelProps<BlankableDateTermConditionProps>

export const BlankableDateTermConditionItemLabel = ({
  value,
  header,
  blank,
  onChangeBlank,
  onChange,
  search,
}: BlankableDateTermConditionItemLabelProps) => {
  const { ref, open, openPopup, closePopup } = usePopup()

  const disabled = useMemo(() => {
    if (blank) return false
    if (!value) return true
    return !value.from && !value.to
  }, [value, blank])

  const valueString = useMemo(() => {
    if (blank) {
      return intl.formatMessage({ id: 'wbsItemSearchFilterPanel.blank' })
    }
    if (!value) return undefined
    const formattedValue = dateTermVoService.format(value, 'YYYY/MM/DD')
    return `${formattedValue.from || ''}~${formattedValue.to || ''}`
  }, [value, blank])

  const onClear = useCallback(() => {
    onChange(undefined)
    onChangeBlank(false)
  }, [onChange, onChangeBlank])

  const onSearch = useCallback(() => {
    closePopup()
    search()
  }, [closePopup, search])

  if (disabled) return <></>
  return (
    <>
      <SearchItemLabel
        ref={ref}
        active={open}
        onClick={openPopup}
        onClear={onClear}
      >
        {header}: {valueString}
      </SearchItemLabel>
      <Popover
        open={open}
        anchorEl={ref.current || null}
        onClose={onSearch}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <BlankableDateTermCondition
          header={header}
          colSpan={10}
          value={value}
          onChange={onChange}
          onEnter={onSearch}
          blank={blank}
          onChangeBlank={onChangeBlank}
        />
      </Popover>
    </>
  )
}
