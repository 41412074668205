import { useCallback, useEffect, useMemo, useState } from 'react'
import { FocusableSearchItemLabelProps, SearchItemLabel, usePopup } from '.'
import { SelectOption } from '../../../model/selectOption'
import { ServerSideAutocompleteConditionProps } from '../search-components/ServerSideAutocompleteCondition'
import { List, ListItem, Popover, Typography } from '@mui/material'
import { Button } from '../../buttons'
import { colorPalette } from '../../../style/colorPallete'

type ServerSideAutocompleteConditionItemLabelProps =
  FocusableSearchItemLabelProps<ServerSideAutocompleteConditionProps>

export const ServerSideAutocompleteConditionItemLabel = ({
  value,
  options,
  ...other
}: ServerSideAutocompleteConditionItemLabelProps) => {
  if (!value) return <></>
  return (
    <CheckedServerSideAutocompleteConditionItemLabel
      options={options}
      {...other}
      value={value}
    />
  )
}

const CheckedServerSideAutocompleteConditionItemLabel = ({
  header,
  value,
  fetchOption,
  onChange,
  search,
  deps,
  options,
}: ServerSideAutocompleteConditionItemLabelProps) => {
  const { ref, open, openPopup, closePopup } = usePopup()

  const [selectedOption, setSelectedOption] = useState<
    SelectOption | undefined
  >()

  useEffect(() => {
    const fetch = async () => {
      if (!value) return
      const option = await fetchOption(value)
      if (!option) return
      setSelectedOption(option)
    }
    fetch()
  }, [deps])

  const valueName = useMemo(() => {
    return selectedOption?.name
  }, [selectedOption])

  const onClear = useCallback(() => {
    onChange(undefined)
  }, [])

  const onSearch = useCallback(() => {
    closePopup()
    search()
  }, [])

  const onChangeValue = useCallback(
    (v: any) => {
      if (value !== v.value) {
        onChange(v.value)
      }
    },
    [onChange, value]
  )

  if (!selectedOption) return <></>
  return (
    <>
      <SearchItemLabel
        ref={ref}
        active={open}
        onClick={openPopup}
        onClear={onClear}
      >
        {header}: {valueName}
      </SearchItemLabel>
      <Popover
        open={open}
        anchorEl={ref.current || null}
        onClose={onSearch}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List>
          {options &&
            options.map(option => (
              <ListItem key={option.value} sx={{ margin: '3px 0', padding: 0 }}>
                <Button
                  colorPattern="monotone"
                  sx={{
                    border: 'none',
                    background: colorPalette.monotone[0],
                    width: '100%',
                    textAlign: 'start',
                    justifyContent: 'start',
                    padding: '0 3px',
                    margin: '0 5px',
                    '&:hover': { background: colorPalette.monotone[1] },
                  }}
                  onClick={() => onChangeValue(option)}
                >
                  <Typography
                    sx={{
                      color: colorPalette.monotone[4],
                      fontWeight: 500,
                      fontSize: '14px',
                      padding: '5px 0 !important',
                      margin: '10px',
                    }}
                  >
                    {option.name}
                  </Typography>
                </Button>
              </ListItem>
            ))}
        </List>
      </Popover>
    </>
  )
}
