import { useCallback, useEffect, useMemo, useState } from 'react'
import { useChangeLogRepository } from '../../../../../services/adaptors/changeLogRepositoryAdaptor'
import { ChangeLog, Delta } from '../../../../../domain/value-object/ChangeLog'
import InfiniteScroll from 'react-infinite-scroller'
import {
  ChangeLogDisplayModel,
  ChangeLogValueParser,
  DeltaDisplayModel,
} from '../../model/changeLog'
import { useWbsItemLabel } from '../../hooks/label'
import { WbsItemStatus } from '../../../../../domain/entity/WbsItemEntity'
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { dateTimeV2Service } from '../../../../../domain/value-object/DateTimeV2'

type ChangeLogTabPanelProps = {
  uuid: string
  getParser: (path: string) => ChangeLogValueParser | undefined
}
export const ChangeLogTabPanel = ({
  uuid,
  getParser,
}: ChangeLogTabPanelProps) => {
  const labelMap = useWbsItemLabel()
  const mapDeltaToDisplayModel = useCallback(
    async (delta: Delta): Promise<DeltaDisplayModel | undefined> => {
      const label = labelMap.get(delta.path)
      const parser = getParser(delta.path)
      if (!label || !parser) return undefined
      const before = delta.oldValue && (await parser(delta.oldValue))
      const after = delta.newValue && (await parser(delta.newValue))
      return {
        label,
        before,
        after,
      }
    },
    [getParser, labelMap]
  )
  const { fetch } = useChangeLogRepository()
  const [changeLogs, setChangeLogs] = useState<ChangeLog[]>([])
  const [changeLogModels, setChangeLogModels] = useState<
    ChangeLogDisplayModel[]
  >([])
  const [loadedCount, setLoadedCount] = useState<number>(0)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const load = useCallback(async () => {
    const response = await fetch(uuid, loadedCount, 10)
    setChangeLogs(prev => [...prev, ...response])
    const models = await Promise.all(
      response.map(async log => {
        const delta = await Promise.all(
          log.delta.map(d => mapDeltaToDisplayModel(d))
        ).then(delta => delta.filter(d => !!d) as DeltaDisplayModel[])
        return {
          updatedAt: new Date(log.updatedAt),
          updatedBy: log.updatedBy?.name || '',
          delta,
        }
      })
    ).then(model => model.filter(m => m.delta.length > 0))
    setChangeLogModels(prev => [...prev, ...models])
    const loaded = response.length
    setLoadedCount(prev => prev + loaded)
    if (loaded < 10) setHasMore(false)
  }, [fetch, loadedCount, mapDeltaToDisplayModel, uuid])

  return (
    <InfiniteScroll
      loadMore={load}
      hasMore={hasMore}
      useWindow={false}
      threshold={10}
    >
      {changeLogModels.map((changeLog, i) => (
        <ChangeLogView key={i} changeLog={changeLog} />
      ))}
    </InfiniteScroll>
  )
}

type ChangeLogViewProps = {
  changeLog: ChangeLogDisplayModel
}
const ChangeLogView = ({ changeLog }: ChangeLogViewProps) => {
  const changedAt = useMemo(() => {
    return dateTimeV2Service.format(changeLog.updatedAt)
  }, [changeLog.updatedAt])
  return (
    <ChangeLogRoot>
      <HeaderArea>
        <ChangedAt>{changedAt}</ChangedAt>
        <ChangedBy>{changeLog.updatedBy}</ChangedBy>
      </HeaderArea>
      <BodyArea>
        <ChangeLogTable>
          <ChangeLogTableHeader>
            <ChangeLogTableRow>
              <ChangeLogTableCell>{'項目名'}</ChangeLogTableCell>
              <ChangeLogTableCell>{'変更前'}</ChangeLogTableCell>
              <ChangeLogTableCell>{'変更後'}</ChangeLogTableCell>
            </ChangeLogTableRow>
          </ChangeLogTableHeader>
          <ChangeLogTableBody>
            {changeLog.delta.map((d, i) => (
              <ChangeLogDeltaRow key={i} delta={d} />
            ))}
          </ChangeLogTableBody>
        </ChangeLogTable>
      </BodyArea>
    </ChangeLogRoot>
  )
}

type ChangeLogDeltaRowProps = {
  delta: DeltaDisplayModel
}
const ChangeLogDeltaRow = ({ delta }: ChangeLogDeltaRowProps) => {
  const [before, after] = useMemo(
    () => [delta.before || '（未設定）', delta.after || '（未設定）'],
    [delta.after, delta.before]
  )
  return (
    <ChangeLogTableRow>
      <ChangeLogTableCell>{delta.label}</ChangeLogTableCell>
      <ChangeLogTableCell>{before}</ChangeLogTableCell>
      <ChangeLogTableCell>{after}</ChangeLogTableCell>
    </ChangeLogTableRow>
  )
}

const ChangeLogRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
  gap: '8px',
})
const HeaderArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})
const ChangedAt = styled('span')({
  color: colorPalette.monotone[4],
  fontSize: '10px',
})
const ChangedBy = styled('span')({
  color: colorPalette.monotone[10],
  fontSize: '11px',
})
const BodyArea = styled('div')({})
const ChangeLogTable = styled(Table)({})
const ChangeLogTableHeader = styled(TableHead)({
  backgroundColor: colorPalette.monotone[1],
})
const ChangeLogTableRow = styled(TableRow)({})
const ChangeLogTableBody = styled(TableBody)({})
const ChangeLogTableCell = styled(TableCell)({
  padding: '4px 8px',
})
