import { useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import API, {
  APIResponse,
  successDummyResponse,
} from '../../../../lib/commons/api'
import store from '../../../../store'
import {
  doNotRequireSave,
  requireSave,
} from '../../../../store/requiredSaveData'
import { ImpactType, ProbabilityType, RiskRow, StrategyType } from '../risks'
import {
  CreateTicketInput,
  TicketDetail,
  UpdateTicketDeltaInput,
} from '../../../../lib/functions/ticket'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import { WbsItemStatus } from '../../../../domain/entity/WbsItemEntity'
import { CUSTOM_ENUM_NONE } from '../../../../lib/commons/customEnum'
import { NewWbsItemRow } from '../../ProjectPlanNew/projectPlanNew'
import { WbsItemDeltaInput } from '../../../../lib/functions/wbsItem'
import {
  createDelta,
  IItemDelta,
} from '../../../../domain/value-object/ItemDeltaInputVO'
import { generateUuid } from '../../../../utils/uuids'
import { hasDiffDateTerm } from '../../../../utils/date'
import { WatchersInput } from '../../../../lib/functions/projectPlan'
import SprintBacklog, {
  UpdateBatchSprintBacklogProps,
} from '../../../../lib/functions/sprintBacklog'
import {
  serializeExtensionValue,
  serializeExtensionValueDelta,
} from '../../../containers/BulkSheetView/gridOptions/extension'
import {
  EntityExtensionValue,
  EntityExtensionValueDelta,
} from '../../../containers/meta/entityExtension'
import { TagsInput, isTagColumnEdited } from '../../../../lib/functions/tag'

export const useRiskData = (
  projectUuid: string,
  ticketListUuid: string | undefined
): {
  data: RiskRow[]
  setData: (rows: RiskRow[]) => void
  refresh: Function
  save: () => Promise<APIResponse[]>
  deleteRows: (rows: RiskRow[]) => void
  generateWbsItemDeltaRequest: (ros: RiskRow) => WbsItemDeltaInput | undefined
} => {
  const [data, setDataInternal] = useState<RiskRow[]>([])
  const [deletedRows, setDeletedRows] = useState<RiskRow[]>([])
  const originalData = useRef<RiskRow[]>([])

  useEffect(() => {
    refresh()
  }, [ticketListUuid])

  const setData = useCallback((d: RiskRow[]) => {
    setDataInternal(d)
    store.dispatch(requireSave())
  }, [])

  const refresh = useCallback(async () => {
    const risks = await fetch()
    setDataInternal(risks)
    originalData.current = _.cloneDeep(risks)
    setDeletedRows([])
    store.dispatch(doNotRequireSave())
  }, [ticketListUuid])

  // Fetch data
  const fetch = useCallback(async (): Promise<RiskRow[]> => {
    if (!ticketListUuid) return []
    const response = await fetchRisks({ ticketListUuid })
    return response.json.map(v => fromStoredObjectToRisk(v))
  }, [ticketListUuid])

  // Save data
  const save = useCallback(async (): Promise<APIResponse[]> => {
    const [response, sprintResponse] = await saveRisks(data, {
      projectUuid,
      ticketListUuid: ticketListUuid!,
      originalData: originalData.current,
      deletedRows,
    })
    return [response, sprintResponse]
  }, [data, projectUuid, ticketListUuid, deletedRows])

  const deleteRows = useCallback(
    (rows: RiskRow[]) => {
      setDeletedRows([...deletedRows, ...rows.filter(v => !v.added)])
      setDataInternal([])
    },
    [deletedRows]
  )

  const generateWbsItemDeltaRequest = useCallback(
    (row: RiskRow): WbsItemDeltaInput | undefined => {
      const original = originalData.current.find(v => v.uuid === row.uuid)
      if (row.added || !original?.wbsItem) return undefined
      const originalExtensions = original.extensions ?? []
      const extensionInput = (row.extensions ?? [])
        .map(v =>
          serializeExtensionValueDelta(v.uuid, {
            oldValue: originalExtensions.find(e => e.uuid === v.uuid)?.value,
            newValue: v.value,
          })
        )
        .filter(v => !!v) as EntityExtensionValueDelta[]
      return generateUpdateWbsItemDeltaRequest(
        original.wbsItem,
        row.wbsItem,
        extensionInput
      )
    },
    []
  )

  return {
    data,
    setData,
    refresh,
    save,
    deleteRows,
    generateWbsItemDeltaRequest,
  }
}

// TODO: Move it
// Fetch
const fetchRisks = async (params: {
  ticketListUuid: string
}): Promise<APIResponse> => {
  return API.functional.request('GET', '/api/v1/projects/risks/list', params)
}

export type FetchRiskResponse = {
  uuid: string
  lockVersion: number
  taskUuid: string
  riskType: string
  probability: string
  impact: string
  strategy: string
  score: number

  ticket: TicketDetail
  extensions?: EntityExtensionValue[]
}

const fromStoredObjectToRisk = (source: FetchRiskResponse): RiskRow => {
  const { ticket, ...riskProperties } = source
  const {
    uuid: ticketUuid,
    lockVersion: ticketLockVersion,
    parentUuid,
    prevSiblingUuid,
    ticketList,
    parentWbsItem,
    wbsItem: w,
    cumulation,
    commentSummary,
    deliverableAttachmentSummary,
    parentPath,
  } = ticket
  return {
    treeValue: [],
    ...riskProperties,
    ticketUuid,
    ticketLockVersion,
    parentUuid,
    prevSiblingUuid,
    ticketList,
    parentPath,
    wbsItem: {
      ...w,
      wbsItemType: w.typeDto
        ? new WbsItemTypeVO(w.typeDto)
        : store.getState().project.wbsItemTypes.get(w.type)!,
      baseWbsItemType: w.baseTypeDto
        ? new WbsItemTypeVO(w.baseTypeDto)
        : store.getState().project.wbsItemTypes.get(w.type)!,
      ticketType: w.ticketType === CUSTOM_ENUM_NONE ? undefined : w.ticketType,
      status: w.status ? (w.status as WbsItemStatus) : undefined,
      substatus: w.substatus === CUSTOM_ENUM_NONE ? undefined : w.substatus,
      priority: w.priority === CUSTOM_ENUM_NONE ? undefined : w.priority,
      difficulty: w.difficulty === CUSTOM_ENUM_NONE ? undefined : w.difficulty,
      description: w.description ?? '',
      tags: w.tags,
      sprint: w.sprint,
    },
    cumulation,
    parentWbsItem,
    commentSummary,
    deliverableAttachmentSummary,
    extensions: [
      ...(riskProperties?.extensions ?? []),
      ...(w.extensions ?? []),
    ],
  }
}

// Save
const saveRisks = async (
  data: RiskRow[],
  {
    projectUuid,
    ticketListUuid,
    originalData,
    deletedRows,
  }: {
    projectUuid: string
    ticketListUuid: string
    originalData: RiskRow[]
    deletedRows: RiskRow[]
  }
) => {
  const changed = data.filter(v => v.added || v.edited)
  const request: UpdateRiskDeltaBatchRequest = {
    added: changed
      .filter(row => row.added && !!row.wbsItem?.displayName)
      .map((row: RiskRow): CreateRiskInput => {
        const wbsItem: NewWbsItemRow = row.wbsItem
        const index = data.findIndex(v => v.uuid === row.uuid)
        const ticketPrevSiblingUuid = data[index - 1]?.ticketUuid
        const extensionInput = row?.extensions?.map(v =>
          serializeExtensionValue(v.uuid, v.value)
        )
        return {
          riskType: row.riskType,
          probability: row.probability,
          impact: row.impact,
          strategy: row.strategy,
          ticket: {
            projectUuid,
            uuid: row.ticketUuid,
            ticketType: row.wbsItem.ticketType!,
            ticketListUuid,
            prevSiblingUuid: ticketPrevSiblingUuid,
            parentWbsItemUuid: row.parentWbsItem?.uuid!,
            projectPlan: {
              projectUuid,
              uuid: generateUuid(),
              type: wbsItem.type,
              ticketType: 'RISK',
              wbsItem: {
                ...wbsItem,
                typeUuid: wbsItem.wbsItemType.uuid,
                displayName: wbsItem.displayName ?? '',
                description: wbsItem.description ?? '',
                teamUuid: wbsItem.team?.uuid,
                accountableUuid: wbsItem.accountable?.uuid,
                responsibleUuid: wbsItem.responsible?.uuid,
                assigneeUuid: wbsItem.assignee?.uuid,
                watchers: wbsItem.watchers?.map(v => v.uuid),
                scheduledDate: {
                  startDate: wbsItem.scheduledDate?.startDate,
                  endDate: wbsItem.scheduledDate?.endDate,
                },
                actualDate: {
                  startDate: wbsItem.actualDate?.startDate,
                  endDate: wbsItem.actualDate?.endDate,
                },
                sprintUuid: wbsItem.sprint?.uuid,
              },
            },
            extensions: extensionInput,
          },
          extensions: extensionInput,
        }
      }),
    edited: changed
      .filter(row => row.edited && !row.added)
      .map((row: RiskRow) => {
        const original = originalData.find(v => v.uuid === row.uuid)!
        const originalTicketPrevSiblingUuid = originalData.find(
          v => v.ticketUuid === row.prevSiblingUuid
        )?.ticketUuid
        const index = data.findIndex(v => v.uuid === row.uuid)
        const ticketPrevSiblingUuid = data[index - 1]?.ticketUuid
        const wbsItem = row.wbsItem
        const originalWbsItem = original.wbsItem
        const originalExtensions = original.extensions ?? []
        const extensionInput = (row.extensions ?? [])
          .map(v =>
            serializeExtensionValueDelta(v.uuid, {
              oldValue: originalExtensions.find(e => e.uuid === v.uuid)?.value,
              newValue: v.value,
            })
          )
          .filter(v => !!v) as EntityExtensionValueDelta[]

        return {
          uuid: row.uuid,
          riskType: createDelta(original.riskType, row.riskType),
          probability: createDelta(original.probability, row.probability),
          impact: createDelta(original.impact, row.impact),
          strategy: createDelta(original.strategy, row.strategy),
          ticket: {
            uuid: row.ticketUuid,
            ticketListUuid: createDelta(
              original.ticketList?.uuid,
              row.ticketList?.uuid
            ),
            prevSiblingUuid: {
              oldValue: originalTicketPrevSiblingUuid,
              newValue: ticketPrevSiblingUuid,
            },
            wbsItem: generateUpdateWbsItemDeltaRequest(
              originalWbsItem,
              wbsItem,
              extensionInput
            ),
            extensions: extensionInput,
          } as UpdateTicketDeltaInput,
          extensions: extensionInput,
        } as UpdateRiskDeltaInput
      }),
    deleted: deletedRows.map(row => {
      return {
        wbsItemUuid: row.wbsItem.uuid!,
        wbsItemLockVersion: row.wbsItem.lockVersion!,
      }
    }),
    watchers: changed
      .filter(row => {
        const watcherToString = d =>
          d
            .map(v => v.uuid)
            .sort()
            .join()
        const original =
          originalData.find(v => v.uuid === row.uuid)?.wbsItem?.watchers ?? []
        const newData = row.wbsItem?.watchers ?? []
        return (
          (row.added || row.edited) &&
          watcherToString(original) !== watcherToString(newData)
        )
      })
      .map(row => {
        const w = row.wbsItem
        return {
          uuid: row.uuid,
          wbsItemUuid: w.uuid,
          userUuids:
            w.watchers && Array.isArray(w.watchers)
              ? w.watchers.map(v => v.uuid)
              : [],
        }
      }),
    tags: changed
      .filter(row => {
        const original =
          originalData.find(v => v.uuid === row.uuid)?.wbsItem?.tags ?? []
        const newData = row.wbsItem?.tags ?? []
        return (row.added || row.edited) && isTagColumnEdited(original, newData)
      })
      .map(row => {
        const wbs = row.wbsItem
        return {
          uuid: row.uuid,
          wbsItemUuid: wbs.uuid,
          tagUuids:
            wbs.tags && Array.isArray(wbs.tags)
              ? wbs.tags.map(v => v.uuid)
              : [],
        }
      }),
  }
  const response = await updateRiskBatchDelta(request)
  const wbsItemLockVersionMap = Object.fromEntries(
    [...response.json?.added, ...response.json?.edited].map(v => [
      v.wbsItemUuid,
      v.wbsItemLockVersion,
    ])
  )
  const sprintResponse = await saveSprintBacklog(
    changed,
    originalData,
    wbsItemLockVersionMap
  )
  return [response, sprintResponse]
}

function updateRiskBatchDelta(
  request: UpdateRiskDeltaBatchRequest
): Promise<APIResponse> {
  return API.functional.request(
    'POST',
    '/api/v1/projects/risks/delta/batch',
    request
  )
}

type UpdateRiskDeltaBatchRequest = {
  added: CreateRiskInput[]
  edited: UpdateRiskDeltaInput[]
  deleted: {
    wbsItemUuid: string
    wbsItemLockVersion: number
  }[]
  watchers: WatchersInput[]
  tags?: TagsInput[]
}

type CreateRiskInput = {
  riskType: string
  probability: ProbabilityType
  impact: ImpactType
  strategy: StrategyType

  ticket: CreateTicketInput
  extensions?: EntityExtensionValue[]
}

export type UpdateRiskDeltaInput = {
  uuid: string
  riskType?: IItemDelta<string>
  probability?: IItemDelta<ProbabilityType>
  impact?: IItemDelta<ImpactType>
  strategy?: IItemDelta<StrategyType>

  ticket: UpdateTicketDeltaInput
  extensions: EntityExtensionValueDelta[]
}

const generateUpdateWbsItemDeltaRequest = (
  original: NewWbsItemRow,
  wbsItem: NewWbsItemRow,
  extension: EntityExtensionValueDelta[]
): WbsItemDeltaInput => {
  return {
    uuid: wbsItem.uuid,
    type: wbsItem.type,
    status: createDelta(original.status, wbsItem.status),
    substatus: createDelta(original.substatus, wbsItem.substatus),
    displayName: createDelta(original.displayName, wbsItem.displayName),
    description: createDelta(original.description, wbsItem.description),
    teamUuid: createDelta(original.team?.uuid, wbsItem.team?.uuid),
    accountableUuid: createDelta(
      original.accountable?.uuid,
      wbsItem.accountable?.uuid
    ),
    responsibleUuid: createDelta(
      original.responsible?.uuid,
      wbsItem.responsible?.uuid
    ),
    assigneeUuid: createDelta(original.assignee?.uuid, wbsItem.assignee?.uuid),
    estimatedStoryPoint: createDelta(
      original.estimatedStoryPoint,
      wbsItem.estimatedStoryPoint
    ),
    estimatedHour: createDelta(original.estimatedHour, wbsItem.estimatedHour),
    priority: createDelta(original.priority, wbsItem.priority),
    difficulty: createDelta(original.difficulty, wbsItem.difficulty),
    scheduledDate: hasDiffDateTerm(
      original.scheduledDate,
      wbsItem.scheduledDate
    )
      ? {
          oldValue: original.scheduledDate,
          newValue: wbsItem.scheduledDate,
        }
      : undefined,
    actualDate: hasDiffDateTerm(original.actualDate, wbsItem.actualDate)
      ? {
          oldValue: original.actualDate,
          newValue: wbsItem.actualDate,
        }
      : undefined,
    estimatedAmount: createDelta(
      original.estimatedAmount,
      wbsItem.estimatedAmount
    ),
    actualAmount: createDelta(original.actualAmount, wbsItem.actualAmount),
    extensions: extension,
    sprintUuid: createDelta(original.sprint?.uuid, wbsItem.sprint?.uuid),
  } as WbsItemDeltaInput
}

const saveSprintBacklog = async (
  changed: RiskRow[],
  originalData: RiskRow[],
  wbsItemLockVersionMap: { [wbsItemUuid: string]: number }
): Promise<APIResponse> => {
  if (_.isEmpty(changed)) return successDummyResponse
  const sprintBacklogRequest: UpdateBatchSprintBacklogProps = {
    sprintBacklogItems: [],
    sprintPlans: {
      added: [],
      deleted: [],
    },
  }
  changed.forEach(row => {
    const wbsItem = row.wbsItem
    const sprint = wbsItem.sprint
    const originalSprint = originalData.find(v => v.uuid === row.uuid)?.wbsItem
      .sprint
    if (!wbsItem?.uuid) return
    if (
      !!sprint &&
      (row.added ||
        (row.edited &&
          (!originalSprint || originalSprint.uuid !== sprint.uuid)))
    ) {
      sprintBacklogRequest.sprintPlans.added.push({
        taskUuid: wbsItem.uuid,
        taskLockVersion:
          wbsItemLockVersionMap[wbsItem.uuid] ?? wbsItem.lockVersion!,
        sprintUuid: sprint.uuid,
      })
    } else if (row.edited && !sprint && !!originalSprint) {
      sprintBacklogRequest.sprintPlans.deleted.push({
        taskUuid: wbsItem.uuid,
        taskLockVersion:
          wbsItemLockVersionMap[wbsItem.uuid] ?? wbsItem.lockVersion!,
        sprintUuid: originalSprint.uuid,
      })
    }
  })
  if (
    _.isEmpty(sprintBacklogRequest.sprintPlans.added) &&
    _.isEmpty(sprintBacklogRequest.sprintPlans.deleted)
  ) {
    return successDummyResponse
  }
  return SprintBacklog.updateBatchSprintBacklog(sprintBacklogRequest)
}
