import { useState, useEffect, useCallback } from 'react'
import {
  UpdateWbsItemDeltaInput,
  WbsItemEntity,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import {
  WbsItemEditableField,
  WbsItemFormModel,
  createInitialValue,
} from '../model'
import { useWbsItemRepository } from '../../../../services/adaptors/wbsItemRepositoryAdaptor'
import { ReferencedEntity } from '../../../../domain/value-object/ReferencedEntity'
import {
  DateTermV2,
  dateTermV2Service,
} from '../../../../domain/value-object/DateTermV2'
import { IItemDelta } from '../../../../domain/value-object/ItemDeltaInputVO'
import { useDispatch } from 'react-redux'
import { addScreenMessage, MessageLevel } from '../../../../store/messages'
import { intl } from '../../../../i18n'

export const useWbsItemSingleSheetData = (uuid: string) => {
  const { getDetail, updateDelta } = useWbsItemRepository()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [entity, setEntity] = useState<WbsItemEntity | undefined>()
  const [formModel, setFormModel] = useState<WbsItemFormModel>(
    createInitialValue(WbsItemType.TASK)
  )
  const fetchData = useCallback(async () => {
    setIsLoading(true)
    const response = await getDetail(uuid)
    setEntity(response)
    setFormModel(response)
    setIsLoading(false)
  }, [getDetail, uuid])
  useEffect(() => {
    fetchData()
    setInitialized(true)
  }, [fetchData, uuid])
  const onChange = useCallback(
    <K extends keyof WbsItemFormModel, T extends WbsItemFormModel[K]>(
      path: K,
      value: T
    ) => {
      setFormModel(prev => {
        const newModel = {
          ...prev,
        }
        newModel[path] = value
        return newModel
      })
    },
    []
  )
  const dispatch = useDispatch()
  const submit = useCallback(async () => {
    if (!entity) return
    setIsLoading(true)
    const input = toDeltaInput(entity, formModel)
    const request = {
      input,
      watchers: (formModel.watchers || []).map(watcher => watcher.uuid),
      tags: {
        wbsItemUuid: uuid,
        tagUuids: (formModel.tags || []).map(tag => tag.uuid),
      },
    }
    await updateDelta(request)
    dispatch(
      addScreenMessage('wbsItem', {
        type: MessageLevel.SUCCESS,
        title: intl.formatMessage({ id: 'update.completed.title' }),
      })
    )
    await fetchData()
    setIsLoading(false)
  }, [dispatch, entity, fetchData, formModel, updateDelta, uuid])
  return {
    initialized,
    isLoading,
    formModel,
    onChange,
    reload: fetchData,
    submit,
  }
}

const toDeltaInput = (
  entity: WbsItemEntity,
  model: WbsItemFormModel
): UpdateWbsItemDeltaInput => {
  const input: UpdateWbsItemDeltaInput = {
    uuid: entity.uuid,
    type: entity.type,
  }
  // TODO: Consider to simplify, maybe by defining field properties.
  const updatableFields: WbsItemEditableField[] = [
    'displayName',
    'description',
    'status',
    'substatus',
    'difficulty',
    'priority',
    'team',
    'accountable',
    'responsible',
    'assignee',
    'estimatedStoryPoint',
    'estimatedHour',
    'scheduledDate',
    'actualDate',
  ]
  for (let field of updatableFields) {
    if (isValueDiffered(entity, model, field)) {
      input[field] = toDeltaValue(entity, model, field)
    }
  }
  return input
}

const isValueDiffered = (
  entity: WbsItemEntity,
  model: WbsItemFormModel,
  path: WbsItemEditableField
) => {
  switch (path) {
    case 'displayName':
    case 'description':
      return isTextValueDiffered(entity[path], model[path])
    case 'status':
    case 'substatus':
    case 'difficulty':
    case 'priority':
      return isSelectValueDiffered(entity[path], model[path])
    case 'team':
    case 'accountable':
    case 'responsible':
    case 'assignee':
      return isReferencedEntityValueDiffered(entity[path], model[path])
    case 'estimatedStoryPoint':
    case 'estimatedHour':
      return isNumberValueDiffered(entity[path], model[path])
    case 'scheduledDate':
    case 'actualDate':
      return isDateTermValueDiffered(entity[path], model[path])
  }
  return false
}

const isTextValueDiffered = (
  oldValue: string | undefined,
  newValue: string | undefined
): boolean => {
  if (!oldValue && !newValue) return false
  if (!oldValue || !newValue) return true
  return oldValue !== newValue
}

const isNumberValueDiffered = (
  oldValue: number | undefined,
  newValue: number | undefined
): boolean => {
  if (!oldValue && !newValue) return false
  if (!oldValue || !newValue) return true
  return oldValue !== newValue
}

const isSelectValueDiffered = (
  oldValue: string | undefined,
  newValue: string | undefined
): boolean => {
  if (!oldValue && !newValue) return false
  if (!oldValue || !newValue) return true
  return oldValue !== newValue
}

const isReferencedEntityValueDiffered = (
  oldValue: ReferencedEntity | undefined,
  newValue: ReferencedEntity | undefined
): boolean => {
  if (!oldValue && !newValue) return false
  if (!oldValue || !newValue) return true
  return oldValue.uuid !== newValue.uuid
}

const isDateTermValueDiffered = (
  oldValue: DateTermV2 | undefined,
  newValue: DateTermV2 | undefined
): boolean => {
  return !dateTermV2Service.isEqual(oldValue, newValue)
}

const toDeltaValue = (
  entity: WbsItemEntity,
  model: WbsItemFormModel,
  path: WbsItemEditableField
) => {
  switch (path) {
    case 'displayName':
    case 'description':
      return toTextDeltaValue(entity[path], model[path])
    case 'status':
    case 'substatus':
    case 'difficulty':
    case 'priority':
      return isSelectDeltaValue(entity[path], model[path])
    case 'team':
    case 'accountable':
    case 'responsible':
    case 'assignee':
      return isReferencedEntityDeltaValue(entity[path], model[path])
    case 'estimatedStoryPoint':
    case 'estimatedHour':
      return isNumberDeltaValue(entity[path], model[path])
    case 'scheduledDate':
    case 'actualDate':
      return isDateTermDeltaValue(entity[path], model[path])
  }
  return undefined
}

const toTextDeltaValue = (
  oldValue: string | undefined,
  newValue: string | undefined
): IItemDelta<string> => {
  return {
    oldValue,
    newValue,
  }
}

const isNumberDeltaValue = (
  oldValue: number | undefined,
  newValue: number | undefined
): IItemDelta<number> => {
  return {
    oldValue,
    newValue,
  }
}

const isSelectDeltaValue = (
  oldValue: string | undefined,
  newValue: string | undefined
): IItemDelta<string> => {
  return {
    oldValue,
    newValue,
  }
}

const isReferencedEntityDeltaValue = (
  oldValue: ReferencedEntity | undefined,
  newValue: ReferencedEntity | undefined
): IItemDelta<string> => {
  return { oldValue: oldValue?.uuid, newValue: newValue?.uuid }
}

const isDateTermDeltaValue = (
  oldValue: DateTermV2 | undefined,
  newValue: DateTermV2 | undefined
): IItemDelta<DateTermV2> => {
  return {
    oldValue,
    newValue,
  }
}
