import { useCallback } from 'react'
import api from '../../lib/commons/api'
import { WbsItemRepository } from '../../applications/ports/wbsItemRepository'
import {
  AddWbsItemAttachmentRequest,
  DeleteWbsItemAttachmentRequest,
  fromApiResponseToEntity,
  UpdateWbsItemDeltaRequest,
  WbsItemType,
} from '../../domain/entity/WbsItemEntity'

export const useWbsItemRepository = (): WbsItemRepository => {
  const getBasicByCode = useCallback(async (code: string) => {
    const response = await api.functional.request(
      'GET',
      '/api/v1/projects/wbs_items/basic',
      { code },
      true
    )
    return response.json
  }, [])
  const getDetail = useCallback(async (uuid: string) => {
    const response = await api.functional.request(
      'GET',
      '/api/v1/projects/wbs_items/detail',
      { wbsItemUuid: uuid },
      true
    )
    return fromApiResponseToEntity(response.json)
  }, [])
  const updateDelta = useCallback(
    async (request: UpdateWbsItemDeltaRequest) => {
      const endPoint = (() => {
        switch (request.input.type) {
          case WbsItemType.PROCESS:
            return '/api/v1/projects/wbs_items/delta/process'
          case WbsItemType.DELIVERABLE_LIST:
            return '/api/v1/projects/wbs_items/delta/deliverable_list'
          case WbsItemType.DELIVERABLE:
            return '/api/v1/projects/wbs_items/delta/deliverable'
          case WbsItemType.TASK:
            return '/api/v1/projects/wbs_items/delta/task'
        }
        return ''
      })()
      const response = await api.functional.request(
        'PUT',
        endPoint,
        request,
        true
      )
      return {}
    },
    []
  )
  const addAttachments = useCallback(
    async (request: AddWbsItemAttachmentRequest) => {
      const response = await api.functional.request(
        'POST',
        '/api/v1/projects/wbs_items/attachments/items',
        request,
        true
      )
      return response.json
    },
    []
  )
  const deleteAttachments = useCallback(
    async (request: DeleteWbsItemAttachmentRequest) => {
      const response = await api.functional.request(
        'DELETE',
        '/api/v1/projects/wbs_items/attachments/items',
        request,
        true
      )
      return response.json
    },
    []
  )
  return {
    getBasicByCode,
    getDetail,
    updateDelta,
    addAttachments,
    deleteAttachments,
  }
}
