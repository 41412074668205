export type DateTimeV2 = Date

const format = (src: DateTimeV2): string => {
  const year = src.getFullYear()
  const month = src.getMonth() + 1
  const fullMonth = month < 10 ? `0${month}` : month.toString()
  const date = src.getDate()
  const fullDate = date < 10 ? `0${date}` : date.toString()
  const hour = src.getHours()
  const minutes = src.getMinutes()
  const seconds = src.getSeconds()
  return `${year}/${fullMonth}/${date} ${hour}:${minutes}:${seconds}`
}

const newFromString = (src: string): DateTimeV2 => {
  return new Date(src)
}

export const dateTimeV2Service = {
  format,
  newFromString,
}
