import {
  Box,
  Button,
  ClickAwayListener,
  Popper,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import {
  BackgroundColor,
  Color,
  FontSize,
} from '../../../../../styles/commonStyles'
import { intl } from '../../../../../i18n'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { CSSProperties, useCallback, useState } from 'react'
import { QuickFilterKeys } from '../..'
import CheckMark from '../../../../../assets/filter_check_mark.png'
import { colorPalette } from '../../../../style/colorPallete'
import { Checkbox } from '../../../../components/inputs/Checkbox'

type Props = {
  onChangeQuickFilters: (keys: QuickFilterKeys[]) => void | Promise<void>
  selectedFilterGroups: QuickFilterKeys[]
}
const ProgressFilter = ({
  selectedFilterGroups,
  onChangeQuickFilters,
}: Props) => {
  const [openProgressFilter, setOpenProgressFilter] = useState<boolean>(false)
  const [filterAnchorEl, setFilterAnchorEl] = useState<Element>()

  const [startByTodayChecked, setStartByTodayChecked] = useState<boolean>(false)
  const [endByTodayChecked, setEndByTodayChecked] = useState<boolean>(false)
  const [delayChecked, setDelayChecked] = useState<boolean>(false)

  const handleFilterClose = () => {
    setFilterAnchorEl(undefined)
    setOpenProgressFilter(false)
  }

  const onClickCheckbox = useCallback(
    (filterGroups: QuickFilterKeys[]) => {
      filterGroups.forEach(filterGroup => {
        if (selectedFilterGroups.includes(filterGroup)) {
          onChangeQuickFilters(
            selectedFilterGroups.filter(filter => filter !== filterGroup)
          )
        } else {
          onChangeQuickFilters([...selectedFilterGroups, filterGroup])
        }
      })
    },
    [selectedFilterGroups]
  )

  return (
    <Box
      sx={{
        margin: ' 0 10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: 'fit-content',
      }}
    >
      <Typography
        sx={{
          color: colorPalette.monotone[5],
          fontSize: FontSize.MEDIUM,
          marginRight: '10px',
        }}
      >
        {intl.formatMessage({ id: 'wbs.header.progress' })}
      </Typography>
      <ClickAwayListener onClickAway={handleFilterClose}>
        <div>
          <Button
            sx={{
              border: `1px solid ${colorPalette.monotone[2]}`,
              background: colorPalette.monotone[0],
              '&:hover': { background: colorPalette.monotone[0] },
              color: colorPalette.monotone[4],
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            onClick={event => {
              if (openProgressFilter) {
                setFilterAnchorEl(undefined)
                setOpenProgressFilter(false)
              } else {
                setFilterAnchorEl(event.currentTarget)
                setOpenProgressFilter(true)
              }
            }}
          >
            <Typography
              sx={{ margin: '0 auto 0 10px', fontSize: FontSize.SMALL }}
            >
              {intl.formatMessage({ id: 'wbs.header.select.filter' })}
            </Typography>
            {openProgressFilter ? (
              <ExpandLess
                sx={{ margin: '0 5px 0 auto', height: '13px', width: '13px' }}
              />
            ) : (
              <ExpandMore
                sx={{ margin: '0 5px 0 auto', height: '13px', width: '13px' }}
              />
            )}
          </Button>
          {openProgressFilter ? (
            <Popper
              open={!!filterAnchorEl}
              anchorEl={filterAnchorEl}
              sx={{
                zIndex: '1300',
                background: colorPalette.monotone[0],
                height: 'fit-content',
                width: '200px',
                borderRadius: '4px',
                boxShadow: '0px 1px 2px #0000004D',
              }}
              placement={'bottom-start'}
              modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]}
            >
              <Table>
                <TableRow>
                  <CheckBoxRowCell
                    onClick={() => (
                      setStartByTodayChecked(!startByTodayChecked),
                      onClickCheckbox([QuickFilterKeys.START_BY_TODAY])
                    )}
                    title={intl.formatMessage({
                      id: 'bulksheet.toolbar.quickFilter.label.startByToday',
                    })}
                    checked={startByTodayChecked}
                    style={{ padding: '20px 20px 10px 20px' }}
                  />
                  <CheckBoxRowCell
                    onClick={() => (
                      setEndByTodayChecked(!endByTodayChecked),
                      onClickCheckbox([QuickFilterKeys.END_BY_TODAY])
                    )}
                    title={intl.formatMessage({
                      id: 'bulksheet.toolbar.quickFilter.label.endByToday',
                    })}
                    checked={endByTodayChecked}
                    style={{ padding: '15px 20px 15px 20px' }}
                  />
                  <CheckBoxRowCell
                    onClick={() => (
                      setDelayChecked(!delayChecked),
                      onClickCheckbox([QuickFilterKeys.DELAY])
                    )}
                    title={intl.formatMessage({
                      id: 'bulksheet.toolbar.quickFilter.label.delay',
                    })}
                    checked={delayChecked}
                    style={{ padding: '10px 20px 20px 20px' }}
                  />
                </TableRow>
              </Table>
            </Popper>
          ) : null}
        </div>
      </ClickAwayListener>
    </Box>
  )
}

type CheckBoxProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
  title: string
  checked: boolean
  style?: CSSProperties
}

const CheckBoxRowCell = ({ onClick, title, checked, style }: CheckBoxProps) => {
  return (
    <TableCell
      sx={{
        display: 'flex',
        flexDirection: 'row',
        borderBottom: 'none',
        padding: style?.padding,
        alignItems: 'center',
      }}
    >
      <Checkbox
        size="m"
        onClick={onClick}
        checked={checked}
        sx={{ marginRight: '10px' }}
      />
      <Typography
        sx={{
          color: colorPalette.monotone[4],
          fontSize: '14px',
          fontWeight: 400,
        }}
      >
        {title}
      </Typography>
    </TableCell>
  )
}

export default ProgressFilter
