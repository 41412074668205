import { useCallback, useEffect, useState } from 'react'
import api from '../../lib/commons/api'
import { WbsItemAdditionalPropertyRepository } from '../../applications/ports/wbsItemAdditionalPropertyRepository'
import {
  WbsItemTypeObject,
  WbsItemTypeVO,
} from '../../domain/value-object/WbsItemTypeVO'
import { UserBasic } from '../../lib/functions/user'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import {
  EntitySearchReferenceEntity,
  WbsItemAdditionalPropertyType,
} from '../../domain/entity/WbsItemAdditionalPropertyEntity'

export const useWbsItemAdditionalPropertyRepository =
  (): WbsItemAdditionalPropertyRepository => {
    const getByProjectUuid = useCallback(
      async (
        projectUuid: string
      ): Promise<WbsItemAdditionalPropertyLayoutEntity> => {
        const response = await api.functional.request(
          'GET',
          '/api/v1/projects/wbs_item_additional_properties',
          { projectUuid }
        )
        const layout: WbsItemAdditionalPropertysFetchResponse = response.json

        return {
          projectUuid: layout.projectUuid,
          groupHeaderLayouts: layout.groupHeaderLayouts.map(
            ({ groupHeader, propertyLayouts, displayOrder }) => {
              return {
                groupHeader: {
                  uuid: groupHeader.uuid,
                  lockVersion: groupHeader.lockVersion,
                  revision: groupHeader.revision,
                  headerName: groupHeader.headerName,
                  createdAt: groupHeader.createdAt,
                  createdBy: groupHeader.createdBy,
                  updatedAt: groupHeader.updatedAt,
                  updatedBy: groupHeader.updatedBy,
                },
                displayOrder,
                propertyLayouts: propertyLayouts.map(
                  ({ wbsItemAdditionalProperty, displayOrder }) => {
                    return {
                      wbsItemAdditionalProperty: {
                        uuid: wbsItemAdditionalProperty.uuid,
                        lockVersion: wbsItemAdditionalProperty.lockVersion,
                        revision: wbsItemAdditionalProperty.revision,
                        projectUuid: wbsItemAdditionalProperty.projectUuid,
                        propertyName: wbsItemAdditionalProperty.propertyName,
                        propertyType:
                          wbsItemAdditionalProperty.propertyType as WbsItemAdditionalPropertyType,
                        required: wbsItemAdditionalProperty.required,
                        selectOptions: wbsItemAdditionalProperty.selectOptions,
                        entitySearchReferenceEntity:
                          wbsItemAdditionalProperty.entitySearchReferenceEntity as
                            | EntitySearchReferenceEntity
                            | undefined,
                        wbsItemTypes: wbsItemAdditionalProperty.wbsItemTypes
                          ? wbsItemAdditionalProperty.wbsItemTypes.map(
                              v => new WbsItemTypeVO(v)
                            )
                          : undefined,
                        createdAt: wbsItemAdditionalProperty.createdAt,
                        createdBy: wbsItemAdditionalProperty.createdBy,
                        updatedAt: wbsItemAdditionalProperty.updatedAt,
                        updatedBy: wbsItemAdditionalProperty.updatedBy,
                      },
                      displayOrder,
                    }
                  }
                ),
              }
            }
          ),
        }
      },
      []
    )
    return {
      getByProjectUuid,
    }
  }

interface WbsItemAdditionalPropertyObject {
  uuid: string
  lockVersion: number
  revision: string
  projectUuid: string
  propertyName: string
  propertyType: string
  required: boolean
  selectOptions?: {
    uuid: string
    selectOption: string
  }[]
  wbsItemTypes: WbsItemTypeObject[]
  entitySearchReferenceEntity?: string
  createdAt: number
  createdBy?: UserBasic
  updatedAt: number
  updatedBy?: UserBasic
}
interface GroupHeaderObject {
  uuid: string
  lockVersion: number
  revision: string
  headerName: string
  createdAt: number
  createdBy?: UserBasic
  updatedAt: number
  updatedBy?: UserBasic
}
interface WbsItemAdditionalPropertyWithLayoutObject {
  projectUuid: string
  groupHeaderLayouts: {
    groupHeader: GroupHeaderObject
    displayOrder: number
    propertyLayouts: {
      wbsItemAdditionalProperty: WbsItemAdditionalPropertyObject
      displayOrder: number
    }[]
  }[]
}

type WbsItemAdditionalPropertysFetchResponse =
  WbsItemAdditionalPropertyWithLayoutObject
