import Comments from '../../Comment/index'

type DiscussionTabProps = {
  functionUuid: string
  projectUuid: string
  uuid: string
}
export const DiscussionTab = ({
  functionUuid,
  projectUuid,
  uuid,
}: DiscussionTabProps) => {
  return (
    <Comments
      applicationFunctionUuid={functionUuid}
      dataUuid={uuid}
      projectUuid={projectUuid}
    />
  )
}
