import { TicketType } from '../../../../../lib/functions/ticket'
import { FlagxsColumnDef } from '../../../../containers/BulkSheetView/gridOptions/extension'
import { PropsForGetColumnDefs } from './common'
import { useGeneralColumnDefs } from './general'
import { useRefinementColumnDefs } from './refinement'
import { useRiskColumnDefs } from './risk'

export type Props = PropsForGetColumnDefs & {
  ticketType: TicketType | undefined
}

const useColumnDefsFunc = (ticketType: TicketType | undefined) => {
  switch (ticketType) {
    case TicketType.RISK:
      return useRiskColumnDefs
    case TicketType.REFINEMENT_NEW:
      return useRefinementColumnDefs
    default:
      return useGeneralColumnDefs
  }
}

export const useColumnDefs = ({
  projectUuid,
  ticketType,
  onClickedStatusCell,
  onClickedActualHourCell,
  addRow,
  reloadSingleRow,
  submitSingleRow,
  context,
}: Props): FlagxsColumnDef[] => {
  const func = useColumnDefsFunc(ticketType)
  if (!func) return []
  return func({
    projectUuid,
    onClickedStatusCell,
    onClickedActualHourCell,
    addRow,
    reloadSingleRow,
    submitSingleRow,
    context,
  })
}
