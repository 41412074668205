import {
  CellKeyDownEvent,
  GridOptions,
  SuppressKeyboardEventParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import {
  columnTypes,
  defaultOnCellClicked,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import _ from 'lodash'
import objects from '../../../../utils/objects'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import { columnDefs } from './columnDefs'
import { TreeCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer/TreeCellRenderer'
import {
  AdditionalPropertyRow,
  getName,
  GroupHeaderRow,
  isGroupHeaderRow,
} from '../rowModel/wbsItemAdditionalPropertyRow'
import { propertyName } from '../../../page-properties/bulksheet-properties/wbsItemAdditionalProperty'
import { ClientSideTextFilter } from '../../../containers/BulkSheetView/components/filter'

export const WbsItemAdditionalPropertyGridOptions = (): GridOptions => {
  return {
    groupHeaderHeight: 25,
    headerHeight: 45,
    rowHeight: 32,
    treeData: false,
    context: {},
    suppressLastEmptyLineOnPaste: true,
    // Row
    rowDragManaged: false,
    rowDragMultiRow: false,
    suppressMoveWhenRowDragging: true,
    enterMovesDownAfterEdit: true,
    onCellKeyDown: (params: CellKeyDownEvent) => {
      // @ts-ignore
      if (!['Delete', 'Backspace'].includes(params.event.key)) return
      const cellRanges = params.api.getCellRanges() || []
      cellRanges.forEach(range => {
        const start = Math.min(range.startRow!.rowIndex, range.endRow!.rowIndex)
        const end = Math.max(range.startRow!.rowIndex, range.endRow!.rowIndex)
        range.columns.forEach(column => {
          const colDef = column.getColDef()
          if (
            typeof colDef.editable === 'function'
              ? colDef.editable(params)
              : colDef.editable
          ) {
            for (let i = start; i <= end; i++) {
              const rowNode = params.api.getDisplayedRowAtIndex(i)
              rowNode?.setDataValue(column, undefined)
            }
          }
        })
      })
    },
    // Column
    columnTypes: columnTypes(),
    components: frameworkComponents,
    defaultColDef: {
      width: 85,
      editable: false,
      enableValue: false,
      sortable: true,
      resizable: true,
      suppressMenu: true,
      suppressSizeToFit: true,
      singleClickEdit: false,
      cellEditor: 'textEditor',
      cellStyle: { justifyContent: 'left' },
      cellRenderer: DefaultCellRenderer,
      onCellClicked: defaultOnCellClicked,
      suppressPaste: params => !_.isEmpty(params.context?.copied),
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        return (
          !params.editing && ['Delete', 'Backspace'].includes(params.event.key)
        )
      },
      valueSetter: (params: ValueSetterParams) => {
        const field = params.colDef.field || params.colDef.colId
        if (
          !field ||
          (!params.oldValue && !params.newValue) ||
          params.oldValue === params.newValue
        ) {
          return false
        }
        objects.setValue(params.data, field, params.newValue)
        params.data.edited = true
        store.dispatch(requireSave())
        return true
      },
    },
    columnDefs,
    // Row grouping
    // @ts-ignore
    treeData: true,
    excludeChildrenWhenTreeDataFiltering: true,
    groupDefaultExpanded: 1,
    autoGroupColumnDef: {
      headerName: propertyName.headerName,
      width: propertyName.width,
      pinned: true,
      editable: propertyName.editable,
      filter: ClientSideTextFilter,
      floatingFilter: true,
      filterParams: {
        valueGetter: (params: { node; context }) => {
          if (!params.node) {
            return undefined
          }
          if (isGroupHeaderRow(params.node.data)) {
            const groupHeaderRow = params.node.data as GroupHeaderRow
            return groupHeaderRow.headerName
          } else {
            const additionalPropertyRow = params.node
              .data as AdditionalPropertyRow
            return additionalPropertyRow.propertyName
          }
        },
        filterChangedCallback: () => {
          return
        },
      },
      valueGetter: (params: ValueGetterParams) =>
        params.node ? getName(params.node.data) : '',
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true,
        innerRenderer: TreeCellRenderer,
      },
      cellClassRules: {
        'hover-over-can-drop': params => {
          return (
            params.context?.onTree &&
            params.context?.draggableNodeId &&
            params.node?.id === params.context.draggableNodeId
          )
        },
      },
    },
  }
}
