import moment from 'moment'
import DomainError, { DomainErrorCode } from '../../../error/DomainError'
import DomainValueObjectBase from '../Base'
import DateTimeVO from '../DateTimeVO'

export interface DateTimeTerm {
  startDateTime?: Date
  endDateTime?: Date
}

export default class DateTimeTermVO extends DomainValueObjectBase<DateTimeTerm> {
  constructor(_startDateTime?: DateTimeVO, _endDateTime?: DateTimeVO) {
    if (_startDateTime && _endDateTime) {
      if (_startDateTime.isAfter(_endDateTime)) {
        throw new DomainError({
          errorCode: DomainErrorCode.INVALID_VALUE_ERROR,
          message: 'The end date time is past the start date time.',
        })
      }
    }
    super({
      startDateTime: _startDateTime?.toPlainValue(),
      endDateTime: _endDateTime?.toPlainValue(),
    })
  }

  public format(formatter: string = 'YYYY-MM-DD HH:mm:ss') {
    const from = super.toPlainValue().startDateTime
    const fromStr = from && moment(from).format(formatter)
    const to = super.toPlainValue().endDateTime
    const toStr = to && moment(to).format(formatter)
    return { from: fromStr, to: toStr }
  }

  public serialize() {
    const from = super.toPlainValue().startDateTime
    const fromStr = from && from.toISOString()
    const to = super.toPlainValue().endDateTime
    const toStr = to && to.toISOString()
    return { from: fromStr, to: toStr }
  }
}
