import {
  ProcessCellForExportParams,
  GridApi,
  ColumnApi,
  ShouldRowBeSkippedParams,
  ExcelStyle,
  GridOptions,
  ValueFormatterParams,
  ExcelRow,
} from 'ag-grid-community'
import * as XLSX from 'xlsx'
import {
  Component,
  CustomEnumValue,
  FunctionProperty,
  PropertyType,
} from '../../../lib/commons/appFunction'
import { ColumnType, dateValueParser } from '../commons/AgGrid'
import repositories, { EntitySearchValue } from '../meta/repositories'
import { generateUuid } from '../../../utils/uuids'
import { LEVEL_SEPARATOR, SUMMARY_ROW_COUNT_SEPARATOR } from './const'
import moment from 'moment'
import ViewMeta from '../meta/ViewMeta'
import { Tree } from '../../../lib/commons/tree'
import objects from '../../../utils/objects'
import {
  getCustomEnumName,
  getCustomEnumValue,
} from '../../../lib/functions/customEnumValue'
import { intl } from '../../../i18n'
import { normalize } from '../../../utils/multilineText'
import { EntityExtensionValue } from '../meta/entityExtension'
import { format } from '../../../utils/comment'
import DateVO from '../../../vo/DateVO'
import DateTimeVO from '../../../vo/DateTimeVO'
import { logDownloadExcel } from '../../../utils/file'
import WbsItemTypeCell from '../commons/AgGrid/components/cell/custom/wbsItemType'
import TicketTypeCell from '../commons/AgGrid/components/cell/custom/ticketType'
import { BulkSheetContext } from '.'
import { RowData } from './RowDataManager/rowDataManager'
import { CUSTOM_ENUM_NONE } from '../../../lib/commons/customEnum'
import { getLabel } from '../../../lib/commons/i18nLabel'
import { TAG_DELIMITER, TagForWbsItem } from '../../../lib/functions/tag'
import { OnlinePredictionSharp } from '@mui/icons-material'
import { getRowNumber } from '../BulkSheetView/lib/gridApi'

export default class Excel<T extends Tree<T>, R extends RowData> {
  constructor(
    private gridApi: GridApi,
    private columnApi: ColumnApi,
    private viewMeta: ViewMeta,
    private treeProperty?: FunctionProperty
  ) {}

  private defaultExportParams = (targetIds?: string[]) => {
    return {
      columnGroups: true,
      processCellCallback: (cell: ProcessCellForExportParams) => {
        const def = cell.column.getColDef()
        const type = def.type
        const uiMeta = def.cellEditorParams && def.cellEditorParams['uiMeta']
        if (
          uiMeta &&
          this.treeProperty &&
          uiMeta.externalId === this.treeProperty.externalId
        ) {
          let roots: string[] = []
          let node = cell.node
          while (node && node.id && node.level >= 0) {
            const path = this.viewMeta.makeDataPropertyName(this.treeProperty)
            if (!targetIds || targetIds.includes(node.id)) {
              roots.push(objects.getValue(node.data, path))
            }
            node = node.parent
          }
          if (roots.length === 0) {
            return ''
          }
          if (typeof roots[0] === 'object') {
            return cell.value
          }
          if (roots.length === 1) {
            return roots[0]
          }
          return `${LEVEL_SEPARATOR.repeat(roots.length - 1)}${roots[0]}`
        }
        if (!type) return cell.value
        if (
          type === ColumnType.autocomplete ||
          type.includes(ColumnType.autocomplete) ||
          type === ColumnType.wbsItemBasic ||
          type.includes(ColumnType.wbsItemBasic)
        ) {
          return new EntitySearchValue(cell.value).toString()
        }
        if (
          type === ColumnType.multiAutocomplete ||
          type?.includes(ColumnType.multiAutocomplete)
        ) {
          return cell.value
            ? cell.value.map(value => new EntitySearchValue(value).toString())
            : ''
        }
        if (type === ColumnType.select || type.includes(ColumnType.select)) {
          const valuesAllowed = uiMeta.valuesAllowed
          return getCustomEnumName(cell.value, valuesAllowed)
        }
        if (
          type === ColumnType.multiSelect ||
          type.includes(ColumnType.multiSelect)
        ) {
          const valuesAllowed = uiMeta.valuesAllowed
          return cell.value
            ? cell.value
                .map(
                  value => value.name || getCustomEnumName(value, valuesAllowed)
                )
                .join(',')
            : ''
        }
        if (
          type === ColumnType.radioGroup ||
          type.includes(ColumnType.radioGroup)
        ) {
          if (
            cell.value &&
            typeof cell.value === 'string' &&
            cell.value.includes(SUMMARY_ROW_COUNT_SEPARATOR)
          ) {
            // Summary row
            return cell.value
          }
          return cell.value === def.cellEditorParams.radioValue ? 1 : 0
        }
        if (cell.value) {
          if (
            typeof cell.value === 'string' &&
            (type === ColumnType.multiLineText ||
              type.includes(ColumnType.multiLineText))
          ) {
            return normalize(cell.value)
          }
          if (
            type === ColumnType.comment ||
            type.includes(ColumnType.comment)
          ) {
            return format(cell.value)
          }
          if (type === ColumnType.date || type.includes(ColumnType.date)) {
            return new DateVO(cell.value).format('YYYY-MM-DD')
          }
          if (
            type === ColumnType.dateTime ||
            type.includes(ColumnType.dateTime)
          ) {
            return new DateTimeVO(cell.value).format('YYYY-MM-DDTHH:mm:ss')
          }
          if (
            type === ColumnType.wbsItemType ||
            type.includes(ColumnType.wbsItemType)
          ) {
            return WbsItemTypeCell.valueFormatter(
              this.transformParamsFromExportToFormatter(cell)
            )
          }
          if (
            type === ColumnType.ticketType ||
            type.includes(ColumnType.ticketType)
          ) {
            return TicketTypeCell.valueFormatter(
              this.transformParamsFromExportToFormatter(cell)
            )
          }
          if (type === ColumnType.tag || type.includes(ColumnType.tag)) {
            return cell.value.map(v => v.name).join(TAG_DELIMITER)
          }
        }
        if (typeof cell.value === 'object') {
          return ''
        }
        return cell.value
      },
    }
  }

  private transformParamsFromExportToFormatter = (
    params: ProcessCellForExportParams
  ): ValueFormatterParams => {
    return {
      node: params.node!,
      data: params.node!.data,
      colDef: params.column.getColDef(),
      column: params.column,
      api: params.api,
      columnApi: params.columnApi,
      context: params.context,
      value: params.value,
    }
  }

  exportExcel = (
    title: string,
    targetIds?: string[],
    exportColIds?: string[]
  ) => {
    this.gridApi.exportDataAsExcel({
      ...this.defaultExportParams(targetIds),
      columnKeys:
        exportColIds ||
        (this.columnApi.getColumns() || [])
          .map(v => v.getColId())
          .filter(v => v !== 'drag' && v !== 'edit'),
      exportMode: 'xlsx',
      fileName: `${title.replaceAll('.', '_')}_${moment().format(
        'YYYYMMDDHHmmss'
      )}.xlsx`, // Escape "." because of exist user can not open file.
      sheetName: `flagxs_${title.replaceAll('.', '_')}`,
      shouldRowBeSkipped: targetIds
        ? (params: ShouldRowBeSkippedParams): boolean => {
            return !params.node.id || !targetIds.includes(params.node.id)
          }
        : undefined,
      // @ts-ignore
      columnWidth: params => Math.floor(params.column.getActualWidth() * 0.8),
    })
  }

  importExcel = async (
    rawData: Uint8Array,
    root: Tree<T>,
    importNewRow: (row: T, s) => boolean,
    ctx: BulkSheetContext<any, T, R, any>
  ) => {
    const workbook = XLSX.read(rawData, { type: 'array', dense: true })
    const worksheet = workbook.Sheets[workbook.SheetNames[0]]
    const data: object[] = XLSX.utils.sheet_to_json(worksheet)
    const header = data[0]
    const keys = Object.keys(header)
    const entries = Object.entries(header)
    let structuredHeader = {}
    let headerLv1 = ''
    for (let i = 0; i < entries.length; i++) {
      if (!entries[i][0].includes('__EMPTY')) {
        headerLv1 = entries[i][0]
      }
      structuredHeader[entries[i][0]] = headerLv1 + '_' + entries[i][1]
    }
    const mapImport = (properties: FunctionProperty[]) => {
      return Object.fromEntries(
        properties
          .filter(
            property =>
              property.requiredIf.isTrue ||
              property.editableIfC.isTrue ||
              property.editableIfU.isTrue ||
              // Import select and entity search in any case
              property.propertyType === PropertyType.Select ||
              property.propertyType === PropertyType.EntitySearch ||
              // Import for display ticket type
              (property.propertyType === PropertyType.Custom &&
                property.component === Component.TicketType)
          )
          .map(property => {
            if (!property.parentProperty) {
              return [property.name, property]
            }
            const parent = this.viewMeta.functionMeta.properties.byId.get(
              property.parentProperty
            )
            return [parent!.name + '_' + property.name, property]
          })
      )
    }
    const extensionUiMetaByName = mapImport(
      Array.from(this.viewMeta.functionMeta.entityExtensions.byId.values())
    )
    const uiMetaByName = mapImport(this.viewMeta.functionMeta.detail.properties)
    const newTree = {}
    newTree[root.uuid] = root
    let parent = root
    let prev = root
    let level = 0
    for (let i = 1; i < data.length; i++) {
      const row = data[i]
      const child = {
        uuid: generateUuid(),
        lockVersion: 0,
        children: [],
      } as unknown as T
      const extensions: EntityExtensionValue[] = []
      for (let j = 0; j < keys.length; j++) {
        const key = keys[j]
        const column = structuredHeader[key]
        if (!column) {
          continue
        }
        let value = row[key] ? row[key].toString() : undefined
        const extensionUiMeta = extensionUiMetaByName[column]
        const uiMeta = uiMetaByName[column]
        if (!uiMeta && !extensionUiMeta) {
          continue
        }
        if (
          this.treeProperty &&
          uiMeta &&
          uiMeta.externalId === this.treeProperty.externalId
        ) {
          if (value) {
            const xs = value.split(LEVEL_SEPARATOR)
            value = xs[xs.length - 1].trim()
            if (xs.length === 1) {
              // Root
              level = 1
              parent = root
            } else if (xs.length === level) {
              // Stay
            } else if (xs.length - 1 === level) {
              // Down
              level += 1
              parent = prev
            } else if (xs.length < level) {
              // Up
              parent = prev
              for (let i = xs.length - 1; i < level; i++) {
                parent = newTree[parent.parentUuid!]
              }
              level = xs.length
            } else {
              // TODO remove alert dialog
              alert(`行の順番が矛盾しています。対象行=${xs[xs.length - 1]}`)
              return false
            }
          }
        } else {
          value = await this.convert(value, uiMeta || extensionUiMeta, ctx)
        }
        if (!value) continue
        if (extensionUiMeta) {
          extensions.push({ uuid: extensionUiMeta.entityExtensionUuid, value })
        } else {
          // TODO Ui meta property name of wbs item is not data path
          let path = this.viewMeta.makeDataPropertyName(uiMeta)
          objects.setValue(child, path, value)
        }
      }
      child.extensions = extensions
      const prevSibling = parent.children[parent.children.length - 1]
      child.parentUuid = parent.uuid
      child.prevSiblingUuid = prevSibling && prevSibling.uuid
      if (!importNewRow(child, ctx)) {
        return false
      }
      parent.children.push(child)
      newTree[child.uuid] = child
      prev = child
    }
    return true
  }

  private convert = async (
    value,
    uiMeta: FunctionProperty,
    ctx: BulkSheetContext<any, T, R, any>
  ) => {
    if (
      uiMeta.propertyType === PropertyType.EntitySearch ||
      (uiMeta.propertyType === PropertyType.Select && uiMeta.referenceEntity)
    ) {
      if (value) {
        const repository = repositories[uiMeta.referenceEntity!]
        const entities = await repository.searchAll()
        const match = entities.find(v =>
          [v.code, v.displayName, v.name].includes(value)
        )
        if (match) {
          return match
        } else {
          return {
            name: intl.formatMessage({ id: 'commentEditor.writeComment' }),
          }
        }
      }
    } else if (uiMeta.propertyType === PropertyType.MultiSelect) {
      if (!value) return undefined
      const values = value.split(',').filter(v => !!v)
      if (uiMeta.referenceEntity) {
        const repository = repositories[uiMeta.referenceEntity]
        const entities = await repository.searchAll()
        return entities.filter(v =>
          values.some(val => [v.code, v.displayName, v.name].includes(val))
        )
      } else if (uiMeta.valuesAllowed) {
        // TODO Test it
        return uiMeta.valuesAllowed.filter(v =>
          values.some(val => [v.customEnumCode, v.name, v.value].includes(val))
        )
      }
    } else if (
      uiMeta.propertyType === PropertyType.Select &&
      uiMeta.valuesAllowed
    ) {
      return getCustomEnumValue(value, uiMeta.valuesAllowed)
    } else if (uiMeta.propertyType === PropertyType.Checkbox) {
      return value === 'true'
    } else if (uiMeta.propertyType === PropertyType.Date) {
      if (!value) return undefined
      return dateValueParser(value)
    } else if (uiMeta.propertyType === PropertyType.Custom) {
      if (
        uiMeta.component &&
        [Component.ActualResult, Component.Workload].includes(uiMeta.component)
      ) {
        const parsed = Number(value)
        return !Number.isNaN(parsed) ? parsed : value
      }
      if (uiMeta.component && uiMeta.component === Component.TicketType) {
        return value
      }
      if (uiMeta.component && uiMeta.component === Component.Tag) {
        const tags: TagForWbsItem[] = ctx.state.tags
        if (!value || !tags) return undefined
        const fromExcel: string[] = (value as string).split(TAG_DELIMITER)
        return tags.filter(v => fromExcel.includes(v.name))
      }
    } else if (
      ['createdBy', 'createdAt', 'updatedBy', 'updatedAt'].includes(
        this.viewMeta.getPropertyNameSuffix(uiMeta)
      )
    ) {
      return undefined
    } else {
      return value ? value.trim() : null
    }
    return undefined
  }
}

export const excelStyles: ExcelStyle[] = [
  {
    id: 'dateType',
    dataType: 'DateTime',
    numberFormat: {
      format: 'yyyy/mm/dd',
    },
  },
  {
    id: 'dateTimeType',
    dataType: 'DateTime',
    numberFormat: {
      format: 'yyyy/mm/dd hh:MM:ss',
    },
  },
]

export const getSelectionList = (columnApi?: ColumnApi) => {
  return (columnApi?.getColumns() || [])
    .filter(
      column =>
        !['drag', 'edit', 'action', 'ganttChart'].includes(column.getColId())
    )
    .map(column => ({
      value: column.getColId(),
      displayName: columnApi?.getDisplayNameForColumn(column, null),
      defaultChecked:
        column.isVisible() ||
        column.getColDef().cellRendererParams?.uiMeta?.tree ||
        ['wbsItem.code', 'wbsItem.type', 'wbsItem.displayName'].includes(
          column.getColId()
        ),
    }))
}

export const exportExcel = (params: {
  fileNamePrefix: string
  gridOptions: GridOptions
  exportColIds: string[]
  shouldRowBeSkipped?: (params: ShouldRowBeSkippedParams) => boolean
  prependContent?: ExcelRow[]
  getCustomExportValue?: (
    params: ProcessCellForExportParams
  ) => string | undefined
}) => {
  logDownloadExcel()
  params.gridOptions.api?.exportDataAsExcel({
    columnGroups: true,
    columnKeys: params.exportColIds,
    exportMode: 'xlsx',
    fileName: `${params.fileNamePrefix}_${DateTimeVO.now().format(
      'YYYYMMDDHHmmss'
    )}.xlsx`,
    sheetName: `flagxs_${params.fileNamePrefix.replaceAll('.', '_')}`,
    columnWidth: params =>
      Math.floor((params.column?.getActualWidth() ?? 0) * 0.8) || 75,
    shouldRowBeSkipped: params.shouldRowBeSkipped,
    processCellCallback: (cell: ProcessCellForExportParams) => {
      const exportValue = !!params.getCustomExportValue
        ? params.getCustomExportValue(cell)
        : undefined
      if (exportValue) {
        return exportValue
      }
      const def = cell.column.getColDef()
      const type = def.type
      if (type && cell.value) {
        if (type === ColumnType.date || type.includes(ColumnType.date)) {
          return new DateVO(cell.value).format('YYYY-MM-DD')
        }
        if (
          type === ColumnType.dateTime ||
          type.includes(ColumnType.dateTime)
        ) {
          return new DateTimeVO(cell.value).format('YYYY-MM-DDTHH:mm:ss')
        }
        if (
          type === ColumnType.wbsItemType ||
          type.includes(ColumnType.wbsItemType)
        ) {
          return WbsItemTypeCell.valueFormatter({
            value: cell.value,
          } as ValueFormatterParams)
        }
        if (
          type === ColumnType.customEnum ||
          type.includes(ColumnType.customEnum)
        ) {
          const enumCode =
            def.cellRendererParams?.optionKey ??
            def.cellEditorParams?.customEnumCode
          const options: CustomEnumValue[] | undefined = cell.context[enumCode]
          const option = options?.find(v => v.value === cell.value)
          return getLabel(option?.nameI18n) ?? option?.name
        }
        if (
          type === ColumnType.myWbsItemCustomEnum ||
          type.includes(ColumnType.myWbsItemCustomEnum)
        ) {
          const enumCode = def.cellEditorParams?.customEnumCode
          let options: CustomEnumValue[] = []
          cell.context[enumCode].forEach((value, _) =>
            value.forEach(v => options.push(v))
          )
          const option = options?.find(v => v.value === cell.value)
          return getLabel(option?.nameI18n) ?? option?.name
        }
        if (
          typeof type === 'object' &&
          (cell.value?.name || cell.value.displayName)
        ) {
          const name = cell.value.name ?? cell.value.displayName
          return name === CUSTOM_ENUM_NONE ? '' : name
        }
      }
      // TODO Use Ag-Grid column type
      if (cell.column.getColId() === 'body.wbsItem.type') {
        return cell.value?.name
      }
      if (cell.column.getColId() === 'body.wbsItem.ticketType') {
        return cell.value?.isTicket() ? cell.value.name : undefined
      }
      if (
        ['body.wbsItem.displayName', 'wbsItem.displayName'].includes(
          cell.column.getColId()
        )
      ) {
        return `${LEVEL_SEPARATOR.repeat(
          cell.node?.uiLevel || cell.node?.level || 0
        )}${cell.value}`
      }
      if (cell.column.getColId() === 'body.wbsItem.watchers') {
        return cell.value?.map(v => v.name)?.join(',')
      }
      if (cell.column.getColId() === 'body.commentSummary.latestComment') {
        return cell.value?.text
      }
      if (cell.column.getColId() === 'body.wbsItem.tags') {
        return cell.value?.map(v => v.name)?.join(TAG_DELIMITER)
      }
      if (cell.column.getColId() === 'rowNumber') {
        return getRowNumber(cell.node)
      }
      return cell.value
    },
    prependContent: params.prependContent,
  })
}
