import {
  ToggleButtonGroup,
  ToggleButton,
  useOnChangeToggleButtonGroup,
} from '.'
import {
  AggregateTarget,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { intl } from '../../../../i18n'

type AggregateTargetToggleButtonGroupProps = {
  value: AggregateTarget
  onChange: (_: AggregateTarget) => void
}

export const AggregateTargetToggleButtonGroup = ({
  value,
  onChange: _onChange,
}: AggregateTargetToggleButtonGroupProps) => {
  const [onChange] = useOnChangeToggleButtonGroup(_onChange)
  return (
    <ToggleButtonGroup value={value} onChange={onChange}>
      <ToggleButton value={WbsItemType.DELIVERABLE}>
        {intl.formatMessage({
          id: 'wbsItemType.deliverable',
        })}
      </ToggleButton>
      <ToggleButton value={WbsItemType.TASK}>
        {intl.formatMessage({
          id: 'wbsItemType.task',
        })}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
