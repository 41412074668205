import { ButtonProps as MuiButtonProps, styled } from '@mui/material'
import { PropsWithChildren, useMemo } from 'react'
import { colorPalette } from '../../style/colorPallete'

type ButtonSize = 's' | 'm' | 'l'
const getSizeStyle = (size: ButtonSize) => {
  switch (size) {
    case 's':
      return {
        height: '28px',
        padding: '6px 8px',
        fontSize: '12px',
        fontWeight: 400,
      }
    case 'm':
      return {
        height: '32px',
        padding: '8px 12px',
        fontSize: '12px',
        fontWeight: 400,
      }
    case 'l':
      return {
        height: '44px',
        padding: '12px 24px',
        fontSize: '14px',
        fontWeight: 600,
      }
  }
}
type ColorPattern = 'skyBlue' | 'monotone' | 'pink' | 'green'
type ButtonVariant = 'filled' | 'outlined'
const getColorStyle = (
  color: ColorPattern,
  variant: ButtonVariant,
  disabled: boolean
) => {
  switch (variant) {
    case 'filled':
      if (disabled) {
        return {
          border: 'none',
          backgroundColor: colorPalette.monotone[2],
          color: `${colorPalette.monotone[4]} !important`,
        }
      }
      switch (color) {
        case 'skyBlue':
          return {
            border: 'none',
            backgroundColor: colorPalette.skyBlue[7],
            color: colorPalette.monotone[0],
            '&:hover': {
              backgroundColor: colorPalette.skyBlue[5],
            },
          }
        case 'green':
          return {
            backgroundColor: colorPalette.green[6],
            color: colorPalette.monotone[0],
            '&:hover': {
              backgroundColor: colorPalette.green[4],
            },
          }
      }
      return {}
    case 'outlined':
      if (disabled) {
        return {
          border: `1px solid ${colorPalette.monotone[2]}`,
          backgroundColor: colorPalette.monotone[1],
          color: colorPalette.monotone[2],
        }
      }
      switch (color) {
        case 'monotone':
          return {
            border: `1px solid ${colorPalette.monotone[2]}`,
            backgroundColor: colorPalette.monotone[0],
            color: colorPalette.monotone[4],
            '&:hover': {
              border: `1px solid ${colorPalette.monotone[4]}`,
            },
          }
        case 'skyBlue':
          return {
            background: colorPalette.monotone[0],
            border: `1px solid ${colorPalette.skyBlue[7]}`,
            color: colorPalette.skyBlue[7],
            '&:hover': {
              background: colorPalette.monotone[0],
              border: `1px solid ${colorPalette.skyBlue[4]}`,
              color: colorPalette.skyBlue[4],
            },
          }
        case 'pink':
          return {
            border: `1px solid ${colorPalette.pink[6]}`,
            backgroundColor: colorPalette.monotone[0],
            color: colorPalette.pink[6],
            '&:hover': {
              border: `1px solid ${colorPalette.pink[3]}`,
              color: colorPalette.pink[3],
            },
          }
      }
  }
}

type ButtonProps = PropsWithChildren<
  {
    disabled?: boolean
    size?: ButtonSize
    colorPattern?: ColorPattern
    variant?: ButtonVariant
  } & Omit<MuiButtonProps, 'disabled' | 'size' | 'variant'>
>

export const Button = styled('button')<ButtonProps>(
  ({
    disabled = false,
    size = 'm',
    colorPattern = 'skyBlue',
    variant = 'filled',
  }) => {
    const sizeStyle = useMemo(() => getSizeStyle(size), [size])
    const colorStyle = useMemo(
      () => getColorStyle(colorPattern, variant, disabled),
      [colorPattern, variant, disabled]
    )
    const style = useMemo(
      () => ({
        borderRadius: '4px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
        ...sizeStyle,
        ...colorStyle,
      }),
      [sizeStyle, colorStyle]
    )
    return style
  }
)
