import MultilineTextEditor from '../../../components/editors/multiline-text/MultilineTextEditor'
import { Alert, Box, styled } from '@mui/material'
import { muiTheme } from '../../../../styles/muiTheme'
import { useEffect, useMemo, useState } from 'react'

type MultilineTextEditorTabProps = {
  uuid: string
  externalId: string
  value: string | undefined
  onChange: (value: string) => void
}

export const MultilineTextEditorTab = ({
  externalId,
  value: _value,
  uuid,
  onChange,
}: MultilineTextEditorTabProps) => {
  const value = useMemo(() => _value || '', [_value])
  return (
    <TabRoot>
      <MultilineTextEditor
        dataUuid={uuid}
        externalId={externalId}
        value={value}
        setValue={onChange}
      />
    </TabRoot>
  )
}

const theme = muiTheme
const TabRoot = styled('div')({
  height: '100%',
  width: 'auto',
  overflow: 'hidden',
  opacity: 1,
  borderRadius: 5,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  position: 'relative',
  zIndex: theme.zIndex.drawer - 1,
  background: theme.palette.background.default,
  margin: '0 5px',
})
