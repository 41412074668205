import { useCallback, useEffect, useState } from 'react'
import { SearchConditionProps } from '../../search-components'
import { customEnumRepository } from '../../../../../domain/repository'
import CodeVO from '../../../../../domain/value-object/CodeVO'
import { intl } from '../../../../../i18n'
import { CUSTOM_ENUM_NONE } from '../../../../../lib/commons/customEnum'
import { SelectOption } from '../../../../model/selectOption'
import { CheckboxSelectConditionItemLabel } from '../CheckboxSelectConditionItemLabel'
import { useProjectPrivateContext } from '../../../../context/projectContext'
import { WbsItemPriorityCheckboxSelectConditionProps } from '../../search-components/wbs-item-search-components/WbsItemPriorityCheckboxSelectCondition'

type WbsItemPriorityCheckboxSelectConditionItemLabelProps = Omit<
  WbsItemPriorityCheckboxSelectConditionProps,
  'colSpan' | 'onEnter'
> & {
  search: () => void
}

export const WbsItemPriorityCheckboxSelectConditionItemLabel = ({
  value,
  onChange,
  ...other
}: WbsItemPriorityCheckboxSelectConditionItemLabelProps) => {
  const { projectUuid } = useProjectPrivateContext()
  const [options, setOptions] = useState<SelectOption[]>([])
  useEffect(() => {
    if (!projectUuid || !value || value.length === 0) return
    const getAndSetOptions = async () => {
      const customEnum = await customEnumRepository.getByCodeAndGroupKeys(
        new CodeVO('WBS_PRIORITY'),
        [projectUuid]
      )
      const values = customEnum.values.map(v => v.toPlainValue())
      if (!values.some(v => v.value === CUSTOM_ENUM_NONE)) {
        values.push({
          name: intl.formatMessage({
            id: 'none',
          }),
          value: CUSTOM_ENUM_NONE,
          foregroundColor: undefined,
          backgroundColor: undefined,
          iconUrl: undefined,
        })
      }
      setOptions(values)
    }
    getAndSetOptions()
  }, [projectUuid, value])
  return (
    <CheckboxSelectConditionItemLabel
      {...other}
      header={intl.formatMessage({
        id: 'wbsItemSearchFilterPanel.priority',
      })}
      value={value}
      onChange={onChange}
      options={options}
    />
  )
}
