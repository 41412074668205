import React from 'react'
import ExclusiveToggleGroup from '../../components/editors/toggle/ExclusiveToggleGroup'
import { intl } from '../../../i18n'
import {
  AggregateTarget,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'

export const AggregateTargetToggleGroup = (props: {
  value: AggregateTarget
  onChange: (value: AggregateTarget) => void
  height?: number
}) => {
  return (
    <ExclusiveToggleGroup
      value={props.value}
      options={[
        {
          label: intl.formatMessage({
            id: 'wbsItemType.deliverable',
          }),
          value: WbsItemType.DELIVERABLE,
        },
        {
          label: intl.formatMessage({
            id: 'wbsItemType.task',
          }),
          value: WbsItemType.TASK,
        },
      ]}
      onChange={value =>
        props.onChange(value as WbsItemType.DELIVERABLE | WbsItemType.TASK)
      }
      height={props.height}
    />
  )
}
