import { useMemo } from 'react'
import { intl } from '../../../i18n'
import { Comment } from '../../../store/comments'
import CommentCell from '../../containers/commons/AgGrid/components/cell/custom/comment'
import CommentCellFilter from '../../containers/commons/AgGrid/components/cell/custom/comment/CommentCellFilter'
import { BulkSheetProperty } from './bulksheetProperty'

export const useLatestComment = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: 'wbsItem.property.latestComment' }),
      hide: true,
      cellRenderer: CommentCell.cellRenderer,
      filter: CommentCellFilter,
      floatingFilter: true,
      comparator: (
        valueA: Comment | undefined,
        valueB: Comment | undefined
      ) => {
        const valA: number = valueA?.createdAt ?? 0
        const valB: number = valueB?.createdAt ?? 0
        return valA - valB
      },
    }),
    [field]
  )
  return property
}
