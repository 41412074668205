import {
  Box,
  Button as MuiButton,
  ClickAwayListener,
  FormControlLabel,
  Popper,
  Typography,
} from '@mui/material'
import { intl } from '../../../../../i18n'
import { colorPalette } from '../../../../style/colorPallete'
import { useRef, useState } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { connect } from 'react-redux'
import store, { AllState } from '../../../../../store'
import {
  DisplayRange,
  GanttDisplayUnit,
  GanttParameterVO,
} from '../../../../../domain/value-object/GanttParameterVO'
import { changeGanttParameter } from '../../../../../store/project'
import { DayOfWeek } from '../../../../../lib/functions/report'
import DateSelect, {
  DateSelectType,
} from '../../../../components/toolbars/common/DateSelect'
import DateVO from '../../../../../vo/DateVO'
import { SwitchButton } from './SwitchButton'
import { Button } from '../../../../components/buttons'

type Props = {
  onSwitchingGanttChart: (value: boolean) => void
  value: boolean
  parameter: GanttParameterVO | undefined
  projectUuid?: string
}
const GanttChartParameter = ({
  onSwitchingGanttChart,
  value,
  parameter,
  projectUuid,
}: Props) => {
  const [openGanttChartSettingPopper, setOpenGanttChartSettingPopper] =
    useState<boolean>(false)
  const [settingAnchorEl, setSettingAnchorEl] = useState<Element>()
  const [openUnitPopper, setOpenUnitPopper] = useState<boolean>(false)
  const [unitAnchorEl, setUnitAnchorEl] = useState<Element>()
  const [openStartDayPopper, setOpenStartDayPopper] = useState<boolean>(false)
  const [startDayAnchorEl, setStartDayAnchorEl] = useState<Element>()

  const ganttParameter = parameter ?? GanttParameterVO.defaultValue()
  const [unit, setUnit] = useState<GanttDisplayUnit>(ganttParameter.unit)
  const [startDay, setStartDay] = useState<DayOfWeek>(ganttParameter.startDay)
  const [range, setRange] = useState<DisplayRange>(ganttParameter.range)

  const openMenuIconRef = useRef<HTMLSpanElement>(null)

  const handleGanttChartSettingClose = () => {
    setSettingAnchorEl(undefined)
    setOpenGanttChartSettingPopper(false)
    setOpenUnitPopper(false)
    setOpenStartDayPopper(false)
  }

  const handleGanttChartSettingApply = () => {
    if (openGanttChartSettingPopper) {
      store.dispatch(
        changeGanttParameter(
          projectUuid!,
          new GanttParameterVO(unit, startDay, range)
        )
      )
    }
    setSettingAnchorEl(undefined)
    setOpenGanttChartSettingPopper(false)
    setOpenUnitPopper(false)
    setOpenStartDayPopper(false)
  }

  const handleUnitClose = () => {
    setUnitAnchorEl(undefined)
    setOpenUnitPopper(false)
  }

  const handleStartDayClose = () => {
    setStartDayAnchorEl(undefined)
    setOpenStartDayPopper(false)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '10px',
        minWidth: 'fit-content',
      }}
    >
      <Typography sx={{ color: colorPalette.monotone[5] }}>
        {intl.formatMessage({ id: 'wbs.header.gantt.title' })}
      </Typography>
      <FormControlLabel
        sx={{ marginRight: 0 }}
        control={<SwitchButton checked={value} />}
        label={undefined}
        checked={value}
        onChange={() => {
          onSwitchingGanttChart(value ? false : true)
        }}
      />
      <ClickAwayListener onClickAway={handleGanttChartSettingClose}>
        <div>
          <span ref={openMenuIconRef}>
            {openGanttChartSettingPopper ? (
              <ExpandLess
                sx={{
                  margin: '0 5px 0 auto',
                  height: '1em',
                  width: '1em',
                  color: colorPalette.monotone[4],
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSettingAnchorEl(undefined)
                  setOpenGanttChartSettingPopper(false)
                }}
              />
            ) : (
              <ExpandMore
                sx={{
                  margin: '0 5px 0 auto',
                  height: '1em',
                  width: '1em',
                  color: colorPalette.monotone[4],
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSettingAnchorEl(openMenuIconRef.current || undefined)
                  setOpenGanttChartSettingPopper(true)
                }}
              />
            )}
          </span>
          <Popper
            open={!!settingAnchorEl}
            anchorEl={settingAnchorEl}
            sx={{
              width: 'fit-content',
              height: 'fit-content',
              background: colorPalette.monotone[0],
              borderRadius: '4px',
              boxShadow: '0px 1px 2px #0000004D',
              padding: '20px',
              zIndex: 1300,
            }}
            placement={'bottom'}
            modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: '15px',
                  }}
                >
                  <Typography
                    sx={{
                      color: colorPalette.monotone[5],
                      fontSize: '12px',
                      fontWeight: 500,
                      margin: '0 auto 5px 0',
                    }}
                  >
                    {intl.formatMessage({ id: 'wbs.header.gantt.unit' })}
                  </Typography>
                  <ClickAwayListener onClickAway={handleUnitClose}>
                    <div>
                      <GanttSettingPulldown
                        onClick={event => {
                          if (openUnitPopper) {
                            setUnitAnchorEl(undefined)
                            setOpenUnitPopper(false)
                          } else {
                            setUnitAnchorEl(event.currentTarget)
                            setOpenUnitPopper(true)
                          }
                        }}
                        openUnitPopper={openUnitPopper}
                        label={intl.formatMessage({
                          id: `gantt.displayUnit.${unit.toLowerCase()}`,
                        })}
                        disabled={!value ? true : false}
                      />
                      <Popper
                        open={openUnitPopper}
                        anchorEl={unitAnchorEl}
                        sx={{
                          width: '120px',
                          height: 'fit-content',
                          background: colorPalette.monotone[0],
                          borderRadius: '4px',
                          boxShadow: '0px 1px 2px #0000004D',
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '10px 15px',
                          zIndex: 1300,
                        }}
                        placement={'bottom-end'}
                        modifiers={[
                          { name: 'offset', options: { offset: [0, 5] } },
                        ]}
                      >
                        <ConditionButton
                          selected={unit}
                          options={Object.values(GanttDisplayUnit).map(v => {
                            return {
                              onClick: () => setUnit(v),
                              unit: v,
                              label: intl.formatMessage({
                                id: `gantt.displayUnit.${v.toLowerCase()}`,
                              }),
                            }
                          })}
                        />
                      </Popper>
                    </div>
                  </ClickAwayListener>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: colorPalette.monotone[5],
                      fontSize: '12px',
                      fontWeight: 500,
                      margin: '0 auto 5px 0',
                    }}
                  >
                    {intl.formatMessage({ id: 'wbs.header.gantt.startDay' })}
                  </Typography>
                  <ClickAwayListener onClickAway={handleStartDayClose}>
                    <div>
                      <GanttSettingPulldown
                        onClick={event => {
                          if (openStartDayPopper) {
                            setStartDayAnchorEl(undefined)
                            setOpenStartDayPopper(false)
                          } else {
                            setStartDayAnchorEl(event.currentTarget)
                            setOpenStartDayPopper(true)
                          }
                        }}
                        openUnitPopper={openStartDayPopper}
                        label={intl.formatMessage({
                          id: `dayOfWeek.${startDay.toLowerCase()}`,
                        })}
                        disabled={
                          unit === GanttDisplayUnit.DAY
                            ? true
                            : false || !value
                            ? true
                            : false
                        }
                      />
                      <Popper
                        open={openStartDayPopper}
                        anchorEl={startDayAnchorEl}
                        sx={{
                          width: '120px',
                          height: 'fit-content',
                          background: colorPalette.monotone[0],
                          borderRadius: '4px',
                          boxShadow: '0px 1px 2px #0000004D',
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '10px 15px',
                          zIndex: 1300,
                        }}
                        placement={'bottom-end'}
                        modifiers={[
                          { name: 'offset', options: { offset: [0, 5] } },
                        ]}
                      >
                        <ConditionButton
                          selected={startDay}
                          options={Object.values(DayOfWeek).map(v => {
                            return {
                              onClick: () => setStartDay(v),
                              unit: v,
                              label: intl.formatMessage({
                                id: `dayOfWeek.${v.toLowerCase()}`,
                              }),
                            }
                          })}
                        />
                      </Popper>
                    </div>
                  </ClickAwayListener>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '20px',
                  width: 'max-content',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    color: colorPalette.monotone[5],
                    fontSize: '12px',
                    fontWeight: 500,
                    margin: '0 auto 5px 0',
                  }}
                >
                  {intl.formatMessage({
                    id: 'wbs.header.gantt.displayRange',
                  })}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      marginRight: '10px',
                      width: '120px',
                      heigth: '30px',
                    }}
                  >
                    <DateSelect
                      type={DateSelectType.DATE}
                      value={range.start}
                      onChange={(value?: string) => {
                        if (!value) return
                        const date = new DateVO(value)
                        setRange({
                          start: date,
                          end: range.end.isBefore(date) ? date : range.end,
                        })
                      }}
                      sx={{ marginRight: '10px' }}
                      disabled={!value ? true : false}
                    />
                  </Box>
                  <Box
                    sx={{
                      marginRight: '10px',
                      width: '120px',
                      heigth: '30px',
                    }}
                  >
                    <DateSelect
                      type={DateSelectType.DATE}
                      value={range.end}
                      onChange={(value?: string) => {
                        if (!value) return
                        const date = new DateVO(value)
                        setRange({
                          start: range.start.isAfter(date) ? date : range.start,
                          end: date,
                        })
                      }}
                      disabled={!value ? true : false}
                    />
                  </Box>
                  <Button
                    sx={{ marginLeft: '5px' }}
                    onClick={handleGanttChartSettingApply}
                    disabled={!value ? true : false}
                  >
                    <Typography>
                      {intl.formatMessage({ id: 'wbs.header.gantt.apply' })}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Popper>
        </div>
      </ClickAwayListener>
    </Box>
  )
}

type ConditionProps = {
  selected: GanttDisplayUnit | DayOfWeek
  options?: {
    onClick: () => void
    unit: GanttDisplayUnit | DayOfWeek
    label: string
  }[]
}

const ConditionButton = ({ selected, options }: ConditionProps) => {
  return (
    <div>
      {options &&
        options.map((option, i) => (
          <MuiButton
            key={i}
            onClick={option.onClick}
            sx={{
              background: () =>
                selected === option.unit
                  ? colorPalette.skyBlue[0]
                  : 'transparent',
              justifyContent: 'start',
              color: colorPalette.monotone[4],
              '&:hover': {
                background: () =>
                  selected === option.unit
                    ? colorPalette.skyBlue[0]
                    : 'transparent',
              },
            }}
          >
            <Typography>{option.label}</Typography>
          </MuiButton>
        ))}
    </div>
  )
}

type PulldownProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
  openUnitPopper: boolean
  label?: string
  disabled?: boolean
}
const GanttSettingPulldown = ({
  onClick,
  openUnitPopper,
  label,
  disabled,
}: PulldownProps) => {
  return (
    <MuiButton
      sx={{
        border: `1px solid ${colorPalette.monotone[2]}`,
        background: colorPalette.monotone[0],
        '&:hover': { background: colorPalette.monotone[0] },
        color: colorPalette.monotone[4],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '160px',
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <Typography
        sx={{
          margin: '0 auto 0 10px',
          fontSize: 12,
        }}
      >
        {label}
      </Typography>
      {openUnitPopper ? (
        <ExpandLess
          sx={{
            margin: '0 5px 0 auto',
            height: '13px',
            width: '13px',
          }}
        />
      ) : (
        <ExpandMore
          sx={{
            margin: '0 5px 0 auto',
            height: '13px',
            width: '13px',
          }}
        />
      )}
    </MuiButton>
  )
}

const mapStateToProps = (state: AllState) => ({
  projectUuid: state.project.selected,
  parameter: state.project.ganttParameter,
})

export default connect(mapStateToProps)(GanttChartParameter)
