import { useCallback, useEffect, useState } from 'react'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../../lib/commons/uiStates'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'
import { WorkloadUnit } from '../../../../lib/functions/workload'
import { DisplayUnit } from '../../../containers/BulkSheetView/components/header/datePicker/TermSelector'
import { DISPLAY_DATE_SHORT_FORMAT_WITH_DAY } from '../../../../utils/date'

type StoredMyWbsItemsPageState = {
  workloadUnit: WorkloadUnit
  rowHeight: ROW_HEIGHT
  displayUnit: DisplayUnit
  dateFormat: string
}

export const usePageState = (
  functionUuid
): {
  initialized: boolean
  workloadUnit: WorkloadUnit
  setWorkloadUnit: (_: WorkloadUnit) => void
  rowHeight: ROW_HEIGHT
  setRowHeight: (_: ROW_HEIGHT) => void
  displayUnit: DisplayUnit
  setDisplayUnit: (_: DisplayUnit) => void
  dateFormat: string
  setDateFormat: (_: string) => void
} => {
  const [workloadUnit, setWorkloadUnit] = useState<WorkloadUnit>(
    WorkloadUnit.HOUR
  )
  const [rowHeight, setRowHeight] = useState<ROW_HEIGHT>(ROW_HEIGHT.SMALL)
  const [displayUnit, setDisplayUnit] = useState<DisplayUnit>(DisplayUnit.WEEK)
  const [initialized, setInitialized] = useState(false)
  const [dateFormat, setDateFormat] = useState(
    DISPLAY_DATE_SHORT_FORMAT_WITH_DAY
  )
  const restorePageState = useCallback(async () => {
    const response = await uiStates.get({
      applicationFunctionUuid: functionUuid,
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
    })
    const stored = response.json
    try {
      if (!stored.value) {
        return
      }
      const payload: StoredMyWbsItemsPageState = JSON.parse(stored.value)
      payload.workloadUnit && setWorkloadUnit(payload.workloadUnit)
      payload.rowHeight && setRowHeight(payload.rowHeight)
      payload.displayUnit && setDisplayUnit(payload.displayUnit)
      payload.dateFormat && setDateFormat(payload.dateFormat)
    } finally {
      setInitialized(true)
    }
  }, [functionUuid])

  const rememberPageState = useCallback((value: StoredMyWbsItemsPageState) => {
    uiStates.update(
      {
        key: UiStateKey.PageState,
        scope: UiStateScope.User,
        value: JSON.stringify(value),
      },
      functionUuid
    )
  }, [])

  useEffect(() => {
    restorePageState()
  }, [])

  useEffect(() => {
    if (!initialized) return
    rememberPageState({
      workloadUnit,
      rowHeight,
      displayUnit,
      dateFormat,
    })
  }, [initialized, workloadUnit, rowHeight, displayUnit, dateFormat])

  return {
    initialized,
    workloadUnit,
    setWorkloadUnit,
    rowHeight,
    setRowHeight,
    displayUnit,
    setDisplayUnit,
    dateFormat,
    setDateFormat,
  }
}
