import { useCallback, useMemo } from 'react'
import { FocusableSearchItemLabelProps, SearchItemLabel, usePopup } from '.'
import {
  DateTimeTermCondition,
  DateTimeTermConditionProps,
} from '../search-components/DateTimeTermCondition'
import { Popover } from '@mui/material'

type DateTimeTermConditionItemLabelProps =
  FocusableSearchItemLabelProps<DateTimeTermConditionProps>

export const DateTimeTermConditionItemLabel = ({
  value,
  header,
  onChange,
  search,
}: DateTimeTermConditionItemLabelProps) => {
  const { ref, open, openPopup, closePopup } = usePopup()

  const disabled = useMemo(() => {
    if (!value) return true
    const plainValue = value.toPlainValue()
    return !plainValue.startDateTime && !plainValue.endDateTime
  }, [value])

  const valueString = useMemo(() => {
    if (!value) return undefined
    value.format()
    const formattedValue = value.format()
    return `${formattedValue.from || ''}~${formattedValue.to || ''}`
  }, [value])

  const onClear = useCallback(() => {
    onChange(undefined)
  }, [])

  const onSearch = useCallback(() => {
    closePopup()
    search()
  }, [])

  if (disabled) return <></>
  return (
    <>
      <SearchItemLabel
        ref={ref}
        active={open}
        onClick={openPopup}
        onClear={onClear}
      >
        {header}: {valueString}
      </SearchItemLabel>
      <Popover
        open={open}
        anchorEl={ref.current || null}
        onClose={onSearch}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <DateTimeTermCondition
          header={header}
          colSpan={10}
          value={value}
          onChange={onChange}
          onEnter={onSearch}
        />
      </Popover>
    </>
  )
}
