import { useSelector } from 'react-redux'
import { AllState } from '../../store'
import { WorkloadUnit } from '../../lib/functions/workload'
import { useCallback, useEffect, useState } from 'react'
import { OrganizationDetail } from '../../lib/functions/tenant'

export type WorkLoadUnitState = {
  hoursPerSelectedUnit: number
  dailyWorkHours: number
  monthlyWorkDays: number
  convertWorkloadFromHourToSelectedUnit: (hour: number) => number
}

export const getHoursPerWorkloadUnit = (
  workloadUnit: WorkloadUnit,
  {
    dailyWorkHours,
    monthlyWorkDays,
  }: {
    dailyWorkHours: number
    monthlyWorkDays: number
  }
) => {
  switch (workloadUnit) {
    case WorkloadUnit.MONTH:
      return monthlyWorkDays * dailyWorkHours
    case WorkloadUnit.DAY:
      return dailyWorkHours
    case WorkloadUnit.HOUR:
      return 1
    default:
      return 1
  }
}

/** @deprecated You can not use this method in others. */
export const getWorkloadUnitStateForSprintReport = (
  workloadUnit: WorkloadUnit,
  { dailyWorkHours, monthlyWorkDays }: OrganizationDetail
) => {
  const hoursPerSelectedUnit = getHoursPerWorkloadUnit(workloadUnit, {
    dailyWorkHours,
    monthlyWorkDays,
  })

  return {
    hoursPerSelectedUnit,
    dailyWorkHours,
    monthlyWorkDays,
    convertWorkloadFromHourToSelectedUnit: (hour: number) =>
      hour / hoursPerSelectedUnit,
  }
}

export const useWorkloadUnit = (
  workloadUnit: WorkloadUnit // TODO I want to manage the workloadUnit in this hooks as a state.
): WorkLoadUnitState => {
  const { dailyWorkHours, monthlyWorkDays } = useSelector(
    (state: AllState) => ({
      dailyWorkHours: state.tenant.organization?.dailyWorkHours || 0,
      monthlyWorkDays: state.tenant.organization?.monthlyWorkDays || 0,
    })
  )
  const [hoursPerSelectedUnit, setHoursPerSelectedUnit] = useState(1)
  useEffect(() => {
    setHoursPerSelectedUnit(
      getHoursPerWorkloadUnit(workloadUnit, { dailyWorkHours, monthlyWorkDays })
    )
  }, [workloadUnit, dailyWorkHours, monthlyWorkDays])

  const convertWorkloadFromHourToSelectedUnit = useCallback(
    (hour: number) => hour / hoursPerSelectedUnit,
    [hoursPerSelectedUnit]
  )

  return {
    hoursPerSelectedUnit,
    dailyWorkHours,
    monthlyWorkDays,
    convertWorkloadFromHourToSelectedUnit,
  }
}
