import {
  EntitySearchReferenceEntity,
  WbsItemAdditionalPropertySelectOption,
  WbsItemAdditionalPropertyType,
} from '../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import { generateUuid } from '../../../../utils/uuids'
import { TreeRow } from '../../../containers/BulkSheetView/model'

/**
 * RowData definition
 */
export interface GroupHeaderRow extends TreeRow {
  projectUuid: string
  headerName: string
}
export interface AdditionalPropertyRow extends TreeRow {
  projectUuid: string
  wbsItemTypes: WbsItemTypeVO[]
  propertyName: string
  propertyType: WbsItemAdditionalPropertyType
  required: boolean
  selectOptions?: WbsItemAdditionalPropertySelectOption[]
  entitySearchReferenceEntity?: EntitySearchReferenceEntity
}
export type WbsItemAdditionalPropertyRow =
  | GroupHeaderRow
  | AdditionalPropertyRow

/**
 * Row data functionality
 */
export const isGroupHeaderRow = (
  rowData: WbsItemAdditionalPropertyRow
): boolean => rowData.treeValue.length === 1
export const isAdditionalPropertyRow = (
  rowData: WbsItemAdditionalPropertyRow
): boolean => rowData.treeValue.length === 2
export const getName = (rowData: WbsItemAdditionalPropertyRow): string => {
  if (isGroupHeaderRow(rowData)) {
    return (rowData as GroupHeaderRow).headerName
  }
  return (rowData as AdditionalPropertyRow).propertyName
}
export const createNewGroupHeaderRow = (
  projectUuid: string
): GroupHeaderRow => {
  const uuid = generateUuid()
  return {
    added: true,
    edited: true,
    treeValue: [uuid],
    uuid,
    projectUuid,
    headerName: '',
  }
}
export const createNewAdditionalPropertyRow = (
  projectUuid: string,
  groupHeaderUuid: string
): WbsItemAdditionalPropertyRow => {
  const uuid = generateUuid()
  return {
    added: true,
    edited: true,
    treeValue: [groupHeaderUuid, uuid],
    uuid,
    projectUuid,
    wbsItemTypes: [],
    propertyName: '',
    propertyType: WbsItemAdditionalPropertyType.TEXT,
    required: false,
    selectOptions: undefined,
    entitySearchReferenceEntity: undefined,
  }
}
export const entityToRow = (
  projectUuid: string,
  entity: WbsItemAdditionalPropertyLayoutEntity
): WbsItemAdditionalPropertyRow[] => {
  const rows: WbsItemAdditionalPropertyRow[] = []
  entity.groupHeaderLayouts.map(({ groupHeader, propertyLayouts }) => {
    // Add a group header row.
    rows.push({
      treeValue: [groupHeader.uuid],
      uuid: groupHeader.uuid,
      projectUuid,
      headerName: groupHeader.headerName,
      revision: groupHeader.revision,
      createdAt: groupHeader.createdAt,
      createdBy: groupHeader.createdBy,
      updatedAt: groupHeader.updatedAt,
      updatedBy: groupHeader.updatedBy,
    })

    propertyLayouts.forEach(({ wbsItemAdditionalProperty }) => {
      // Add a additional property row.
      rows.push({
        treeValue: [groupHeader.uuid, wbsItemAdditionalProperty.uuid],
        uuid: wbsItemAdditionalProperty.uuid,
        projectUuid,
        wbsItemTypes: wbsItemAdditionalProperty.wbsItemTypes || [],
        propertyName: wbsItemAdditionalProperty.propertyName,
        propertyType: wbsItemAdditionalProperty.propertyType,
        required: wbsItemAdditionalProperty.required,
        selectOptions: wbsItemAdditionalProperty.selectOptions,
        entitySearchReferenceEntity:
          wbsItemAdditionalProperty.entitySearchReferenceEntity,
        revision: wbsItemAdditionalProperty.revision,
        createdAt: wbsItemAdditionalProperty.createdAt,
        createdBy: wbsItemAdditionalProperty.createdBy,
        updatedAt: wbsItemAdditionalProperty.updatedAt,
        updatedBy: wbsItemAdditionalProperty.updatedBy,
      })
    })
  })
  return rows
}
