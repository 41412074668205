import {
  ColDef,
  EditableCallbackParams,
  CellClassParams,
} from 'ag-grid-community'
import { ClientSideSelectFilter } from '../../../../containers/BulkSheetView/components/filter'
import { propertyType } from '../../../../page-properties/bulksheet-properties/wbsItemAdditionalProperty'
import {
  isAdditionalPropertyRow,
  isGroupHeaderRow,
  AdditionalPropertyRow,
} from '../../rowModel/wbsItemAdditionalPropertyRow'
import { DISABLE_COLOR } from './style'

export const propertyTypeColumnDef: ColDef = {
  ...propertyType,
  editable: (params: EditableCallbackParams) =>
    isAdditionalPropertyRow(params.node.data),
  cellStyle: (params: CellClassParams) =>
    isGroupHeaderRow(params.node.data)
      ? {
          backgroundColor: DISABLE_COLOR,
        }
      : undefined,
  filter: ClientSideSelectFilter,
  floatingFilter: true,
  filterParams: {
    valueGetter: (params: { node; context }) => {
      if (!params.node || isGroupHeaderRow(params.node.data)) {
        return undefined
      }
      const additionalPropertyRow = params.node.data as AdditionalPropertyRow
      return additionalPropertyRow.propertyType
    },
    getValue: o => o,
    getLabel: o => o,
    filterChangedCallback: () => {
      return
    },
  },
}
