import { ColDef, ColGroupDef } from 'ag-grid-community'
import { intl } from '../../../../../i18n'
import {
  revision,
  createdBy,
  createdAt,
  updatedBy,
  updatedAt,
} from '../../../../page-properties/bulksheet-properties/changeLog'
import { dragAndDrop } from '../../../../page-properties/bulksheet-properties/dragAndDrop'
import { rowNumber } from '../../../../page-properties/bulksheet-properties/rowNumber'
import { propertyTypeColumnDef } from './propertyType'
import { wbsItemTypesColumnDef } from './wbsItemTypes'
import { entitySearchReferenceEntityColumnDef } from './entitySearchReferenceEntity'
import { requiredColumnDef } from './required'
import { selectOptionsColumnDef } from './selectOptions'

export const columnDefs: (ColDef | ColGroupDef)[] = [
  {
    field: 'uuid',
    hide: true,
    suppressColumnsToolPanel: true,
  },
  {
    colId: 'blank',
    hide: true,
    width: 35,
    suppressColumnsToolPanel: true,
  },
  rowNumber,
  dragAndDrop,
  {
    headerName: intl.formatMessage({
      id: 'wbsItemAdditionalProperties.column.group.header.wbsAdditionalProperty',
    }),
    children: [
      propertyTypeColumnDef,
      wbsItemTypesColumnDef,
      requiredColumnDef,
      selectOptionsColumnDef,
      entitySearchReferenceEntityColumnDef,
    ],
  },
  {
    headerName: intl.formatMessage({ id: 'changeLog' }),
    children: [revision, createdBy, createdAt, updatedBy, updatedAt],
  },
]
