import { useEffect, useMemo, useState } from 'react'
import { WbsItemBasic, WbsItemType } from '../../../domain/entity/WbsItemEntity'
import { useWbsItemRepository } from '../../../services/adaptors/wbsItemRepositoryAdaptor'
import { Task } from './components/pages/Task'
import { Deliverable } from './components/pages/Deliverable'
import { useProjectPrivateContext } from '../../context/projectContext'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { useSelector } from 'react-redux'
import { AllState } from '../../../store'
import { DeliverableList } from './components/pages/DeliverableList'
import { Process } from './components/pages/Process'

type WbsItemV2Props = {
  code: string
}
export type WbsItemProps = {
  projectUuid: string
  uuid: string
  functionUuid: string
}

export const WbsItemV2 = ({ code }: WbsItemV2Props) => {
  const [basic, setBasic] = useState<WbsItemBasic | undefined>()
  const { getBasicByCode } = useWbsItemRepository()
  useEffect(() => {
    const fn = async () => {
      const response = await getBasicByCode(code)
      setBasic(response)
    }
    fn()
  }, [code, getBasicByCode])
  const functions = useSelector(
    (state: AllState) => state.appFunction.functions
  )
  const functionUuid = useMemo(
    () => functions.find(f => f.externalId === 'wbsItem')?.uuid || '',
    [functions]
  )
  if (!basic) return <></>
  const { projectUuid, uuid, type, ticketType } = basic
  switch (type) {
    case WbsItemType.TASK:
      return (
        <Task
          projectUuid={projectUuid}
          uuid={uuid}
          functionUuid={functionUuid}
        />
      )
    case WbsItemType.DELIVERABLE:
      return (
        <Deliverable
          projectUuid={projectUuid}
          uuid={uuid}
          functionUuid={functionUuid}
        />
      )
    case WbsItemType.DELIVERABLE_LIST:
      return (
        <DeliverableList
          projectUuid={projectUuid}
          uuid={uuid}
          functionUuid={functionUuid}
        />
      )
    case WbsItemType.PROCESS:
      return (
        <Process
          projectUuid={projectUuid}
          uuid={uuid}
          functionUuid={functionUuid}
        />
      )
  }
  return <></>
}
