import React from 'react'
import {
  Avatar,
  AvatarGroup,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from '@mui/material'
import { styled } from '@mui/system'
import { ColDef, RowNode } from 'ag-grid-community'
import { ICON_SIZE } from '../../../../../../BulkSheet/const'
import { TextColor } from '../../../../../../../../styles/commonStyles'

const RootDiv = styled('div')({
  '& .MuiAvatar-root': {
    height: '21px',
    width: '21px',
    border: 0,
    borderRadius: '50%',
    fontSize: 'small',
  },
})
const StyledAvatar = styled(Avatar)({
  border: 0,
  borderRadius: '50%',
})
const TooltipTitle = styled('div')({
  color: TextColor.WHITE,
  background: 'transparent',
  opacity: 0.9,
  borderRadius: '5px',
  maxHeight: 300,
  maxWidth: 500,
  overflowY: 'auto',
  padding: '9px 10px',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#bbb',
    borderRadius: '10px',
    border: '1px solid #fff',
  },
  '& .MuiList-root': {
    padding: 0,
  },
  '& .MuiListItem-root': {
    padding: 0,
    alignItems: 'center',
  },
  '& .MuiListItemAvatar-root': {
    height: '21px',
    minWidth: '21px !important',
  },
  '& .MuiAvatar-root': {
    margin: '0px 5px 0px 0px',
    padding: 0,
    height: '21px',
    width: '21px',
    border: 0,
    borderRadius: '50%',
  },
})

interface Props {
  colDef: ColDef
  node: RowNode
  eGridCell: HTMLElement
  getValue: () => any
}

class cellRenderer extends React.PureComponent<Props> {
  render() {
    const values = this.props.getValue()
    // When copy & paste, first values' type is not array but string.
    if (!values || typeof values === 'string' || values.length === 0) {
      return <></>
    }
    let content
    const showIcon = this.props.colDef.cellRendererParams.showIcon
    if (showIcon) {
      const iconSize = ICON_SIZE
      content = (
        <AvatarGroup max={6} spacing="medium">
          {values.map((v, i) => (
            <StyledAvatar
              key={i}
              src={v.iconUrl}
              variant="rounded"
              style={{
                height: iconSize,
                width: iconSize,
              }}
            />
          ))}
        </AvatarGroup>
      )
    } else {
      content = values[0].name
    }
    return (
      <Tooltip
        title={
          <TooltipTitle>
            <List>
              {values.map((v, i) => (
                <ListItem key={i}>
                  {showIcon && (
                    <ListItemAvatar>
                      <Avatar src={v.iconUrl} variant="rounded" />
                    </ListItemAvatar>
                  )}
                  <ListItemText primary={v.name} />
                </ListItem>
              ))}
            </List>
          </TooltipTitle>
        }
        placement={'bottom-start'}
        enterDelay={300}
        enterNextDelay={300}
        leaveDelay={300}
      >
        <RootDiv className="sevend-ag-cell">{content}</RootDiv>
      </Tooltip>
    )
  }
}

export default cellRenderer
